export const answerMatches = {
  "Body image (EORTC)": [
    "during the past week... have you felt physically less attractive as a result of your disease or treatment?",
    "have you been feeling less feminine as a result of your disease or treatment?",
    "did you find it difficult to look at yourself naked?",
    "have you been dissatisfied with your body?"
  ],
  "Symptom: Arm symptoms (EORTC)": [
    "during the past week... did you have any pain in your arm or shoulder?",
    "did you have a swollen arm or hand?",
    "was it difficult to raise your arm or to move it sideways?"
  ],
  "Physical functioning (EORTC)": [
    "do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase?",
    "do you have any trouble taking a long walk?",
    "do you have any trouble taking a short walk outside of the house?",
    "do you need to stay in bed or a chair during the day?",
    "do you need help with eating, dressing, washing yourself or using the toilet?"
  ],
  "Cognitive functioning (EORTC)": [
    "during the past week... have you had difficulty in concentrating on things, like reading a newspaper or watching television?",
    "have you had difficulty remembering things?"
  ],
  "Role functioning (EORTC)": [
    "during the past week... were you limited in doing either your work or other daily activities?",
    "were you limited in pursuing your hobbies or other leisure time activities?"
  ],
  "Emotional functioning (EORTC)": [
    "during the past week... did you feel tense?",
    "did you worry?",
    "did you feel irritable?",
    "did you feel depressed?"
  ],
  "Social functioning (EORTC)": [
    "during the past week... has your physical condition or medical treatment interfered with your family life?",
    "has your physical condition or medical treatment interfered with your social activities?",
  ],
  "Sexual functioning (EORTC)": [
    "during the past four weeks... to what extent were you interested in sex?",
    "to what extent were you sexually active? (with or without intercourse)",
    "answer this question only if you have been sexually active: to what extent was sex enjoyable for you?"
  ]
}