'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPatient;

var _gqlUtils = require('../../gqlUtils');

function getPatient(guid, items) {
  var itemsFragment = (0, _gqlUtils.queryBuilder)(items);
  var query = '{\n    patients (guid: "' + guid + '")\n    {\n      items\n      {\n        ' + itemsFragment + '\n      }\n      pageInfo\n      {\n        hasPreviousPage,\n        startCursor,\n        hasNextPage,\n        endCursor,\n      }\n      totalCount\n    }\n  }';
  return [query];
}