import React from 'react'
import { connect } from 'react-redux'
import { Button, Icon } from 'antd'

import { removeCSVPatient } from '@/features/CSVImport/redux/csvActions'
import './PatientRow.scss'

const PatientRow = ({ canBeRemoved, name, index, errorMessage, removeCSVPatient }) => {

  const handleRemoveClick = () => {
    removeCSVPatient(index)
  }
  
  const renderErrorText = () => {
    if (!errorMessage) return null
    return (
      <div className='c-csv__patient__list__row__info'>
        <Icon type='exclamation-circle' className='c-csv__patient__list__row__info__icon' />
        <span className='c-csv__patient__list__row__info__message'>{errorMessage}</span>
      </div>
    )
  }

  return (
    <div className={`c-csv__patient__list__row ${errorMessage.length && 'c-csv__patient__list__row--error'}`}>
      <div className='c-csv__patient__list__row__content'>
        <p>{name}</p>
        { canBeRemoved ? <Button onClick={handleRemoveClick} type='link'>Remove</Button> : null }
      </div>
      { renderErrorText() }
    </div>
  )
}

export default connect(null, { removeCSVPatient })(PatientRow)