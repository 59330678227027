'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propsWithPems = require('../propsWithPems');

var _propsWithPems2 = _interopRequireDefault(_propsWithPems);

var _authentication = require('../authentication');

var _moment = require('moment');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mHealthModule = function () {
  function mHealthModule(mHealthMain) {
    (0, _classCallCheck3.default)(this, mHealthModule);

    this.mHealthMain = mHealthMain;
    this.requiredPermissions = [];
    this.AuthorizedAuthenticatedRoute = (0, _authentication.AuthorizedAuthenticatedRoute)('/auth/login', mHealthMain);
  }

  (0, _createClass3.default)(mHealthModule, [{
    key: 'init',
    value: function init(className, prettyName, icon, route, routeConfig, masterConfig) {
      this.className = className;
      this.prettyName = prettyName;
      this.route = route;
      this.routeConfig = routeConfig;
      this.icon = icon;
      this.masterConfig = masterConfig;
    }
    //

  }, {
    key: 'setStore',
    value: function setStore(store) {
      this.store = store;
    }
  }, {
    key: 'output',
    value: function output(props, route) {
      var _this = this;

      return this.routeConfig(this.masterConfig, props).map(function (route) {
        return _react2.default.createElement(_this.AuthorizedAuthenticatedRoute, (0, _extends3.default)({
          exact: route.exact,
          path: route.path,
          key: route.path,
          component: route.screen,
          onPermissionError: route.onPermissionError,
          requiredPermissions: route.requiredPermissions,
          fallbackRoute: _this.masterConfig.fallbackRoute
        }, props, {
          moduleConfig: _this.masterConfig
        }));
      }).filter(function (r) {
        return !r.path;
      });
    }
  }, {
    key: 'getReducers',
    value: function getReducers(moduleReducers) {
      var reducers = [];
      var keys = (0, _keys2.default)(moduleReducers);
      keys.forEach(function (reducerKey) {
        reducers.push({
          key: reducerKey,
          function: moduleReducers[reducerKey]
        });
      });
      return reducers;
    }
  }, {
    key: 'getActions',
    value: function getActions(moduleActions) {
      var actions = [];
      var keys = (0, _keys2.default)(moduleActions);
      keys.forEach(function (actionKey) {
        actions.push({
          key: actionKey,
          function: moduleActions[actionKey]
        });
      });
      return actions;
    }
  }, {
    key: 'getOperations',
    value: function getOperations(moduleOperations) {
      var operations = [];
      var keys = (0, _keys2.default)(moduleOperations);
      keys.forEach(function (operationKey) {
        actions.push({
          key: operationKey,
          function: moduleOperations[operationKey]
        });
      });
      return operations;
    }
  }]);
  return mHealthModule;
}();

exports.default = mHealthModule;