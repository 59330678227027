import {
  LOGOUT_SUCCESS
} from '../../actionTypes'

const INITIAL_AUTH_STATE = {
  errors: false,
  isAuthenticated: false,
  isFetching: false,
  message: ''
}

const auth = (state = INITIAL_AUTH_STATE, action) => {
  switch (action.type) {
  case LOGOUT_SUCCESS:
    return {
      ...state,
      errors: false,
      isAuthenticated: true,
      isFetching: false,
      message: 'Logged out successfully'
    }
  default:
    return state
  }
}

export {
  auth
}