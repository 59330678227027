import * as types from './createPatientTypes'


export const addNewTableRow = (tableName, dataObject, mHealthDispatch) => {
  mHealthDispatch({
    type: types.ADD_TABLE_FIELD_VALUE,
    payload: { tableName, dataObject }
  })
}

export const removeTableRow = (tableName, rowIndex, mHealthDispatch) => {
  mHealthDispatch({
    type: types.REMOVE_TABLE_ROW,
    payload: { tableName, rowIndex }
  })
}

export const wipeTables = (mHealthDispatch) => {
  mHealthDispatch({
    type: types.WIPE_PATIENT_TABLES
  })
}

export const setPatientTableData = (tableName, data, tableFieldKey, dataKey, mHealthDispatch, extraInfo) => {
  mHealthDispatch({
    type: types.SET_PATIENT_TABLE_FIELD_DATA,
    payload: { tableName, fieldKey: tableFieldKey, dataKey, fieldData: data, extraInfo }
  })
}