'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = resendRegistrationEmail;

var _endpoints = require('../../endpoints');

function resendRegistrationEmail(username) {
  return ['POST', true, _endpoints.USER_ROUTE, { 'type': 'ResendSignupEmail' }, null, '/' + username + '/command'];
}