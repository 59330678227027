'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getRoles;

var _endpoints = require('../../endpoints');

function getRoles() {
  return ['GET', true, _endpoints.ROLE_ROUTE, null, null];
}