import * as types from './csvTypes'
import cloneDeep from 'lodash/cloneDeep'

const INITIAL_STATE = {
  patientsToUpload: [],
  readyToImport: false,
  meta: {
    uploadingCsvFile: false,
    uploadCsvFileFailed: false,
    uploadedCsvFile: false,
    uploadingPatients: false,
    uploadedPatients: false,
    uploadPatientsFailed: false,
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPLOAD_CSV_FILE_CONTENTS_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          uploadingCsvFile: true,
          uploadCsvFileFailed: false,
          uploadedCsvFile: false,
          uploadedPatients: false
        }
      }
    }
    case types.UPLOAD_CSV_FILE_CONTENTS_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          uploadingCsvFile: false,
          uploadCsvFileFailed: true,
          uploadedCsvFile: false
        }
      }
    }
    case types.UPLOAD_CSV_FILE_CONTENTS_SUCCESS: {
      return {
        ...state,
        patientsToUpload: action.payload,
        meta: {
          ...state.meta,
          uploadingCsvFile: false,
          uploadCsvFileFailed: false,
          uploadedCsvFile: true
        }
      }
    }
    case types.REMOVE_CSV_PATIENT: {
      const newPatientsToUpload = state.patientsToUpload.filter((patient, index) => index !== action.payload)
      return {
        ...state,
        patientsToUpload: newPatientsToUpload
      }
    }
    case types.UPLOAD_CSV_FILE_PATIENTS_BEGIN: {
      return {
        ...state,
        meta: {
          ...state.meta,
          uploadedCsvFile: false,
          uploadingPatients: true,
          uploadedPatients: false,
          uploadPatientsFailed: false,
        }
      }
    }
    case types.UPLOAD_CSV_FILE_PATIENTS_SUCCESS: {
      return {
        ...state,
        meta: {
          ...state.meta,
          uploadingPatients: false,
          uploadedPatients: true,
          uploadPatientsFailed: false,
        }
      }
    }
    case types.UPLOAD_CSV_FILE_PATIENTS_FAIL: {
      return {
        ...state,
        meta: {
          ...state.meta,
          uploadedCsvFile: true,
          uploadingPatients: false,
          uploadedPatients: false,
          uploadPatientsFailed: true,
        }
      }
    }
    case types.RESET_CSV_DATA: {
      return INITIAL_STATE
    }
    default: return state
  }
}