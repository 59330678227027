'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = deleteProfilePhotoFromS3;

var _endpoints = require('../../endpoints');

function deleteProfilePhotoFromS3(key) {
  return ['DELETE', true, _endpoints.PHOTO_UPLOAD_ROUTE, { s3Key: key }];
}