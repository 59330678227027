import React from 'react'
import { EntityTable } from '@adkgroup/appkit-ui'
import get from 'lodash/get'
import { Icon, Spin } from 'antd'
import find from 'lodash/find'
import { initEntityData } from '../../utils'

import './Response.scss'

export default class ResponseScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    // get entityId from url or navigator and GET /entity/id/response
    const { location, entityProfile } = this.props
    let guid, entityType
    if (location && location.payload) {
      guid = location.payload.entity.guid
      entityType = location.payload.entityType
    } else if (get(location, ['state', 'from'], null) && location.state.from.split('/')[1] === 'patients') { // account for refreshes
      guid = location.pathname.split('/')[2]
      entityType = location.pathname.split('/')[1]
    }
    if (entityProfile.guid !== guid) {
      await initEntityData(this.props, guid, entityType)
    }
    this.setState({ guid, entityType, loading: false })
  }

  returnToPatients = () => {
    const { mHealthMain, location } = this.props
    // Cleanup redux state
    mHealthMain.dispatch.setData('patientResponses', [])
    mHealthMain.dispatch.setTablePageInfo(
      'patientResponse',
      null,
      null
    )
    // Navigation event
    mHealthMain.dispatch.navigateTo('/patients', location.pathname)
  }  

  goToProfile = () => {
    const { mHealthMain, location } = this.props
    const { guid, entityType } = this.state
    // Navigation event
    mHealthMain.dispatch.navigateTo(
      `/${entityType}/${guid}/profile`,
      location.pathname,
      { 
        entityType,
        entity: find(this.props[entityType], entity => entity.guid === guid),
      }
    )
  } 

  render() {
    const { entityProfile } = this.props
    const patient = entityProfile
    if (this.state.loading) {
      return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Spin />
        <span style={{ marginTop: '10px' }}>Loading Data...</span>
      </div>
    } else {
      return <div
        className='patient-response-container'
        loading={this.state.loading}
      >
        <div className='return-container' onClick={this.returnToPatients}>
          <Icon type='left' className='return-icon' />
          <div className='return-text'>Return to Patients</div>
        </div>
        <div className='header-container'>
          <div className='header'>Patient Responses</div>
          <div className='view-profile-container' onClick={this.goToProfile}>
            <Icon type='user' className='view-profile-icon' />
            <div className='view-profile-text'>View Patient Profile</div>
          </div>
        </div>
        { patient && <div className='patient-info-container'>
          <div className='patient-name'>{patient.firstName} {patient.lastName}</div>
          { patient.mrn && <div className='patient-mrn'>{patient.mrn.slice(0, 3) + '-' + patient.mrn.slice(3)}</div> }
        </div> }
        <EntityTable
          {...this.props}
          tableConfig={this.props.moduleConfig.patientResponses}
        />
      </div>
    }
  }
}