import React, { PureComponent } from 'react'
import { initEntityData } from '../../utils'

import PatientHeader from './components/PatientHeader'
import PatientSurveyCategories from './components/PatientSurveyCategories'
import PatientGraphContainer from './components/PatientGraph'
import './PatientOverview.scss'

class PatientOverview extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { 
      guid: null, 
      entityType: null
    };
    this.init()
  }

  init = async () => {
    const { location, entityProfile } = this.props
    const guid = location.payload
      ? location.payload.entity.guid
      : location.pathname.split('/')[2]
    const entityType = location.payload
      ? location.payload.entityType
      : location.pathname.split('/')[1]
    this.setState({ guid, entityType })
    if (entityProfile.guid !== guid) {
      await initEntityData(this.props, guid, entityType)
    }
  }

  render () {
    const { entityProfile, mHealthMain } = this.props
    const entity = entityProfile
    return (
      <div className='patient__overview'>
        <PatientHeader />
        <div className='patient__overview__split__container'>
          <PatientSurveyCategories dispatch={mHealthMain.dispatch} categories={entity.surveyCategories} />
          <PatientGraphContainer mhealthDispatch={mHealthMain.dispatch} />
        </div>

      </div>
    )
  }
}

export default PatientOverview