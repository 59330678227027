'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthorizedAuthenticatedRoute = exports.handleAuthRedirect = exports.isAuthorized = exports.isAuthenticated = exports.isTokenStillValid = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

exports.mapModulePermissions = mapModulePermissions;
exports.canAssignManager = canAssignManager;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _reactRouterDom = require('react-router-dom');

var _jwtDecode = require('jwt-decode');

var _jwtDecode2 = _interopRequireDefault(_jwtDecode);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _some = require('lodash/some');

var _some2 = _interopRequireDefault(_some);

var _get = require('lodash/get');

var _get2 = _interopRequireDefault(_get);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isTokenStillValid = exports.isTokenStillValid = function isTokenStillValid() {
  var token = (0, _jwtDecode2.default)(window.localStorage.getItem('session_token'));
  var currentTime = _moment2.default.utc();
  var tokenTime = (0, _moment2.default)(new Date(Math.floor(token.exp * 1000)).getTime());
  return tokenTime > currentTime;
};

var isAuthenticated = exports.isAuthenticated = function isAuthenticated() {
  return window.localStorage.getItem('session_token'); //&& isTokenStillValid()
};

function mapModulePermissions(permissions) {
  var permissionsObj = {};
  permissions.forEach(function (pString) {
    var key = pString.split('.')[0];
    pString = pString.split('.')[1];
    if (permissionsObj[key]) {
      permissionsObj[key].push(pString);
    } else {
      permissionsObj[key] = [];
      permissionsObj[key].push(pString);
    }
  });
  return permissionsObj;
}

function canAssignManager(permissions) {
  if (permissions.module) {
    return permissions.module.indexOf('add_pendingregistration_with_manager') > -1;
  }
  return false;
}

/**
 * 
 * @param {string[]} userPermissions The permissions that the accessing user has
 * @param {string[]} requiredPermissions The permissions that the route requires defined in the config
 * @returns {boolean} if user is authorized or not
 */
var isAuthorized = exports.isAuthorized = function isAuthorized() {
  var userPermissions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var requiredPermissions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  // The route doesn't have any required permissions
  if (!requiredPermissions.length) return true;
  // The route has required permissions and the user has none
  else if (requiredPermissions.length && !userPermissions.length) return false;else return (0, _some2.default)(requiredPermissions, function (p) {
      return userPermissions.includes(p);
    });
};

var handleAuthRedirect = exports.handleAuthRedirect = function handleAuthRedirect(RESTRICTED_ROUTE, LOGIN_ROUTE, onPermissionError) {
  return function (attemptedPath, props) {
    // if authenticated already
    if (window.localStorage.session_token) {
      //  if we try to access the module with no success, bring up the failed module screen
      if (onPermissionError) onPermissionError();
      return _react2.default.createElement(_reactRouterDom.Redirect, { to: { pathname: RESTRICTED_ROUTE, state: { from: props.location } } });
    } else {
      return _react2.default.createElement(_reactRouterDom.Redirect, { to: { pathname: LOGIN_ROUTE, state: { from: props.location } } });
    }
  };
};

var AuthorizedAuthenticatedRoute = function AuthorizedAuthenticatedRoute(LOGIN_ROUTE, mHealthMain) {
  return function (_ref) {
    var Component = _ref.component,
        path = _ref.path,
        requiredPermissions = _ref.requiredPermissions,
        onPermissionError = _ref.onPermissionError,
        fallbackRoute = _ref.fallbackRoute,
        rest = (0, _objectWithoutProperties3.default)(_ref, ['component', 'path', 'requiredPermissions', 'onPermissionError', 'fallbackRoute']);

    var permissions = (0, _get2.default)(mHealthMain.getStoreState(), ['myProfile', 'role', 'permissions'], []);
    var userPermissions = permissions.map(function (p) {
      return p.name;
    });
    if (mHealthMain.isAuthenticated()) {
      return _react2.default.createElement(_reactRouter.Route, (0, _extends3.default)({}, rest, {
        key: path,
        render: function render(props) {
          return mHealthMain.isAuthenticated(props) && isAuthorized(userPermissions, requiredPermissions) ? _react2.default.createElement(Component, (0, _extends3.default)({}, props, rest)) : handleAuthRedirect(fallbackRoute, LOGIN_ROUTE, onPermissionError)(path, props);
        }
      }));
    } else {
      return _react2.default.createElement(_reactRouter.Route, (0, _extends3.default)({}, rest, {
        key: path,
        render: function render(props) {
          return mHealthMain.isAuthenticated(props) ? _react2.default.createElement(Component, (0, _extends3.default)({}, props, rest)) : _react2.default.createElement(_reactRouterDom.Redirect, { to: { pathname: LOGIN_ROUTE, state: { from: props.location } } });
        }
      }));
    }
  };
};
exports.AuthorizedAuthenticatedRoute = AuthorizedAuthenticatedRoute;