'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPatientResponses;

var _gqlUtils = require('../../gqlUtils');

function getPatientResponses(items, sort, filters, pagination, guid) {
  var paginationArg = pagination ? (0, _gqlUtils.paginationBuilder)(pagination) : 'first: 8';
  var sortArg = sort ? (0, _gqlUtils.sortBuilder)(sort) : null;
  var filtersArg = filters && filters.length ? (0, _gqlUtils.argumentBuilder)(filters) : '';

  var argumentStr = '(patientGuid: "' + guid + '", ' + paginationArg + ', ' + (sortArg ? sortArg + ', ' : '') + filtersArg + ')';
  var itemsFragment = (0, _gqlUtils.queryBuilder)(items);

  var query = '{\n    patientResponses:responses ' + argumentStr + '\n    {\n      items\n      {\n        ' + itemsFragment + '\n      }\n      pageInfo\n      {\n        hasPreviousPage,\n        startCursor,\n        hasNextPage,\n        endCursor,\n      }\n      totalCount\n    }\n  }';
  return [query];
}