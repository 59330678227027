import React, { PureComponent } from 'react'

class PatientGraphAxis extends PureComponent {
  render () {
    return (
      <g>
        <line 
          x1={this.props.x1}
          x2={this.props.x2}
          y1={60}
          y2={this.props.y2}
          style={this.props.style}
        />
      </g>
    )
  }
}

export default PatientGraphAxis