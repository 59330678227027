'use strict';

var _auth = require('./auth');

var _dataDisplay = require('./dataDisplay');

var _dataEntry = require('./dataEntry');

var _feedback = require('./feedback');

var _layout = require('./layout');

var _general = require('./general');

/*
 * Exports from appkit-ui
 */

/*
 * Layout components 
 */

/*
 * Data Entry components 
 */

/*
 * Auth components 
 */

exports.Login = _auth.Login;

/*
 * General components
 */

/*
 * Feedback components 
 */

/*
 * Data Display components 
 */

exports.ForgotPassword = _auth.ForgotPassword;
exports.ResetPassCreds = _auth.ResetPassCreds;
exports.ResetPassEmail = _auth.ResetPassEmail;

exports.EntityTable = _dataDisplay.EntityTable;
exports.AntTable = _dataDisplay.AntTable;

exports.CreateEntity = _dataEntry.CreateEntity;
exports.EditEntity = _dataEntry.EditEntity;
exports.Form = _dataEntry.Form;
exports.Input = _dataEntry.Input;
exports.Select = _dataEntry.Select;

exports.Alert = _feedback.Alert;
exports.Notification = _feedback.Notification;
exports.PasswordValidation = _feedback.PasswordValidation;

exports.Header = _layout.Header;
exports.Modal = _layout.Modal;
exports.Page = _layout.Page;
exports.Sider = _layout.Sider;

exports.Button = _general.Button;