'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AntInput = function (_React$Component) {
  (0, _inherits3.default)(AntInput, _React$Component);

  function AntInput() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, AntInput);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = AntInput.__proto__ || (0, _getPrototypeOf2.default)(AntInput)).call.apply(_ref, [this].concat(args))), _this), _this.state = {}, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(AntInput, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          ref = _props.ref,
          key = _props.key,
          className = _props.className,
          value = _props.value,
          onChange = _props.onChange,
          onBlur = _props.onBlur,
          _props$onPressEnter = _props.onPressEnter,
          onPressEnter = _props$onPressEnter === undefined ? null : _props$onPressEnter,
          _props$prefix = _props.prefix,
          prefix = _props$prefix === undefined ? null : _props$prefix,
          _props$suffix = _props.suffix,
          suffix = _props$suffix === undefined ? null : _props$suffix,
          _props$placeholder = _props.placeholder,
          placeholder = _props$placeholder === undefined ? null : _props$placeholder,
          _props$type = _props.type,
          type = _props$type === undefined ? 'text' : _props$type,
          _props$disabled = _props.disabled,
          disabled = _props$disabled === undefined ? false : _props$disabled,
          _props$style = _props.style,
          style = _props$style === undefined ? null : _props$style;

      return _react2.default.createElement(_antd.Input, {
        ref: ref,
        key: key,
        className: className,
        value: value,
        onChange: onChange,
        onBlur: onBlur,
        onPressEnter: onPressEnter,
        prefix: prefix,
        suffix: suffix,
        placeholder: placeholder,
        type: type,
        disabled: disabled,
        style: style
      });
    }
  }]);
  return AntInput;
}(_react2.default.Component);

exports.default = AntInput;