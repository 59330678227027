import React from 'react'
import { EditEntity } from '@adkgroup/appkit-ui'

class EntityDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  render() {
    const { entity, configs } = this.props
    return (
      <div className="edit-entity-wrapper">
        <EditEntity
          {...this.props}
          entity={entity}
          configs={configs}
        />
      </div>
    );
  }
}

export default EntityDetailForm;