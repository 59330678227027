'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./Loader.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Loader = function Loader(_ref) {
  var _classNames;

  var _ref$spinning = _ref.spinning,
      spinning = _ref$spinning === undefined ? true : _ref$spinning,
      fullScreen = _ref.fullScreen;

  return _react2.default.createElement(
    'div',
    {
      className: (0, _classnames2.default)('loader', (_classNames = {}, (0, _defineProperty3.default)(_classNames, 'hidden', !spinning), (0, _defineProperty3.default)(_classNames, 'fullScreen', fullScreen), _classNames))
    },
    _react2.default.createElement(
      'div',
      { className: 'warpper' },
      _react2.default.createElement('div', { className: 'inner' }),
      _react2.default.createElement(
        'div',
        { className: 'text' },
        'LOADING'
      )
    )
  );
};

Loader.propTypes = {
  spinning: _propTypes2.default.bool,
  fullScreen: _propTypes2.default.bool
};

exports.default = Loader;