'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEnvironment = undefined;

var _browserDetect = require('browser-detect');

var _browserDetect2 = _interopRequireDefault(_browserDetect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getEnvironment = exports.getEnvironment = function getEnvironment() {
  var result = (0, _browserDetect2.default)();
  return {
    environment: process.env.NODE_ENV, // this is going to be whatever this is built on, intended ?
    platform: result.os + ': ' + result.name.toUpperCase() + ' version ' + result.version + ' ' + (result.mobile ? 'mobile' : 'desktop')
  };
};