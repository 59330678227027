import React from 'react'
import { Icon, Tooltip } from 'antd'
import { ENTITY_STATUS } from '../../../../app'
import { Notification } from '@adkgroup/appkit-ui'
import find from 'lodash/find'

const roleMapping = {
  Physician: "Doctor",
  CareManager: "Examiner"
}

export default [
  {
    type: 'text',
    value: 'First Name',
    fieldKey: 'firstName',
    width: 150,
    fieldLocation: '',
    filtered: true,
    sorter: true,
  },
  {
    type: 'text',
    value: 'Last Name',
    fieldKey: 'lastName',
    width: 150,
    fieldLocation: '',
    filtered: true,
    sorter: true,
  },
  {
    type: 'text',
    value: 'E-mail',
    fieldKey: 'email',
    fieldLocation: '',
    filtered: true,
    sorter: true,
  },
  {
    type: 'text',
    value: 'Role',
    fieldKey: 'role',
    fieldLocation: '',
    render: (value, mHealthMain) => {
      return (
        <div>{roleMapping[value.role] || 'Admin'}</div>
      )
    }
  },
  {
    type: 'text',
    value: 'Office Number',
    fieldKey: 'attributes',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (value, mHealthMain) => {
      let attributesObject = {}
      if (value.attributes.length) {
        attributesObject = JSON.parse(value.attributes[0])
        return (
          <div>{attributesObject.officeNumber}</div>
        )
      } else return <div />
    }
  },
  {
    type: 'text',
    value: 'Specialty',
    fieldKey: 'attributes',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (value, mHealthMain) => {
      let attributesObject = {}
      if (value.attributes.length) {
        attributesObject = JSON.parse(value.attributes[0])
        return (
          <div>{attributesObject.specialty}</div>
        )
      } else return <div />
    }
  },
  {
    type: 'text',
    value: 'Status',
    fieldKey: 'status',
    fieldLocation: '',
    filtered: true,
    filterTransform: str => {
      if (str === '') {
        return ''
      } else {
        const lowerCaseStr = str.toLowerCase()
        if ("active".includes(lowerCaseStr)) {
          return "CONFIRMED"
        } else if ("pending".includes(lowerCaseStr)) {
          return "UNCONFIRMED"
        } else if ("deactivated".includes(lowerCaseStr)) {
          return "DISABLE"
        } else {
          return str
        }
      }
    },
    sorter: true,
    render: (record, mHealthMain) => {
      return <div className='status-cell' onMouseOver={e => e.stopPropagation()}>
        <div className={`status-indicator ${record.status}`} />
        { ENTITY_STATUS[record.status] }
        { record.status === 'UNCONFIRMED'
          ? <Tooltip title="Resend Invitation">
            <Icon
              type='mail'
              className='resend-registration-email-icon'
              onClick={async e => {
                e.stopPropagation()
                try {
                  await mHealthMain.api.resendRegistrationEmail(record.guid)
                  Notification({
                    message: 'Successfully resend registration email'
                  })
                } catch (error) {
                  Notification({
                    message: 'Failed to resend registration email',
                    description: 'Please try again later'
                  })
                }
              }}
            />
          </Tooltip>
          : null }
      </div>
    }
  },
]