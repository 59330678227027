import React from 'react'
import columns from './columns'
import { buildRequestConfig, improveAxios } from '@/utils/axios/helpers'
import { Notification } from '@adkgroup/appkit-ui'
import CSVModal from '@/features/CSVImport/components/CSVModal'

import { initEntityData } from '../../../../../modules/EntityManagementModule/utils'
let fileDownload = require('js-file-download')

export default {
  title: 'Patients',
  dataFilter: (data) => data,
  modelName: 'patient',
  onRowClick: async (props, rowIndex) => {
    const selectedPatient = props.patients[rowIndex]
    const { guid } = selectedPatient
    const profile = props.mHealthMain.getStoreState().myProfile
    if (profile.role && profile.role.name === 'Physician') {
      await initEntityData(props, guid, 'patients')
      props.mHealthMain.dispatch.navigateTo(
        `/patients/${guid}/overview`,
        props.location.pathname,
        { 
          entityType: 'patients',
          entity: selectedPatient 
        }
      )
    } else {
      await initEntityData(props, guid, 'patients')
      props.mHealthMain.dispatch.navigateTo(
        `/patients/${guid}/profile`,
        props.location.pathname,
        { 
          entityType: 'patients',
          entity: selectedPatient 
        }
      )
    }
  },
  scroll: true,
  columns,
  actions: (props) => ([
    {
      action: 'clearFilters',
      label: 'Clear Filters'
    },
    {
      action: 'export',
      // permissions: ['read_0_export', 'read_1_export'],
      className: 'patient-table-export--quotes',
      label: 'Export Quotes',
      onClick: async () => {
        const config = buildRequestConfig('dashboard/message/export/Inspiration', 'get')
        try {
          const response = await improveAxios.request(config)
          fileDownload(response.data, 'patient_quotes.csv')
        } catch (error) {
          Notification({
            message: 'Failed to download',
            description: 'Please try again later.'
          })
        }
      }
    },
    {
      action: 'export',
      className: 'patient-table-export--upload',
      label: 'CSV Upload',
      onClick: async () => {
        const { mHealthMain: { dispatch } } = props
        dispatch.setModalContent({
          title: "Import Patient CSV",
          content: <CSVModal />,
          okText: 'Exit',
          cancelButtonProps: { id: 'ant-modal-hidden-cancel'  },
          onOk: async () => {}
        })
        dispatch.setModalVisibility(true)
      }
    },
    {
      action: 'export',
      // permissions: ['read_0_export', 'read_1_export'],
      className: 'patient-table-export--community',
      label: 'Export Community',
      onClick: async () => {
        const config = buildRequestConfig('dashboard/message/export/Resource', 'get')
        try {
          const response = await improveAxios.request(config)
          fileDownload(response.data, 'patient_community.csv')
        } catch (error) {
          Notification({
            message: 'Failed to download',
            description: 'Please try again later.'
          })
        }
      }
    },
    {
      action: 'export',
      className: 'patient-table-export',
      // permissions: ['read_0_export', 'read_1_export'],
      label: 'Export Data',
      onClick: async () => {
          // Build the request config with increased timeout
          const config = {
              ...buildRequestConfig('dashboard/patient/export', 'get'),
              timeout: 300000 // 5 minutes
          };
  
          try {
              const response = await improveAxios.request(config);
              fileDownload(response.data, 'patient_export.csv');
          } catch (error) {
              Notification({
                  message: 'Failed to download',
                  description: 'Please try again later.'
              });
          }
      }
  }, 
    {
      action: 'export',
      className: 'patient-table-export--responses',
      // permissions: ['read_0_export', 'read_1_export'],
      label: 'Export Survey Data',
      onClick: async () => {
        const config = buildRequestConfig('dashboard/patient/response/export', 'get')
        try {
          const response = await improveAxios.request(config)
          fileDownload(response.data, 'patient_responses_export.csv')
        } catch (error) {
          Notification({
            message: 'Failed to download',
            description: 'Please try again later.'
          })
        }
      }
    },
    {
      action: 'manageColumns',
      label: 'Manage Columns',
      className: 'patient-table-manage-column'
    },
  ]),
}