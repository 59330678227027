'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RangePicker = _antd.DatePicker.RangePicker;
var Option = _antd.Select.Option;

exports.default = function (props, config, filterOnChange, resetFilters, selectedKeys, setSelectedKeys) {
  switch (config.filterType) {
    case 'date':
      return _react2.default.createElement(
        'div',
        { className: 'filter-dropdown' },
        _react2.default.createElement(
          'div',
          { className: 'filter-upper-container' },
          _react2.default.createElement(
            'div',
            {
              className: 'filter-ok',
              onClick: filterOnChange
            },
            'OK'
          ),
          _react2.default.createElement(
            'div',
            {
              className: 'filter-reset',
              onClick: resetFilters
            },
            'Reset'
          )
        ),
        _react2.default.createElement(_antd.DatePicker, {
          value: selectedKeys && selectedKeys[0],
          onChange: function onChange(e) {
            return setSelectedKeys(e ? [e] : []);
          },
          style: { padding: '8px' },
          dropdownClassName: 'date-picker-dropdown'
        })
      );
    case 'date-range':
      return _react2.default.createElement(
        'div',
        { className: 'filter-dropdown' },
        _react2.default.createElement(
          'div',
          { className: 'filter-upper-container' },
          _react2.default.createElement(
            'div',
            {
              className: 'filter-ok',
              onClick: filterOnChange
            },
            'OK'
          ),
          _react2.default.createElement(
            'div',
            {
              className: 'filter-reset',
              onClick: resetFilters
            },
            'Reset'
          )
        ),
        _react2.default.createElement(RangePicker, {
          value: selectedKeys && selectedKeys[0],
          onChange: function onChange(e) {
            return setSelectedKeys(e ? [e] : []);
          },
          style: { padding: '8px', width: '450px' },
          popupStyle: { width: '450px' },
          dropdownClassName: 'range-picker-dropdown'
        })
      );
    case 'select':
      return _react2.default.createElement(
        'div',
        { className: 'filter-dropdown' },
        _react2.default.createElement(
          'div',
          { className: 'filter-upper-container' },
          _react2.default.createElement(
            'div',
            {
              className: 'filter-ok',
              onClick: filterOnChange
            },
            'OK'
          ),
          _react2.default.createElement(
            'div',
            {
              className: 'filter-reset',
              onClick: resetFilters
            },
            'Reset'
          )
        ),
        _react2.default.createElement(
          _antd.Select,
          {
            value: selectedKeys ? selectedKeys[0] : [],
            onChange: function onChange(e) {
              return setSelectedKeys(e ? [e] : []);
            },
            mode: 'multiple',
            style: { padding: '8px', width: '250px' },
            popupStyle: { width: '250px' },
            dropdownClassName: 'select-dropdown'
          },
          config.filterOptions(props).map(function (option, index) {
            return _react2.default.createElement(
              Option,
              {
                key: 'select-option-' + index,
                value: option.value
              },
              option.label
            );
          })
        )
      );
    default:
      return null;
  }
};