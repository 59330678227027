'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApplication = undefined;

var _getApplication = require('./getApplication');

var _getApplication2 = _interopRequireDefault(_getApplication);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.getApplication = _getApplication2.default;