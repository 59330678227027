'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = uploadProfilePhotoToS3;

var _endpoints = require('../../endpoints');

function uploadProfilePhotoToS3(payload) {
  return ['POST', true, _endpoints.PHOTO_UPLOAD_ROUTE, payload, "multipart/form-data"];
}