import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'


export const handleProfileTableDelete = (mHealthMain, tableName, rowIndex) => {
  const entityProfileStore = mHealthMain.getStoreState().entityProfile
  const tableData = cloneDeep(get(entityProfileStore, [tableName], null))
  if (!tableData) return null
  tableData.splice(rowIndex, 1)
  mHealthMain.dispatch.setData(
    'entityProfile', 
    {
      ...entityProfileStore,
      [tableName]: tableData
    }
  )
}

export const handleProfileTableAdd = (mHealthMain, tableName, newData) => {
  const entityProfileStore = mHealthMain.getStoreState().entityProfile
  let tableData
  if (entityProfileStore[tableName] && entityProfileStore[tableName].length) {
    tableData = [...entityProfileStore[tableName], newData]
  }
  else tableData = [newData]
  mHealthMain.dispatch.setData(
    'entityProfile', {
      ...entityProfileStore,
      [tableName]: tableData
    }
  )
}

export const handleProfileTableChange = (e, mHealthMain, tableName, dataKey, tableFieldKey, extraInfo) => {
  const entityProfileStore = mHealthMain.getStoreState().entityProfile
  const clonedTable = cloneDeep(get(entityProfileStore, tableName, null))
  if (!clonedTable) return null
  const dataField = clonedTable.filter((dataset) => dataset.key === tableFieldKey)[0]
  if (!dataField) return null
  if (extraInfo && extraInfo.type) {
    if (dataField[dataKey] && dataField[dataKey].length) {
      if (extraInfo.type === 'secondDate') dataField[dataKey][1] = e
      else dataField[dataKey][0] = e
    } else {
      if (extraInfo.type === 'secondDate') dataField[dataKey] = [undefined, e]
      else dataField[dataKey] = [e, undefined]
    }
  } else dataField[dataKey] = e
  mHealthMain.dispatch.setData(
    'entityProfile', {
      ...entityProfileStore,
      [tableName]: clonedTable
    }
  )
}