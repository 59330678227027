'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPhysicians = getPhysicians;

var _endpoints = require('../../endpoints');

function getPhysicians() {
  return ['GET', false, _endpoints.PHYSICIAN_ROUTE];
}