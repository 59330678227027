'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GuestOnlyRoute = exports.AuthenticatedRoute = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _reactRouter = require('react-router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AuthenticatedRoute = function AuthenticatedRoute(loginRoute, authenticatedCheckFxn) {
  return function (_ref) {
    var rootPath = _ref.rootPath,
        Component = _ref.component,
        path = _ref.path,
        rest = (0, _objectWithoutProperties3.default)(_ref, ['rootPath', 'component', 'path']);

    return _react2.default.createElement(_reactRouter.Route, (0, _extends3.default)({}, rest, {
      key: path,
      render: function render(props) {
        return authenticatedCheckFxn(props) ? rest.location.pathname !== '/' ? _react2.default.createElement(Component, (0, _extends3.default)({}, props, rest)) : _react2.default.createElement(_reactRouterDom.Redirect, { to: rootPath }) : _react2.default.createElement(_reactRouterDom.Redirect, { to: { pathname: loginRoute, state: { from: props.location } } });
      }
    }));
  };
};

exports.AuthenticatedRoute = AuthenticatedRoute;
var GuestOnlyRoute = function GuestOnlyRoute(defaultRoute, authenticatedCheckFxn) {
  return function (_ref2) {
    var Component = _ref2.component,
        path = _ref2.path,
        rest = (0, _objectWithoutProperties3.default)(_ref2, ['component', 'path']);

    return _react2.default.createElement(_reactRouter.Route, (0, _extends3.default)({}, rest, {
      key: path,
      render: function render(props) {
        return !authenticatedCheckFxn(props) || path.indexOf('emailverify') !== 1 ? _react2.default.createElement(Component, (0, _extends3.default)({}, props, rest)) : _react2.default.createElement(_reactRouterDom.Redirect, { to: { pathname: defaultRoute, state: { from: props.location } } });
      }
    }));
  };
};
exports.GuestOnlyRoute = GuestOnlyRoute;