'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Sider = exports.Page = exports.Modal = exports.Header = undefined;

var _Header = require('./Header');

var _Header2 = _interopRequireDefault(_Header);

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _Page = require('./Page');

var _Page2 = _interopRequireDefault(_Page);

var _Sider = require('./Sider');

var _Sider2 = _interopRequireDefault(_Sider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Header = _Header2.default;
exports.Modal = _Modal2.default;
exports.Page = _Page2.default;
exports.Sider = _Sider2.default;