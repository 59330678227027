import React, { PureComponent } from 'react'
import { Checkbox } from 'antd'

import './FormCheckbox.scss'

class FormCheckbox extends PureComponent {

  handleOnChange = (e) => {
    const { formRef } = this.props
    formRef.props.form.setFieldsValue({
      [this.props.id]: e
    })
  }

  render () {
    const { options, value, disabled } = this.props
    if (!options) return null
    return (
      <Checkbox.Group
        value={value || undefined} 
        onChange={this.handleOnChange} 
        className='form-checkbox'
        disabled={disabled}
      >
        {options.map((option, index) => (
          <Checkbox 
            key={`${option.id}-${index}`}
            value={option.id}

          >
            {option.name}
          </Checkbox>
        ))}
      </Checkbox.Group>
    )
  }
}

export default FormCheckbox