'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

require('./Modal.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MHealthModal = function (_Component) {
  (0, _inherits3.default)(MHealthModal, _Component);

  function MHealthModal() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, MHealthModal);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = MHealthModal.__proto__ || (0, _getPrototypeOf2.default)(MHealthModal)).call.apply(_ref, [this].concat(args))), _this), _this.state = {}, _this.handleOnCancel = function () {
      var _this$props = _this.props,
          dispatch = _this$props.mHealthMain.dispatch,
          onCancel = _this$props.UI.modal.onCancel;

      if (onCancel) onCancel();
      dispatch.setModalVisibility(false);
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(MHealthModal, [{
    key: 'render',
    value: function render() {
      if (!this.props.UI.modal.visible) {
        return null;
      }

      var _props$UI$modal = this.props.UI.modal,
          okText = _props$UI$modal.okText,
          visible = _props$UI$modal.visible,
          title = _props$UI$modal.title,
          onOk = _props$UI$modal.onOk,
          content = _props$UI$modal.content,
          okButtonProps = _props$UI$modal.okButtonProps,
          cancelButtonProps = _props$UI$modal.cancelButtonProps,
          className = _props$UI$modal.className,
          width = _props$UI$modal.width;


      return _react2.default.createElement(
        _antd.Modal,
        {
          visible: visible,
          title: title,
          okText: okText,
          onOk: onOk,
          onCancel: this.handleOnCancel,
          maskClosable: true,
          centered: true,
          width: width ? width : "-webkit-fit-content",
          okButtonProps: okButtonProps,
          cancelButtonProps: cancelButtonProps,
          className: className
        },
        content
      );
    }
  }]);
  return MHealthModal;
}(_react.Component);

exports.default = MHealthModal;