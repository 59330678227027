export default [
  {
    label: 'Add Patient',
    className: 'add-patient-link',
    key: 'add-patient-link',
    onClick: (e, props) => {
      props.mHealthMain.dispatch.navigateTo('/patients/action/create')
    },
  },
  {
    label: 'Study Team Member',
    className: 'add-study-team-member-link',
    key: 'add-study-team-member-link',
    onClick: (e, props) => {
      props.mHealthMain.dispatch.navigateTo('/dashboardUsers/action/create')
    },
  }
]