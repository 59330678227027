"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var mapManagersToSelectOptions = function mapManagersToSelectOptions(managers, hasPermission) {
  var arr = [];
  managers.forEach(function (manager) {
    arr.push({
      label: manager.first_name + " " + manager.last_name,
      value: manager.id
    });
  });
  return arr;
};

var mapStudiesToSelectOptions = function mapStudiesToSelectOptions(studies) {
  var arr = [];
  studies.forEach(function (study) {
    arr.push({
      label: study.title,
      value: study.id
    });
  });
  return arr;
};

exports.mapManagersToSelectOptions = mapManagersToSelectOptions;
exports.mapStudiesToSelectOptions = mapStudiesToSelectOptions;