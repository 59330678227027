import React from 'react'
import { Icon } from 'antd'
import { Notification } from '@adkgroup/appkit-ui'
import moment from 'moment'

import ResourceModal from '@/modules/EntityManagementModule/screens/Response/components/ResourceModal'
import UploadedResourceModal from '@/modules/EntityManagementModule/screens/Response/components/UploadedResourceModal'
import AttemptModal from '@/modules/EntityManagementModule/screens/Response/components/AttemptModal'
import attemptConfigs from './attempts'

export default [
  {
    type: 'text',
    value: 'Question',
    fieldKey: 'question',
    fieldLocation: '',
    filtered: true,
    sorter: true,
    render: (record, mHealthMain) => {
      let content = record.question && record.question.length > 200
        ? record.question.substring(0, 200)
        : record.question
      const modalContent = record.items && record.items.length > 0
        ? record.items.filter(item => item.type !== 'basic text')
        : null
      return <div style={{ width: 275 }} className='patient-response-question-column'>
        <div className='patient-response-question-content'>{content}</div>
        { modalContent && modalContent.length > 0 &&
          <Icon
            type='plus-circle'
            className='patient-response-question-resource'
            onClick={() => {
              mHealthMain.dispatch.setModalContent({
                visible: true,
                content: <ResourceModal resource={modalContent} />,
                onOk: () => mHealthMain.dispatch.setModalVisibility(false)
              })
              mHealthMain.dispatch.setModalVisibility(true)
            }}
          /> }
      </div>
    }
  },
  {
    type: 'text',
    value: 'Response',
    fieldKey: 'response',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (record, mHealthMain) => {
      if (!record.responseUrl) {
        return <div style={{ width: 275 }}>{record.response}</div>
      } else {
        return <div style={{ width: 275 }} className='patient-response-resource-column'>
          { <div 
            className='view-response-resource-url' 
            onClick={() => {
              mHealthMain.dispatch.setModalContent({
                visible: true,
                content: <UploadedResourceModal resource={record} />,
                onOk: () => mHealthMain.dispatch.setModalVisibility(false),
                className: 'uploaded-resource-modal'
              })
              mHealthMain.dispatch.setModalVisibility(true)
            }}
          >
            View Resource
          </div> }
        </div>
      }
    }
  },
  {
    type: 'text',
    value: 'Answer Check',
    fieldKey: 'isCorrect',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    width: 150,
    render: (record, mHealthMain) => {
      if (record && record.isCorrect === true) {
        return <div className='patient-response-answer-check-container'>
          <Icon
            type='check-circle'
            className='patient-response-answer-check-correct-icon'
          />
          <div className='patient-response-answer-check-correct-text'>
            Correct
          </div>
        </div>
      } else if (record && record.isCorrect === false) {
        return <div className='patient-response-answer-check-container'>
          <Icon
            type='close-circle'
            className='patient-response-answer-check-incorrect-icon'
          />
          <div className='patient-response-answer-check-incorrect-text'>
            Incorrect
          </div>
        </div>
      } else return null
    }
  },
  {
    type: 'text',
    value: 'Attempts',
    fieldKey: 'attemptCount',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    width: 150,
    render: (record, mHealthMain) => {
      if (record && record.attemptCount) {
        return <div
          className='patient-response-attempt-count'
          onClick={async() => {
            try {
              const response = await mHealthMain.api.getAttempts(record.questionnaireResponseItemId, mHealthMain.models.attempts)
              if (response.errors && response.errors.length > 0) throw ('error')
              mHealthMain.dispatch.setModalContent({
                visible: true,
                content: <AttemptModal
                  data={response.data}
                  configs={attemptConfigs}
                  record={record}
                  mHealthMain={mHealthMain}
                />,
                onOk: () => mHealthMain.dispatch.setModalVisibility(false),
                okText: 'Done',
                cancelButtonProps: { style: { display: 'none' } },
                className: 'attempts-table-modal',
                title: record.question,
              })
              mHealthMain.dispatch.setModalVisibility(true)
            } catch (error) {
              Notification({
                message: 'Failed to load survey response attempts',
                description: 'Please try again later'
              })
            }

          
          }}
        >{record.attemptCount} {record.attemptCount === 1 ? 'Attempt' : 'Attempts'}</div>}
    }
  },
  {
    type: 'text',
    value: 'Survey',
    fieldKey: 'survey',
    fieldLocation: '',
    filtered: true,
    sorter: true,
  },
  {
    type: 'text',
    value: 'Date',
    fieldKey: 'date',
    fieldLocation: '',
    filtered: true,
    filterType: 'date-range',
    filterTransform: (dates) => {
      return dates.map(d => d.format("MM/DD/YYYY"))
    },
    filterKeys: ['startDate', 'endDate'],
    sorter: true,
    render: (record, mHealthMain) => {
      if (record.date && record.date !== '') {
        return <div>{moment.utc(moment.unix(record.date)).local().format("MM/DD/YYYY hh:mm:ss A zz")}</div>
      }
    }
  },
]