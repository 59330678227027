import React from 'react'

class ResourceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }

  renderResource = resource => {
    return resource.map((r, index) => {
      switch(r.type) {
        case 'image':
          return <img
            key={`resource-${index}-type-${r.type}`}
            src={r.question}
            className="resource-image"
            alt={"Resource cannot be loaded"}
            style={{ maxWidth: window.innerWidth * 0.50 }}
          />
        case 'video':
          return <video
            key={`resource-${index}-type-${r.type}`}
            controls
            muted
            width={window.innerWidth * 0.50}
          >
            <source src={r.question} />
            <p>Your browser doesn't support HTML video. Here is a <a href={r.question}>link to the video</a> instead.</p>
          </video>
        case 'audio':
          return <audio
            key={`resource-${index}-type-${r.type}`}
            controls
            src={r.question}
          >
            <p>Your browser doesn't support HTML video. Here is a <a href={r.question}>link to the video</a> instead.</p>
          </audio>
        default:
          return null
      }
    })
  }

  render() {
    const { resource } = this.props
    return (
      <div className="resource-content-container">
        {resource.length > 0 && this.renderResource(resource)}
      </div>
    );
  }
}

export default ResourceModal;