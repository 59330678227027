'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAuthorized = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Form = require('../Form');

var _Form2 = _interopRequireDefault(_Form);

require('./editEntity.css');

var _antd = require('antd');

var _isEqual = require('lodash/isEqual');

var _isEqual2 = _interopRequireDefault(_isEqual);

var _intersection = require('lodash/intersection');

var _intersection2 = _interopRequireDefault(_intersection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isAuthorized = exports.isAuthorized = function isAuthorized() {
  var userPermissions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var requiredPermissions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  // The route doesn't have any required permissions
  if (!requiredPermissions.length) return true;
  // The route has required permissions and the user has none
  else if (requiredPermissions.length && !userPermissions.length) return false;else return (0, _intersection2.default)(userPermissions, requiredPermissions).length === requiredPermissions.length;
};

var EditEntity = function (_React$Component) {
  (0, _inherits3.default)(EditEntity, _React$Component);

  function EditEntity(props) {
    (0, _classCallCheck3.default)(this, EditEntity);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EditEntity.__proto__ || (0, _getPrototypeOf2.default)(EditEntity)).call(this, props));

    _this.injectInitialValues = function (configs, entity, ref) {
      if (configs.patchForm) {
        return configs.formConfig(ref).map(function (section) {
          return (0, _extends3.default)({}, section, {
            fields: section.fields.map(function (field) {
              return (0, _extends3.default)({}, field, {
                initialValue: field.initialValueTransform ? field.initialValueTransform(entity[field.id]) : entity[field.id]
              });
            })
          });
        });
      } else {
        return configs.formConfig;
      }
    };

    _this.renderStatusToggle = function (configs, entity, userPermissions) {
      return _react2.default.createElement(
        'div',
        { className: 'edit-entity-status-toggle-container' },
        _react2.default.createElement(_antd.Switch, {
          className: 'edit-entity-status-toggle ' + (entity && entity.status === 'CONFIRMED' && 'active'),
          onChange: function onChange() {
            return configs.toggleStatus(_this.props, entity);
          },
          checked: entity && entity.status === 'CONFIRMED',
          disabled: entity && entity.status === 'UNCONFIRMED' || !isAuthorized(userPermissions, configs.permissions)
        }),
        _react2.default.createElement(
          'div',
          { className: 'edit-entity-status-label' },
          entity && configs.ENTITY_STATUS[entity.status]
        )
      );
    };

    _this.state = {};
    return _this;
  }

  (0, _createClass3.default)(EditEntity, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      return (0, _isEqual2.default)(this.props.entityProfile, prevProps.entityProfile);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          configs = _props.configs,
          entity = _props.entity,
          permissions = _props.myProfile.role.permissions;

      if (!this.form) this.forceUpdate();
      var userPermissions = permissions.map(function (p) {
        return p.name;
      });
      return _react2.default.createElement(
        'div',
        { className: 'edit-entity-container' },
        _react2.default.createElement(
          'div',
          { className: 'edit-entity-title' },
          configs.title
        ),
        configs.toggleStatus && this.renderStatusToggle(configs, entity, userPermissions),
        _react2.default.createElement(_Form2.default, (0, _extends3.default)({}, this.props, {
          className: 'edit-entity-form',
          wrappedComponentRef: function wrappedComponentRef(form) {
            return _this2.form = form;
          },
          onSubmit: function onSubmit(event) {
            return configs.onSubmit(event, entity);
          },
          configs: this.injectInitialValues(configs, entity, this.form),
          disabled: entity && entity.status !== 'CONFIRMED' || !isAuthorized(userPermissions, configs.permissions)
        }))
      );
    }
  }]);
  return EditEntity;
}(_react2.default.Component);

exports.default = EditEntity;