import moment from 'moment'

import { buildPostConfig } from '../../../../utils/axios/helpers'
import { formatTableDataForAPI } from '../../../../features/TreatmentTable/helpers/treatmentTableHelpers'

export const createPatientDetailsConfig = (values, tables, guid) => {
  const formattedData = formatTableDataForAPI(tables)
  const formattedNextAppointmentDate = moment(values.dateOfNextAppointment).format('YYYY-MM-DD')
  const formattedNextAppointmentTime = moment(values.timeOfNextAppointment).format('HH:mm:ss')
  const dateTimeOfNextAppointment = moment(formattedNextAppointmentDate + ' ' + formattedNextAppointmentTime).unix()
  return buildPostConfig(`user/${guid}/patientDetails`, 'post', {
    reconstructionType: 'DIEP',
    therapyType: 'Radiation',
    dateOfDiagnosis: moment(values.dateOfDiagnosis).format('YYYY-MM-DD'),
    height: 0,
    weight: 0,
    histologicType: values.histologicType,
    permissionToText: values.permissionToText === 'no' ? false : true,
    complications: values.complications,
    email: values.emailAddress,
    locationOfNextClinicalAppointment: values.locationOfNextAppointment,
    ...values.dateOfNextAppointment && values.timeOfNextAppointment && { dateTimeOfNextClinicalAppointment: dateTimeOfNextAppointment },
    preferedMethodOfContacts: values.preferredContactMethod,
    ...formattedData
  })
}