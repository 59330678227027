import React, { PureComponent } from 'react'
import { Icon } from 'antd'
import { v4 } from 'uuid'

import TwoDashIcon from '@/shared/components/TwoDashIcon'
import PatientSurveyCategory from './components/PatientSurveyCategory'
import './PatientSurveyCategories.scss'

class PatientSurveyCategories extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.categories) return null
    return (
      <div className='patient-overview-survey-categories'>
        <div className='patient-overview-survey-categories__key'>
          <div className='patient-overview-survey-categories__key__item'>
            <Icon  
              className='patient-overview-survey-categories__key__item__icon patient-overview-survey-categories__key__item__icon--teal' 
              type='arrow-up' 
            />
            <span className='patient-overview-survey-categories__key__item__text'>Improved since previous</span>
          </div>
          <div className='patient-overview-survey-categories__key__item'>
            <Icon 
              className='patient-overview-survey-categories__key__item__icon patient-overview-survey-categories__key__item__icon--red' 
              type='arrow-down' 
            />
            <span className='patient-overview-survey-categories__key__item__text'>Decreased since previous</span>
          </div>
          <div className='patient-overview-survey-categories__key__item'>
            <TwoDashIcon 
              className='patient-overview-survey-categories__key__item__icon patient-overview-survey-categories__key__item__icon--gray' 
            />
            <span className='patient-overview-survey-categories__key__item__text'>Same as previous</span>
          </div>
          <div className='patient-overview-survey-categories__key__item'>
            <Icon 
              className='patient-overview-survey-categories__key__item__icon patient-overview-survey-categories__key__item__icon--red' 
              type='stop' 
            />
            <span className='patient-overview-survey-categories__key__item__text'>No previous</span>
          </div>
        </div>
        {
          this.props.categories.map((category) => <PatientSurveyCategory dispatch={this.props.dispatch} key={v4()} category={category} />)
        }
      </div>
    )
  }
}

export default PatientSurveyCategories