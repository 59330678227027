'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AntButton = function (_React$Component) {
  (0, _inherits3.default)(AntButton, _React$Component);

  function AntButton(props) {
    (0, _classCallCheck3.default)(this, AntButton);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AntButton.__proto__ || (0, _getPrototypeOf2.default)(AntButton)).call(this, props));

    _this.state = {};
    return _this;
  }

  (0, _createClass3.default)(AntButton, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          key = _props.key,
          label = _props.label,
          _props$type = _props.type,
          type = _props$type === undefined ? "primary" : _props$type,
          _props$htmlType = _props.htmlType,
          htmlType = _props$htmlType === undefined ? "submit" : _props$htmlType,
          className = _props.className,
          style = _props.style,
          onClick = _props.onClick,
          icon = _props.icon,
          size = _props.size,
          disabled = _props.disabled;

      return _react2.default.createElement(
        _antd.Button,
        {
          key: key,
          type: type,
          htmlType: htmlType,
          className: className,
          style: style,
          onClick: onClick,
          icon: icon,
          size: size,
          disabled: disabled
        },
        label
      );
    }
  }]);
  return AntButton;
}(_react2.default.Component);

exports.default = AntButton;