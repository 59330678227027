'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = queueScheduler;

var _endpoints = require('../../endpoints');

function queueScheduler(payload) {
  return ['POST', true, _endpoints.QUESTIONNAIRE_SCHEDULER_ROUTE, payload];
}