'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patchDiff = exports.getNestedValue = exports.trunc = undefined;

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var trunc = exports.trunc = function trunc(s, length) {
  if (s && s.length > length) {
    return s.substring(0, length) + '...';
  } else {
    return s || '';
  }
};

var getNestedValue = exports.getNestedValue = function getNestedValue(obj, path) {
  for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
    obj = obj[path[i]];
  };
  return obj;
};

var patchDiff = exports.patchDiff = function patchDiff(source, input) {
  if ((typeof source === 'undefined' ? 'undefined' : (0, _typeof3.default)(source)) !== 'object' || (typeof input === 'undefined' ? 'undefined' : (0, _typeof3.default)(input)) !== 'object') {
    return null;
  } else {
    var diffKeys = [];
    (0, _keys2.default)(input).forEach(function (key) {
      if ((0, _stringify2.default)(source[key]) !== (0, _stringify2.default)(input[key])) {
        diffKeys.push(key);
      }
    });
    return diffKeys;
  }
};