'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _antd = require('antd');

require('./SiderMenu.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SiderMenu = function (_PureComponent) {
  (0, _inherits3.default)(SiderMenu, _PureComponent);

  function SiderMenu() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, SiderMenu);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = SiderMenu.__proto__ || (0, _getPrototypeOf2.default)(SiderMenu)).call.apply(_ref, [this].concat(args))), _this), _this.onOpenChange = function (openKeys) {
      var menus = _this.props.menus;

      var rootSubmenuKeys = menus.filter(function (_) {
        return !_.menuParentId;
      }).map(function (_) {
        return _.id;
      });

      var latestOpenKey = openKeys.find(function (key) {
        return _this.state.openKeys.indexOf(key) === -1;
      });

      var newOpenKeys = openKeys;
      if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
        newOpenKeys = latestOpenKey ? [latestOpenKey] : [];
      }

      _this.setState({
        openKeys: newOpenKeys
      });
      // store.set('openKeys', newOpenKeys)
    }, _this.generateMenus = function () {
      return _this.props.mHealthMain.getModules().map(function (item, index) {
        if (!item.masterConfig.subMenu) {
          return _react2.default.createElement(
            _antd.Menu.Item,
            {
              key: item.route,
              onClick: function onClick() {
                return item.masterConfig.navItem.onClick(_this.props, item);
              },
              className: 'module-nav-container'
            },
            _react2.default.createElement(
              'div',
              { className: 'module-nav-title' },
              item.icon && _react2.default.createElement(_antd.Icon, { type: item.icon }),
              _react2.default.createElement(
                'span',
                null,
                item.prettyName
              )
            )
          );
        } else {
          var menuItems = [_react2.default.createElement(
            _antd.Menu.Item,
            {
              key: item.route,
              onClick: function onClick() {
                return item.masterConfig.navItem.onClick(_this.props, item);
              },
              className: 'module-nav-container'
            },
            _react2.default.createElement(
              'div',
              { className: 'module-nav-title' },
              item.icon && _react2.default.createElement(_antd.Icon, { type: item.icon }),
              _react2.default.createElement(
                'span',
                null,
                item.prettyName
              )
            )
          )];

          menuItems.push(item.masterConfig.subMenu.map(function (subItem, index) {
            return _react2.default.createElement(
              _antd.Menu.Item,
              {
                className: 'module-nav-subitem-container',
                key: 'module-nav-' + item.route + '-subitem-' + index,
                onClick: function onClick() {
                  return subItem.onClick(_this.props, item);
                }
              },
              _react2.default.createElement(_antd.Icon, { component: function component() {
                  return _react2.default.createElement('div', { className: 'nav-subitem-badge ' + subItem.title });
                } }),
              _react2.default.createElement(
                'span',
                null,
                _react2.default.createElement(
                  'div',
                  { className: 'nav-subitem-title' },
                  subItem.title
                ),
                _react2.default.createElement(
                  'div',
                  { className: 'nav-subitem-count' },
                  _this.props.statusCounts[subItem.countKey] ? _this.props.statusCounts[subItem.countKey].totalCount : 0
                )
              )
            );
          }));
          return menuItems.map(function (mi) {
            return mi;
          });
        }
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(SiderMenu, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          collapsed = _props.collapsed,
          theme = _props.theme,
          menus = _props.menus,
          location = _props.location,
          isMobile = _props.isMobile,
          onCollapseChange = _props.onCollapseChange;

      // Generating tree-structured data for menu content.
      // const menuTree = arrayToTree(menus, 'id', 'menuParentId')

      // Find a menu that matches the pathname.
      // const currentMenu = menus.find(
      //   _ => _.route && pathMatchRegexp(_.route, location.pathname)
      // )

      // Find the key that should be selected according to the current menu.
      // const selectedKeys = currentMenu
      //   ? queryAncestors(menus, currentMenu, 'menuParentId').map(_ => _.id)
      //   : []

      // const menuProps = collapsed
      //   ? {}
      //   : {
      //       openKeys: this.state.openKeys,
      //     }

      return _react2.default.createElement(
        _antd.Menu,
        {
          selectedKeys: this.props.mHealthMain.getModules().map(function (m) {
            if (window.location.pathname.indexOf(m.route) !== -1) {
              return m.route;
            }
          }),
          mode: 'inline',
          theme: theme,
          onOpenChange: this.onOpenChange,
          inlineCollapsed: collapsed
          // selectedKeys={selectedKeys}
          , onClick: isMobile ? function () {
            onCollapseChange(true);
          } : undefined
          // {...menuProps}
        },
        this.generateMenus()
      );
    }
  }]);
  return SiderMenu;
}(_react.PureComponent);

SiderMenu.propTypes = {
  menus: _propTypes2.default.array,
  theme: _propTypes2.default.string,
  isMobile: _propTypes2.default.bool,
  collapsed: _propTypes2.default.bool,
  onCollapseChange: _propTypes2.default.func
};

exports.default = SiderMenu;