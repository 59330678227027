import React from 'react'

import CompleteHealthGraph from './components/CompleteHealthGraph'
import './CompleteHealth.scss'

const CompleteHealth = () => {
  return (
    <div className='complete-health'>
      <div className='complete-health-content'>
        <h3 className='complete-health-content__title'>Complete Health</h3>
        <CompleteHealthGraph />
      </div>
    </div>
  )
}

export default CompleteHealth