import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { v4 } from 'uuid'

import { answerMatches } from '../../../constants/patientAnswerMatches'

import './PatientAnswers.scss'

class PatientAnswers extends PureComponent {

  renderAnswers = () => {
    const { activeAnswers, name } = this.props
    return activeAnswers.answers.map((answer) => {
      const answerText = activeAnswers.questions[answer.questionnaireItemId].text
      if (!answer.answers.length || (answerMatches[name] && answerMatches[name].indexOf(answerText.toLowerCase().trim().replace(/\s+/g, ' ')) === -1)) return null
      return (
        <tr className='patient-overview-answers__table__body__row' key={v4()}>
          <td 
            className='patient-overview-answers__table__body__row__data patient-overview-answers__table__body__row__data--question'
          >
            {answerText}
          </td>
          <td 
            className='patient-overview-answers__table__body__row__data patient-overview-answers__table__body__row__data--value'
          >
            {`${answer.answers[0].valueString}`}
          </td>
        </tr>
      )
    })
  }

  render () {
    return (
      <div className='patient-overview-answers'>
        <table className='patient-overview-answers__table'>
          <thead className='patient-overview-answers__table__head'>
            <tr className='patient-overview-answers__table__head__row'>
              <th 
                className='patient-overview-answers__table__head__row__head 
              patient-overview-answers__table__head__row__head--question'
              >
                Question
              </th>
              <th 
                className='patient-overview-answers__table__head__row__head 
              patient-overview-answers__table__head__row__head--answer'
              >
                Answer
              </th>
            </tr>
          </thead>
          <tbody className='patient-overview-answers__table__body'>
            {this.renderAnswers()}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = ({ overviewReducer }) => {
  const { activeAnswers, activeDomain: { name } } = overviewReducer
  return { activeAnswers, name }
}

export default connect(mapStateToProps)(PatientAnswers)