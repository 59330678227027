import { Notification } from '@adkgroup/appkit-ui'
import get from 'lodash/get'

export default {
  requiredPermissions: {
    list: [],
    profile: [],
    response: [],
    create: props => get(props, ['roles', 'SuperAdmin', 'permissions'], [])
      .map(p => p.name)
      .filter(p => p.includes('.add'))
      .filter(p => !p.includes('patient')),
  }, 
  onPermissionError: {
    list: () => Notification({ message: 'Permission Error', description: 'You are missing required permissions' }),
    profile: () =>  Notification({ message: 'Permission Error', description: 'You are missing required permissions' }),
    response: () =>  Notification({ message: 'Permission Error', description: 'You are missing required permissions' }),
    create: () =>  Notification({ message: 'Permission Error', description: 'You are missing required permissions' }),
  },
  fallbackRoute: '/dashboardUsers'
}