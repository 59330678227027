'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPatientResponses;

var _gqlUtils = require('../../gqlUtils');

function getPatientResponses(responseItemId, items) {
  var pagination = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  var paginationArg = pagination ? (0, _gqlUtils.paginationBuilder)(pagination) : 'first: 8';
  var argumentStr = '(id: ' + responseItemId + ', ' + paginationArg + ')';
  var itemsFragment = (0, _gqlUtils.queryBuilder)(items);

  var query = '{\n    attempts ' + argumentStr + '\n    {\n      items\n      {\n        ' + itemsFragment + '\n      }\n      pageInfo\n      {\n        hasPreviousPage,\n        startCursor,\n        hasNextPage,\n        endCursor,\n      }\n      totalCount\n    }\n  }';
  return [query];
}