const {
  REACT_APP_AWS_BUCKET_NAME,
  REACT_APP_AWS_IDENTITY_POOL_ID,
  REACT_APP_AWS_LOGIN_POOL,
  REACT_APP_AWS_REGION
} = process.env;

export const returnAWSCredentials = () => ({
  REACT_APP_AWS_BUCKET_NAME,
  REACT_APP_AWS_IDENTITY_POOL_ID,
  REACT_APP_AWS_LOGIN_POOL,
  REACT_APP_AWS_REGION
});
