'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PasswordValidation = function (_React$Component) {
  (0, _inherits3.default)(PasswordValidation, _React$Component);

  function PasswordValidation(props) {
    (0, _classCallCheck3.default)(this, PasswordValidation);

    var _this = (0, _possibleConstructorReturn3.default)(this, (PasswordValidation.__proto__ || (0, _getPrototypeOf2.default)(PasswordValidation)).call(this, props));

    _this.renderValidationList = function () {
      var password = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      return PASSWORD_CONDITIONS.map(function (condition) {
        var passed = condition.regex.test(password);
        return _react2.default.createElement(
          'div',
          { className: 'validation-condition' },
          _react2.default.createElement(_antd.Icon, {
            type: 'check-circle',
            className: 'condition ' + (passed && 'passed')
          }),
          _react2.default.createElement(
            'div',
            { className: 'condition-text' },
            condition.text
          )
        );
      });
    };

    _this.state = {};
    return _this;
  }

  (0, _createClass3.default)(PasswordValidation, [{
    key: 'render',
    value: function render() {
      var password = this.props.password;

      return _react2.default.createElement(
        'div',
        { className: 'password-validation' },
        this.renderValidationList(password)
      );
    }
  }]);
  return PasswordValidation;
}(_react2.default.Component);

exports.default = PasswordValidation;


var PASSWORD_CONDITIONS = [{
  key: '',
  regex: /^(?=.{8,})/,
  text: "At least 8 characters long"
}, {
  key: '',
  regex: /^(?=.*[a-z])/,
  text: "At least one lowercase letter"
}, {
  key: '',
  regex: /^(?=.*[A-Z])/,
  text: "At least one uppercase letter"
}, {
  key: '',
  regex: /^(?=.*\d)/,
  text: "At least one number"
}, {
  key: '',
  regex: /^(?=.*?[#?!@$%^&*-])/,
  text: "At least one special character"
}];