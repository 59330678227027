import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import S3 from '@/shared/aws'
import {
  statusChecker,
  // ActivityFxnTimer
} from '@adkgroup/appkit-utils'
import {
  Alert,
  Header,
  Sider,
  Notification,
} from '@adkgroup/appkit-ui'
import { Layout, Drawer, Spin } from 'antd'
import { enquireScreen } from 'enquire-js'
import { LOGO } from '../../configs/app'
import { addEntityDropdown } from '@/configs/app/header'
import cloneDeep from 'lodash/cloneDeep'
import './BaseLayoutScreen.scss'
import '../../styles/patientTable.scss'

import { buildRequestConfig, improveAxios } from '../../utils/axios/helpers'
import { setSharedUIField } from '../../shared/redux/ui/sharedActions'
const { Content } = Layout


export default class BaseLayout extends Component {
  state = {
    actionBarWidth: '0px',
    loading: true,
    isMobile: false,
    collapsed: false,
    fixedHeader: true
  }

  componentDidCatch (error, info) {
    // catch Type Errors and other errors here
    // this.props.mHealthMain.dispatch.createErrorLog(error)
  }

  componentDidMount () {
    // window.addEventListener('resize', this.setActionBarWidth)
    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
        })
      }
    })
    this.init()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.mHealthMain.myProfile) {
      return this.props.mHealthMain.myProfile.emailVerified !== prevProps.mHealthMain.myProfile.emailVerified
    }
  }

  refreshToken = async () => {
    const token = window.localStorage.getItem('session_token')
    const req = await this.props.mHealthMain.api.tokenRefresh(token)
    if (statusChecker(req.status)) {
      window.localStorage.setItem('session_token', req.data.token)
    } else {
      this.props.mHealthMain.dispatch.logoutUser()
    }
  }

  init = async () => {
    const { mHealthMain: { dispatch }, mConfig, location } = this.props

    let requests = mConfig.baseConfig.baseDataRequests.map((obj, index) => {
      return obj.request().catch(e => {
        const requestConfig = mConfig.baseConfig.baseDataRequests[index]
        const { reduxName, defaultData, errorMessage } = requestConfig
        dispatch.setData(reduxName, defaultData)
        Notification({
          message: errorMessage,
          description: 'Please try again later'
        })
      })
    })
    const config = buildRequestConfig('selectBoxOptions', 'get')
    const response = await improveAxios.request(config)
    dispatch.setSharedUIField(response.data)
    Promise.all([...requests]).then(async (values) => {
      values.forEach((response, index) => {
        const {
          reduxName,
          defaultData,
          errorMessage,
          preReduxTransform,
          postReduxFxns
        } = mConfig.baseConfig.baseDataRequests[index]
        if (response && response.data && !response.errors){
          // if you need to transform the data for some reason
          // Ex: adding the phase field to ERAS paitents since backend is lazy
          let data = response.data
          if (preReduxTransform) {
            data = preReduxTransform(response)
          }
  
          // works for base data only..
          // TODO: Can setData become dynamic for what reducers have been loaded if naming convention used?
          if (reduxName) { // when no data, if (reduxName && data)
            dispatch.setData(reduxName, data)
          }
  
          // Further functions you want to handle with the response
          // Used to set non basedata redux actions (Ex: Subjects from MCSRT and GTI)
          if (postReduxFxns) {
            postReduxFxns.forEach(fxn => fxn(response))
          }
        }
        if (response && response.errors) {
          // If request fails and catch, set store state to default data in order to avoid app crash
          if (reduxName) {
            dispatch.setData(reduxName, defaultData)
            Notification({
              message: errorMessage,
              description: 'Please try again later'
            })
          }
        }
      })
      this.setActionBarWidth()
      if (location.payload) {
        const { username, pin } = location.payload
        const accessToken = window.localStorage.access_token
        this.verifyEmail(accessToken, username, pin)
      }

      await S3.init({ idToken: window.localStorage.getItem("session_token") })

      return true
    })
  }

  // componentWillUnmount () {
  //   window.removeEventListener('resize', this.setActionBarWidth)
  // }

  setActionBarWidth = () => {
    if (this.refs['maincontentarea']) {
      this.setState({
        loading: false
      }, () => {
        // this.setState({
        //   actionBarWidth: `${this.refs['maincontentarea'].clientWidth}px`,
        // })
      })
    }
  }

  isLoading = () => {
    return this.state.loading
  }

  onCollapseChange = (collapsed) => {
    this.setState({
      collapsed
    })
  }

  resendVerification = async () => {
    const {
      mHealthMain,
      myProfile,
    } = this.props
    try {
      await mHealthMain.api.resendVerification()
      Notification({
        message: 'Email verification sent',
        description: 'Please check your e-mail'
      })
      // Change emailVerified redux state boolean
      let copy = cloneDeep(myProfile)
      copy.emailVerified = true
      mHealthMain.dispatch.setData('myProfile', copy)
    } catch (error) {
      Notification({
        message: 'Email verification attempt failed',
        description: 'Please try again later'
      })
    }
  }

  verifyEmail = async (accessToken, username, pin) => {
    const { myProfile, mHealthMain: { api, dispatch } } = this.props
    const payload = {
      accessToken,
      pin,
    }
    try {
      await api.verifyEmail(username, payload)
      let copy = cloneDeep(myProfile)
      copy.emailVerified = true
      await dispatch.setData('myProfile', copy)
      Notification({ message: 'Your email has been successfully verified' })
    } catch (error) {
      console.error(error)
      Notification({
        message: 'Email verification attempt failed',
        description: 'Please try again later'
      })
    }
  }

  render () {
    const { isMobile, collapsed, fixedHeader } = this.state
    const { onCollapseChange } = this

    if (this.isLoading()) {
      return (
        <main ref='maincontentarea'>
          <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spin />
            <span style={{ marginLeft: '10px' }}>Loading Data...</span>
          </div>
        </main>
      )
    }

    const siderProps = {
      logo: LOGO,
      brandName: null,
      theme: 'light',
      menus: [],
      isMobile,
      collapsed,
      onCollapseChange,
      onThemeChange: (theme) => {
        // dispatch({
        //   type: 'app/handleThemeChange',
        //   payload: theme,
        // })
      },
    }

    const headerProps = {
      menus: addEntityDropdown,
      collapsed,
      notifications: [],
      onCollapseChange,
      avatar: null,
      // username: 'GuyGuyBrother',
      fixed: fixedHeader,
      onAllNotificationsRead: () => {
        // dispatch({ type: 'app/allNotificationsRead' })
      },
      onSignOut: () => {
        // dispatch({ type: 'app/signOut' })
      },
    }

    return (
      <div>
        {/* <ActivityFxnTimer minutes={1} onClickAfterTimer={this.refreshToken} /> */}
        <div id='dashboard'>
          <Layout>
            {process.env.REACT_APP_TEST_VAR}
            <Header
              {...headerProps}
              {...this.props} 
              logo={LOGO}
            />
            <Layout className='below-header-content'>
              {isMobile ? (
                <Drawer
                  maskClosable
                  closable={false}
                  onClose={onCollapseChange.bind(this, !collapsed)}
                  visible={!collapsed}
                  placement='left'
                  width={300}
                  style={{
                    padding: 0,
                    height: '100vh',
                  }}
                >
                  <Sider
                    {...siderProps}
                    {...this.props}
                    collapsed={false}
                  />
                </Drawer>
              ) : (
                <Sider
                  {...siderProps}
                  {...this.props}
                />
              )}
              <div
                className={'container'}
                style={{ paddingTop: this.state.fixedHeader ? 72 : 0 }}
                id='primaryLayout'
              >
                
                {!this.props.myProfile.emailVerified &&
                  <Alert
                    message={<div className='email-verify-warning'>
                        Please verify your email address by clicking &nbsp;
                      <a href='#' onClick={this.resendVerification}>here</a>
                    </div>}
                    type='warning'
                    banner
                  />}
                <Content className={'content'}>
                  <Switch>
                    {/* mHealthMain outputs routes dynamically for all its screens, this output below is a <Switch> router for
                    all of the modules screens loaded */}
                    { this.props.mHealthMain.getModules().map((module) => {
                      return module.output(this.props)
                    })}
                  </Switch>
                </Content>
                {/* <GlobalFooter
                  className={'footer'}
                  copyright={config.copyright}
                /> */}
              </div>
            </Layout>
          </Layout>
        </div>
      </div>
    )
  }
}