import moment from 'moment'

import { formatTableDataForAPI } from '@/features/TreatmentTable/helpers/treatmentTableHelpers'
import { buildPostConfig } from '@/utils/axios/helpers'

export const createPatientDetailsConfig = (values, tables, guid, entity) => {
  const formattedData = formatTableDataForAPI(tables)
  const formattedNextAppointmentDate = moment(values.dateOfNextAppointment).format('YYYY-MM-DD')
  const formattedNextAppointmentTime = moment(values.timeOfNextAppointment).format('HH:mm:ss')
  const dateTimeOfNextAppointment = moment(formattedNextAppointmentDate + ' ' + formattedNextAppointmentTime).unix()
  return buildPostConfig(`patient/${guid}/patientDetails`, 'put', {
    histologicType: values.histologicType,
    complications: values.complications,
    weight: 0,
    height: 0,
    therapyType: '',
    reconstructionType: '',
    email: values.emailAddress,
    locationOfNextClinicalAppointment: values.locationOfNextAppointment,
    dateTimeOfNextClinicalAppointment: dateTimeOfNextAppointment,
    permissionToText: values.permissionToText === 'no' ? false : true,
    preferedMethodOfContacts: values.preferredContactMethod,
    reflectionsOnMyJourney: entity.reflectionsOnMyJourney,
    thingsIWantToAskMyDoctor: entity.thingsIWantToAskMyDoctor,
    thingsImThankfulFor: entity.thingsImThankfulFor,
    onboardingCompleted: entity.onboardingCompleted,
    ...formattedData,
  })
}