import React from 'react'
import { Icon } from 'antd'
import moment from 'moment'
import durationHOC from 'moment-duration-format'

durationHOC(moment)

export default [
  {
    title: 'Response',
    fieldKey: 'response',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (record, mHealthMain) => <div style={{ width: 275 }}>{record.response}</div>
  },
  {
    title: 'Answer Check',
    fieldKey: 'isCorrect',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (record, mHealthMain) => {
      if (record.isCorrect === true) {
        return <div className='patient-response-answer-check-container'>
          <Icon
            type='check-circle'
            className='patient-response-answer-check-correct-icon'
            style={{ color: "#1BBB9C" }}
          />
          <div className='patient-response-answer-check-correct-text'>
            Correct
          </div>
        </div>
      } else if (record.isCorrect === false) {
        return <div className='patient-response-answer-check-container'>
          <Icon
            type='close-circle'
            className='patient-response-answer-check-incorrect-icon'
            style={{ color: "#FF0000" }}
          />
          <div className='patient-response-answer-check-incorrect-text'>
            Incorrect
          </div>
        </div>
      } else return null
    }
  },
  {
    title: 'Elapsed Time',
    fieldKey: 'valueStartTime',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (record, mHealthMain) => {
      if (record.valueStartTime && record.valueEndTime) {
        const diff = moment(record.valueEndTime).diff(moment(record.valueStartTime))
        return moment.duration(diff).format("HH:mm:ss:SS", { trim: false })
      }
    }
  },
  {
    title: 'Date',
    fieldKey: 'date',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (record, mHealthMain) => {
      if (record.date && record.date !== '') {
        return <div>{moment.utc(moment.unix(record.date)).local().format("MM/DD/YYYY hh:mm:ss A zz")}</div>
      }
    }
  },
]