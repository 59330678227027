import React, { PureComponent } from 'react'

class PatientGraphTick extends PureComponent {
  render () {
    return (
      <g>
        <line 
          x1={this.props.x1 - 7} 
          x2={this.props.x2 + 4} 
          y1={this.props.y1} 
          y2={this.props.y2} 
          style={this.props.style}
        />
      </g>
    )
  }
} 

export default PatientGraphTick