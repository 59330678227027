import { camelizeKeys } from 'humps'
import jwtDecode from 'jwt-decode'
import keyBy from 'lodash/keyBy'
import find from 'lodash/find'

export const baseDataConfig = (props) => ([
  {
    request: () => props.mHealthMain.api.getDashboardUsers(
      props.mHealthMain.models.dashboardUsers,
      null,
      null,
      props.mHealthMain.getStoreState().UI.dashboardUserTable.pagination
    ),
    reduxName: 'dashboardUsers',
    requestConstant: 'GET_DASHBOARD_USERS',
    preReduxTransform: (res) => {
      if (res.data.dashboardUsers && res.data.dashboardUsers.items) {
        return res.data.dashboardUsers.items.map(p => {
          return {
            ...p,
          }
        })
      } else {
        throw 'error'
      }
    },
    postReduxFxns: [
      async res => await props.mHealthMain.dispatch.setTablePageInfo(
        'dashboardUser',
        res.data.dashboardUsers.pageInfo,
        res.data.dashboardUsers.totalCount
      )
    ],
    defaultData: [],
    errorMessage: "Failed to load dashboard users data"
  },
  {
    request: () => {
      const username = window.localStorage.getItem('username')

      return props.mHealthMain.api.getUser(username)
    },
    reduxName: 'myProfile',
    requestConstant: 'GET_MY_PROFILE',
    preReduxTransform: (res) => {
      let obj = {}
      const token = window.localStorage.getItem('session_token')
      if (token) {
        const readableToken = jwtDecode(token)
        if (readableToken['cognito:groups'] && readableToken['cognito:groups'].length) {
          obj.role = readableToken['cognito:groups'][0]
        }
      }
      if (res.data.userAttributes && res.data.userAttributes.length > 0) {
        res.data.userAttributes.forEach(a => {
          obj[a.name] = a.value
        })
      }
      
      // Check if emailVerified is present, if not (new account), add default key-value
      if (!obj.email_verified) {
        obj.emailVerified = false
      }

      if (res.data.physicianUsername) {
        obj.physicianUsername = res.data.physicianUsername
      }

      return camelizeKeys(obj)
    },
    defaultData: {},
    errorMessage: "Failed to load my profile data"
  },
  {
    request: () => props.mHealthMain.api.getStatusCounts(),
    reduxName: 'statusCounts',
    requestConstant: 'GET_STATUS_COOUNTS',
    defaultData: {},
    errorMessage: "Failed to load patient status data"
  },
  // {
  //   request: () => props.mHealthMain.api.getCohorts(),
  //   reduxName: 'cohorts',
  //   requestConstant: 'GET_COHORTS',
  //   defaultData: [],
  //   errorMessage: "Failed to load cohorts data"
  // },
  {
    request: () => props.mHealthMain.api.getSurveys(),
    reduxName: 'surveys',
    requestConstant: 'GET_SURVEYS',
    defaultData: [],
    errorMessage: "Failed to load survey data"
  },
  {
    request: () => props.mHealthMain.api.getPhysicians(),
    reduxName: 'physicians',
    requestConstant: 'GET_PHYSICIANS',
    preReduxTransform: (res) => {
      return res.data.map(p => {
        return {
          ...p
        }
      })
    },
    defaultData: [],
    errorMessage: "Failed to load physicians data"
  },
  {
    request: () => props.mHealthMain.api.getPatients(
      props.mHealthMain.models.patients,
      null,
      null,
      props.mHealthMain.getStoreState().UI.patientTable.pagination
    ),
    reduxName: 'patients',
    requestConstant: 'GET_PATIENTS',
    preReduxTransform: (res) => {
      if (res.data.patients && res.data.patients.items) {
        return res.data.patients.items.map(p => {
          let attributes = p.attributes
          if (attributes && attributes.length) {
            attributes = JSON.parse(p.attributes[0])
          }
          return {
            ...p,
            nextPROMDate: attributes.nextPROMDate || '',
            surgeryType: attributes.surgeryType || '',
            surgeryDate: attributes.surgeryDate || '',
            phone: p.phone.substr(2), // remove +1
          }
        })
      } else { 
        throw 'error'
      }
    },
    postReduxFxns: [
      async res => await props.mHealthMain.dispatch.setTablePageInfo(
        'patient',
        res.data.patients.pageInfo,
        res.data.patients.totalCount
      )
    ],
    defaultData: [],
    errorMessage: "Failed to load patients data"
  },
  {
    request: () => props.mHealthMain.api.getApplication(),
    reduxName: 'application',
    requestConstant: 'GET_APPLICATION',
    defaultData: {},
    errorMessage: 'Failed to load application info'
  },
  {
    request: () => props.mHealthMain.api.getRoles(),
    reduxName: 'roles',
    requestConstant: 'GET_ROLES',
    defaultData: {},
    errorMessage: 'Failed to load roles info',
    preReduxTransform: (res) => {
      return keyBy(res.data.map((role) => {
        return {
          ...role,
          permissions: role.permissions.map((p) => ({ isDefault: p.isDefault, ...p.permission }))
        }
      }), 'name')
    },
  },
  {
    request: () => { 
      const username = window.localStorage.getItem('username')
      return props.mHealthMain.api.getUserPermissions(username)
    },
    reduxName: 'myProfile',
    requestConstant: 'SET_MY_PROFILE',
    defaultData: {},
    errorMessage: 'Failed to load user permission info',
    preReduxTransform: (res) => {
      const { myProfile, UI: { application } } = window.MHealthMain.getStoreState()
      const userApplication = find(res.data, app => app.applicationId === application.id)
      if (userApplication) {
        return {
          ...myProfile,
          role: {
            ...userApplication.application.role,
            permissions: userApplication.application.role.permissions.map((role) => role.permission)
          } 
        }
      }
      
    },
  },
])