import React, { PureComponent } from 'react'
import moment from 'moment'
import last from 'lodash/last'
import has from 'lodash/has'
import { Icon } from 'antd'
import { connect } from 'react-redux'

import TwoDashIcon from '@/shared/components/TwoDashIcon'
import './PatientSurveyCategoryButton.scss'

class PatientSurveyCategoryButton extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      dateCompleted: this.getDateCompleted(),
    }
  }

  handleTableRowClick = () => {
    const { domain, mhealthDispatch, isActive } = this.props
    const { dateCompleted } = this.state
    if (isActive) { 
      mhealthDispatch.setOverviewTableData(null)
    }
    else if (!domain.graph) {
      mhealthDispatch.setOverviewTableAnswerData(dateCompleted, domain.data[0].questionnaireId, domain.data[0].questionnaireResponseId, domain)
    }
    else { 
      mhealthDispatch.setOverviewTableData(domain)
    }
  }

  formatTimeCompleted = (momentTime) => {
    const difference = moment().startOf('day').diff(moment(momentTime).startOf('day'), 'day')
    if (difference === 0) return 'Today'
    if (difference > 30) return 'Over a month ago'
    return `${difference} day(s) ago`
  } 


  getDateCompleted = () => {
    const { domain: { data } } = this.props
    if (!data.length) return null
    return last(data).date
  }

  renderIcon = () => {
    const { domain } = this.props
    if (!domain.data.length || domain.data.length === 1) {
      return (
        <Icon 
          className='patient-survey-category__table__data__name__icon--stop' 
          type='stop' 
        />
      )
    }
    const scoreDifference = last(domain.data).value - domain.data[domain.data.length - 2].value
    if (scoreDifference === 0) {
      return (
        <TwoDashIcon className='patient-survey-category__table__data__name__icon--equal' />
      )
    }
    if (scoreDifference > 0) {
      return (
        <Icon  
          className='patient-survey-category__table__data__name__icon--up' 
          type='arrow-up' 
        />
      )
    } else if (scoreDifference < 0) {
      return (
        <Icon  
          className='patient-survey-category__table__data__name__icon--down' 
          type='arrow-down' 
        />
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const dateCompleted = this.getDateCompleted()
    if (!moment(dateCompleted).isSame(prevState.dateCompleted)) {
      this.setState({ dateCompleted })
    }
  }

  render () {
    const { domain, isActive } = this.props
    const { dateCompleted } = this.state
    const differenceInTime = domain.data && domain.data.length ? moment().diff(last(domain.data).date, 'days') : null
    return (
      <tr 
        onClick={this.handleTableRowClick} 
        className={`
        patient-survey-category__table__data 
        patient-survey-category__table__data--active
        ${differenceInTime && differenceInTime > 35  ? 'patient-survey-category__table__data--disabled' : ''}
        ${isActive ? 'patient-survey-category__table__data--selected' : '' } 
        `}
      >
        <td className='patient-survey-category__table__data__name'>
          {this.renderIcon()}
          {domain.name}
        </td>
        <td className='patient-survey-category__table__data__time'>{this.formatTimeCompleted(dateCompleted)}</td>
      </tr>
    )
  }
}

const mapStateToProps = ({ overviewReducer }, ownProps) => {
  const { activeDomain } = overviewReducer
  if (has(ownProps, ['domain', 'name']) && has(activeDomain, 'name')) {
    const isActive = ownProps.domain.name === activeDomain.name
    return { isActive }
  } else return { isActive: false }
}

export default connect(mapStateToProps)(PatientSurveyCategoryButton)