import logoImage from '../assets/adk-mhealth-logo.png'
import { addEntityDropdown } from '../app/header'

// Network Configuration
const BASENAME = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : null
const AXIOS_BASE_URL = process.env.AXIOS_BASE_URL ? process.env.AXIOS_BASE_URL : '/api'
const GRAPHQL_BASE_URL = process.env.GRAPHQL_BASE_URL ? process.env.GRAPHQL_BASE_URL : '/graphql'
const STORAGE_DRIVER = window.localStorage
const HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  'content-type': 'application/json',
  'cache-control': 'no-cache'
}

// Module Configuration
const DEFAULT_ROUTE = '/patients' // the app will redirect here after login
const LOGIN_ROUTE = '/auth/login'
const DEFAULT_MODULE = 'patientmodule'
const DEFAULT_TRIAL_SITE = [1, 1]
const LOGIN_TITLE = null
const LOGO = logoImage

const MODELS = {
  patients: [
    'firstName',
    'lastName',
    'dateOfBirth',
    'guid',
    'phone',
    'status',
    'mrn',
    'attributes',
    // 'cohorts',
    'profilePhotoUrl',
    'status',
    'physicianIds'
  ],
  dashboardUsers: [
    'firstName',
    'lastName',
    'guid',
    'email',
    'role',
    'profilePhotoUrl',
    'parentPhysicianId',
    'status',
    'attributes',
  ],
  patientResponses: [
    'id',
    'question',
    'items',
    'response',
    'responseUrl',
    'survey',
    'date',
    'type',
    'isCorrect',
    'attemptCount',
    'questionnaireResponseItemId',
  ],
  attempts: [
    'attemptGuid',
    'response',
    'isCorrect',
    'valueStartTime',
    'valueEndTime',
    'date',
  ]
}

const ENTITY_STATUS = {
  CONFIRMED: 'Active',
  UNCONFIRMED: 'Pending',
  DISABLE: 'Deactivated',
}

const ACCEPTED_STATUS = [
  'Active',
  'active',
  'Pending',
  'pending',
  'Deactivated',
  'deactived',
]

export {
  BASENAME,
  AXIOS_BASE_URL,
  GRAPHQL_BASE_URL,
  STORAGE_DRIVER,
  HEADERS,
  DEFAULT_ROUTE,
  LOGIN_ROUTE,
  DEFAULT_MODULE,
  LOGIN_TITLE,
  LOGO,
  MODELS,
  ENTITY_STATUS,
  ACCEPTED_STATUS,
  DEFAULT_TRIAL_SITE,
}