import { dataActionTypes } from '../../actionTypes'

const studies = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['studies']:
      return action.studies
    default:
      return state
  }
}

const roles = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['roles']:
      return action.roles
    default:
      return state
  }
}

const cohorts = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['cohorts']:
      return action.cohorts
    default:
      return state
  }
}

const surveys = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['surveys']:
      return action.surveys
    default:
      return state
  }
}

const myProfile = (state = {}, action) => {
  switch (action.type) {
    case dataActionTypes['myProfile']:
      return action.myProfile
    default:
      return state
  }
}

const patients = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['patients']:
      return action.patients
    default:
      return state
  }
}

const dashboardUsers = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['dashboardUsers']:
      return action.dashboardUsers
    default:
      return state
  }
}

const physicians = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['physicians']:
      return action.physicians
    default:
      return state
  }
}

const statusCounts = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['statusCounts']:
      return action.statusCounts
    default:
      return state
  }
}

const patientResponses = (state = [], action) => {
  switch (action.type) {
    case dataActionTypes['patientResponses']:
      return action.patientResponses
    default:
      return state
  }
}

const entityProfile = (state = {}, action) => {
  switch (action.type) {
    case dataActionTypes['entityProfile']:
      return action.entityProfile
    default:
      return state
  }
}

const entityLoginHistory = (
  state = { history: [], pagination: {} },
  action
) => {
  switch (action.type) {
    case dataActionTypes['entityLoginHistory']:
      return action.entityLoginHistory
    default:
      return state
  }
}

const application = (state = {}, action) => {
  switch (action.type) {
    case dataActionTypes['application']:
      return action.application
    default:
      return state
  }
}

export {
  dashboardUsers,
  myProfile,
  patients,
  roles,
  cohorts,
  surveys,
  studies,
  physicians,
  statusCounts,
  patientResponses,
  entityProfile,
  entityLoginHistory,
  application,
}
