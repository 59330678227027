'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AntTable = exports.EntityTable = undefined;

var _EntityTable = require('./EntityTable');

var _EntityTable2 = _interopRequireDefault(_EntityTable);

var _AntTable = require('./AntTable');

var _AntTable2 = _interopRequireDefault(_AntTable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.EntityTable = _EntityTable2.default;
exports.AntTable = _AntTable2.default;