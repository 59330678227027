import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'

import { uploadCSVFileContents, resetCSVData, uploadCSVFileToPatients } from '@/features/CSVImport/redux/csvActions'
import './CsvButton.scss'

const CsvButton = (
  { 
    readerRef, 
    uploadCSVFileToPatients,
    uploadedCsvFile,
    uploadingCsvFile,
    uploadCsvFileFailed,
    hasErrors,
    uploadingPatients,
    uploadedPatients
  }
) => {

  const handleOpenDialog = (e) => {
    if (readerRef.current) {
      readerRef.current.open(e)
    }
  }

  const handleImportPatients = () => {
    uploadCSVFileToPatients()
  }

  const getButtonName = () => {
    if (uploadedPatients) return 'Import Another'
    if (uploadedCsvFile) return 'Import Patients'
    else return 'Upload CSV File'
  }

  return (
    <Button
      size='large'
      icon={uploadedCsvFile ? 'team' : 'upload'}
      onClick={!uploadedCsvFile ? handleOpenDialog : handleImportPatients}
      disabled={uploadingCsvFile || uploadCsvFileFailed || hasErrors || uploadingPatients}
      loading={uploadingCsvFile || uploadingPatients}
      className={`
    o-csv__modal__upload__button
    ${uploadedCsvFile ? 'o-csv__modal__upload__button__import' : 'o-csv__modal__upload__button__upload'}
    ${hasErrors && "o-csv__modal__upload__button--disabled"}
    ${uploadedPatients && "o-csv__modal__upload__button--success"}
    `}
    >
      {getButtonName()}
    </Button>
  )

}

const mapStateToProps = ({ csvReducer }) => {
  const { 
    meta: { 
      uploadingCsvFile, 
      uploadCsvFileFailed, 
      uploadedCsvFile,
      uploadedPatients,
      uploadingPatients
    } 
  } = csvReducer
  return { 
    uploadingCsvFile, 
    uploadCsvFileFailed, 
    uploadedCsvFile, 
    uploadedPatients, 
    uploadingPatients 
  }
}

const mapDispatchToProps = { uploadCSVFileContents, resetCSVData, uploadCSVFileToPatients }


export default connect(mapStateToProps, mapDispatchToProps)(CsvButton)