'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getLoginHistory;
function getLoginHistory(route, username, pageSize) {
  var pageNumber = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

  return ['GET', true, route, null, null, '/' + username + '?pageSize=' + pageSize + (pageNumber ? '&pageNumber=' + pageNumber : '')];
}