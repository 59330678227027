import React, { PureComponent } from 'react'
import { TimePicker } from 'antd'

import './FormTime.scss'

class FormCheckbox extends PureComponent {

  handleOnChange = (e) => {
    const { formRef } = this.props
    formRef.props.form.setFieldsValue({
      [this.props.id]: e
    })
  }

  render () {
    const { placeholder, value, disabled } = this.props
    return (
      <TimePicker 
        disabled={disabled}
        value={value || undefined}
        onChange={this.handleOnChange}
        placeholder={placeholder}
        popupClassName='form-time-pop-up'
      />
    )
  }
}

export default FormCheckbox