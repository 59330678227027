import React from 'react'

import './LoadingIcon.scss'

const LoadingIcon = () => {
  return (
    <div id='circleG'>
      <div id='circleG_1' className='circleG'>
      </div>
      <div id='circleG_2' className='circleG'>
      </div>
      <div id='circleG_3' className='circleG'>
      </div>
    </div>
  )
}

export default LoadingIcon