'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

var _isEqual = require('lodash/isEqual');

var _isEqual2 = _interopRequireDefault(_isEqual);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AntCheckBox = function (_React$Component) {
  (0, _inherits3.default)(AntCheckBox, _React$Component);

  function AntCheckBox() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, AntCheckBox);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = AntCheckBox.__proto__ || (0, _getPrototypeOf2.default)(AntCheckBox)).call.apply(_ref, [this].concat(args))), _this), _this.state = {}, _this.renderOptions = function (options) {
      if (!options.length) return null;
      return options.map(function (o, index) {
        return _react2.default.createElement(
          _antd.Checkbox,
          {
            key: 'checkbox-option-' + index,
            value: o.value
          },
          o.label
        );
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(AntCheckBox, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          style = _props.style,
          className = _props.className,
          options = _props.options,
          onChange = _props.onChange,
          disabled = _props.disabled,
          value = _props.value;

      return _react2.default.createElement(
        _antd.Checkbox.Group,
        {
          defaultValue: value ? [(0, _stringify2.default)(value)] : undefined,
          className: className,
          onChange: onChange,
          disabled: disabled,
          style: style
        },
        this.renderOptions(options)
      );
    }
  }]);
  return AntCheckBox;
}(_react2.default.Component);

exports.default = AntCheckBox;