import React from 'react'
import { Icon } from 'antd'
import { ENTITY_STATUS } from '../../../../app'
import { improveAxios, buildPostConfig } from '../../../../../utils/axios/helpers'
import find from 'lodash/find'
import moment from 'moment'
import { Notification } from '@adkgroup/appkit-ui'

//dateOfBirth

export default [
  {
    type: 'text',
    value: 'First Name',
    fieldKey: 'firstName',
    fieldLocation: '',
    width: 150,
    filtered: true,
    sorter: true,
    fixed: 'left',
  },
  {
    type: 'text',
    value: 'Last Name',
    fieldKey: 'lastName',
    fieldLocation: '',
    width: 150,
    filtered: true,
    sorter: true,
    fixed: 'left',
  },
  {
    type: 'text',
    value: 'MRN #',
    fieldKey: 'mrn',
    fieldLocation: '',
    filtered: true,
    sorter: true,
    render: (value, mHealthMain) => {
      if (value.mrn && value.mrn !== '') {
        return <div>{value.mrn.slice(0, 3) + "-" + value.mrn.slice(3)}</div>
      }
    }
  },
  {
    type: 'text',
    value: 'Patient ID',
    fieldKey: 'guid',
    fieldLocation: '',
    width: 160,
    filtered: true,
    sorter: true,
  },
  {
    type: 'text',
    value: 'DOB',
    fieldKey: 'dateOfBirth',
    fieldLocation: '',
    filtered: true,
    filterType: 'date',
    filterTransform: (date) => date ? date.format("MM/DD/YYYY") : undefined,
    filterKeys: ['dateOfBirth'],
    sorter: true,
    render: (record, mHealthMain) => {
      if (record.dateOfBirth && record.dateOfBirth !== '') {
        return <div>{moment(record.dateOfBirth).format("YYYY-MM-DD")}</div>
      }
    }
  },
  // {
  //   type: 'text',
  //   value: 'Sex',
  //   fieldKey: 'sex',
  //   width: 150,
  //   fieldLocation: '',
  //   filtered: true,
  //   sorter: true,
  // },
  {
    type: 'text',
    value: 'Survey',
    fieldKey: 'surveys',
    fieldLocation: '',
    filtered: true,
    filterType: 'select',
    filterOptions: (props) => props.surveys.map(c => ({
      label: c.name,
      value: c.id,
    })),
    filterKeys: ['surveys'],
    render: (record, mHealthMain) => {
      if (record.surveys && record.surveys.length) {
        // Print out full name and remove last entity's trailing comma
        return record.surveys.map((surveyId, index) => {
          const survey = find(mHealthMain.getStoreState().surveys, survey => survey.id === surveyId) || undefined
          if (survey) {
            if (index !== record.surveys.length - 1) {
              return <div>{`${survey.name}, `}</div>
            } else {
              return <div>{`${survey.name}`}</div>
            }
          }          
        })
      }
    }
  },
  {
    type: 'text',
    value: 'Physician',
    fieldKey: 'physicianIds',
    fieldLocation: '',
    filtered: true,
    filterType: 'select',
    filterOptions: (props) => props.physicians.map(c => ({
      label: c.firstName + ' ' + c.lastName,
      value: c.id,
    })),
    filterKeys: ['physicianIds'],
    render: (record, mHealthMain) => {
      if (record.physicianIds && record.physicianIds.length) {
        // Print out full name and remove last entity's trailing comma
        return record.physicianIds.map((p, index) => {
          const physician = find(mHealthMain.getStoreState().physicians, phys => phys.id === p)
          if (physician) {
            if (index !== record.physicianIds.length - 1) {
              return <div>{`${physician.firstName} ${physician.lastName},`}</div>
            } else {
              return <div>{`${physician.firstName} ${physician.lastName}`}</div>
            }
          }
        })
      }
    }
  },
  // {
  //   type: 'text',
  //   value: 'Physician',
  //   fieldKey: 'physicianIds',
  //   fieldLocation: '',
  //   filtered: true,
  //   render: (record, mHealthMain) => {
  //     if (record.physicianIds && record.physicianIds.length) {
  //       // Print out full name and remove last entity's trailing comma
  //       return record.physicianIds.map((p, index) => {
  //         const physician = find(mHealthMain.getStoreState().physicians, phys => phys.id === p)
  //         if (physician) {
  //           if (index !== record.physicianIds.length - 1) {
  //             return <div>{`${physician.firstName} ${physician.lastName},`}</div>
  //           } else {
  //             return <div>{`${physician.firstName} ${physician.lastName}`}</div>
  //           }
  //         }
  //       })
  //     }
  //   }
  // },
  {
    type: 'text',
    value: 'Status',
    fieldKey: 'status',
    fieldLocation: '',
    filtered: true,
    filterTransform: str => {
      if (str === '' || !str) {
        return ''
      } else {
        const lowerCaseStr = str.toLowerCase()
        if ("active".includes(lowerCaseStr)) {
          return "CONFIRMED"
        } else if ("pending".includes(lowerCaseStr)) {
          return "UNCONFIRMED"
        } else if ("deactivated".includes(lowerCaseStr)) {
          return "DISABLE"
        } else {
          return str
        }
      }
    },
    sorter: true,
    immutable: true,
    render: (record, mHealthMain) => {
      // Render resend sms icon if patient is pending
      let showResendSMSIcon = record.status && record.status === 'UNCONFIRMED'
        ? true
        : false
      return <div className='status-cell'>
        <div className={`status-indicator ${record.status}`} /> { record.status && ENTITY_STATUS[record.status]}
        { showResendSMSIcon
          ? <Icon
            className='resend-pending-sms'
            type='message'
            onClick={async (event) => {
              event.stopPropagation()
              try {
                const myGuid = mHealthMain.getStoreState().myProfile.guid
                const patientGuid = record.guid
                const resendSMSRequestConfig = buildPostConfig(
                  `user/${myGuid}/patient/resendInvite`,
                  'post',
                  { patientIdentifier: patientGuid }
                )
                await improveAxios(resendSMSRequestConfig)
                Notification({ message: 'Successfully resend patient invite' })
              } catch (error) {
                Notification({
                  message: 'Failed to resend patient invite',
                  description: 'Please try again later'
                })
              }
            }}
          />
          : null }
      </div>
    }
  },
  {
    type: 'text',
    value: 'Surgery Type',
    fieldKey: 'surgeryType',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (value, mHealthMain) => {
      if (!value) return
      return (
        <div>{value.surgeryType}</div>
      )
    }
  },
  {
    type: 'text',
    value: 'Surgery Date',
    fieldKey: 'surgeryDate',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (value, mHealthMain) => {
      if (!value || !value.surgeryDate) return null
      return (
        <div>{moment(value.surgeryDate).format('MM/DD/YYYY')}</div>
      )
    }
  },
  {
    type: 'text',
    value: 'Next PROM',
    fieldKey: 'nextPROMDate',
    fieldLocation: '',
    filtered: false,
    sorter: false,
    render: (value, mHealthMain) => {
      if (!value || !value.nextPROMDate) return
      return (
        <div>{moment(value.nextPROMDate).format('MM/DD/YYYY')}</div>
      )
    }
  },
]