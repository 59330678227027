'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authenticate = authenticate;
exports.forgotPassword = forgotPassword;
exports.confirmPassword = confirmPassword;
exports.verifyEmail = verifyEmail;
exports.resendVerification = resendVerification;

var _endpoints = require('../../endpoints');

function authenticate(creds) {
  return ['POST', false, _endpoints.AUTH_ROUTE, creds];
}

function forgotPassword(username) {
  return ['PATCH', false, _endpoints.USER_ROUTE, null, null, '/' + username + '/forgotpassword'];
}

function confirmPassword(username, payload) {
  return ['PATCH', false, _endpoints.USER_ROUTE, payload, null, '/' + username + '/confirmpassword'];
}

function verifyEmail(username, payload) {
  var accessToken = window.localStorage.getItem('access_token');
  return ['PATCH', true, _endpoints.USER_ROUTE, payload, null, '/' + username + '/verifyemail', { accessToken: accessToken }];
}

function resendVerification() {
  var username = window.localStorage.getItem('username');
  var accessToken = window.localStorage.getItem('access_token');
  return ['POST', true, _endpoints.USER_ROUTE, null, null, '/' + username + '/verify', { accessToken: accessToken }];
}