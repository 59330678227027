import React, { PureComponent } from 'react'
import moment from 'moment'
import last from 'lodash/last'
import { VictoryAxis, VictoryChart, VictoryTooltip, VictoryScatter, VictoryArea, createContainer } from 'victory'

import PatientGraphAxis from './PatientGraphAxis'
import PatientGraphTick from './PatientGraphTick'
import './PatientGraph.scss'

const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi')

class PatientGraph extends PureComponent {
  constructor() {
    super()
    this.state = {}
  }

  handlePointClick = (selectedPoint) => {
    const { mhealthDispatch } = this.props
    if (!selectedPoint.activePoints.length) return null
    const { questionnaireId, questionnaireResponseId } = selectedPoint.activePoints[0]
    mhealthDispatch.setOverviewTableAnswerData(null, questionnaireId, questionnaireResponseId)
  }

  handleZoom = (domain) => {
    this.setState({ selectedDomain: domain })
  }

  handleBrush(domain) {
    this.setState({ zoomDomain: domain })
  }

  getTickValues = () => {
    if (!this.state.selectedDomain) {
      return [this.props.graphData[0].date, last(this.props.graphData).date]
    }
    else return this.state.selectedDomain.x
  }

  renderRightAxisTick = (tickValue) => {
    // Use numbers to align the right axis to the left axis, and then use the tickformat
    // to return the proper wording based on the value
    switch (tickValue) { // 16, 45, 63, 95
      case 16:
        return 'Poor'
      case 45:
        return 'Fair'
      case 63:
        return 'Good'
      case 95:
        return 'Excellent'
      default: return null
    }
  }

  render () {
    const strokeLinejoin = "round";
    const { graphData } = this.props
    if (!graphData.length) return null
    if (graphData.length < 2) { return (
      <div className='patient-graph-score'>
        <p className='patient-graph-score--header'>Patient's score:</p>
        <p className='patient-graph-score--value'>{Math.round(graphData[0].value)}</p>
      </div>
    )}
    return (
      <VictoryChart
        height={450}
        width={480}
        padding={{ left: 30, bottom: 30, top: 30, right: 65 }}
        domainPadding={{ y: [10, 10], x: 4 }}
        containerComponent={
          <VictoryZoomVoronoiContainer 
            responsive={false}
            zoomDimension='x'
            zoomDomain={this.state.zoomDomain}
            onZoomDomainChange={this.handleZoom}
            radius={25}
            // voronoiBlacklist={['line']}
          />
        }
        events={[{
          target: 'parent',
          eventHandlers: {
            onClick: async (evt, dotProps) => this.handlePointClick(dotProps)
          }
        }]}
      >
        <VictoryScatter 
          name='scatter'
          size={({ active }) => active ? 6 : 4}
          style={{
            data: { stroke: '#09A1B8', fill: '#09A1B8' }
          }}
          data={graphData}
          x={(d) => d.x}
          y='value'
          labelComponent={<VictoryTooltip flyoutStyle={{ fill: 'white' }} style={{ fill: '#121B4D' }}/>}
          labels={({ datum }) => moment(datum.date).format('LL') }
        />
        <defs>
          <linearGradient style={{ touchAction: 'none' }} id='gradientStroke'
            x1='10%'
            x2='15%'
            y1='0%'
            y2='100%'
          >
            <stop offset='0%' stopColor='#09A1B8' stopOpacity='0.2' />
            <stop offset='100%' stopColor='#09A1B8' stopOpacity='0.05' />
          </linearGradient>
        </defs>
        <VictoryArea
          name='line'
          data={graphData}
          x={(d) => d.x}
          y='value'
          style={{
            data: {
              stroke: '#09A1B8',
              strokeWidth: 2,
              fill: 'url(#gradientStroke)'
            }
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: '#E5E4E5' },
          }}
          domain={[0, 100]}
          tickValues={[0, 20, 40, 60, 80, 100]}
        /> 
        <VictoryAxis 
          style={{
            grid: {
              stroke: 'none'
            },
            axis: { stroke: '#E5E4E5' },
          }}
          tickFormat={(tick) => {
            if (`${tick}`.includes('.5')) return null
            else return Math.floor(tick )
          }}
        />
        <VictoryAxis 
          dependentAxis 
          orientation='right' 
          style={{
            tickLabels: {
              fill: 'none'
            },
            axis: {
              stroke: 'none',
            },
            ticks: {
              fill: "transparent",
              size: 5,
              stroke: '#E5E4E5',
              strokeWidth: 1,
              strokeLinecap: 'butt',
              strokeLinejoin,
            }
          }}
          domain={[0, 100]}
          tickValues={[16, 45, 63, 95]}
          tickComponent={<PatientGraphTick />}
        />
        <VictoryAxis 
          dependentAxis 
          orientation='right' 
          style={{
            axis: {
              stroke: 'none',
            },
            tickLabels: {
              fontSize: 14,
              lineHeight: 21,
              fontFamily: "Source Sans Pro",
              fill: '#121B4D'
            }
          }}
          domain={[0, 100]}
          padding={{ right: 50 }}
          tickValues={[16, 45, 63, 95]} // 15, 35, 50, 77.5
          tickFormat={this.renderRightAxisTick}
        />
        <VictoryAxis 
          dependentAxis
          orientation='right'
          domain={[0, 100]}
          tickValues={[0]}
          tickFormat={() => null}
          style={{
            axis: {
              stroke: '#E5E4E5'
            }
          }}
          axisComponent={<PatientGraphAxis />}
        />
      </VictoryChart>
    )
  }
}

export default PatientGraph