import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { VictoryPolarAxis, VictoryChart, VictoryArea, VictoryScatter } from 'victory'
import get from 'lodash/get'
import has from 'lodash/has'

import styles from '../../../../../constants/completeHealthStyles'
import { getCompleteHealthGraphData, resetStoredData } from '../../../../../helpers/graphHelpers'
import './CompleteHealthGraph.scss'

const gridData = [
  {
    x: 'Psychosocial\nWell-Being',
    y: 100
  },
  {
    x: 'Satisfaction with Breast',
    y: 100
  },
  {
    x: 'Sexual\nWell-Being',
    y: 100
  },
  {
    x: 'Physical Well-Being',
    y: 100
  }
] 
// https://github.com/FormidableLabs/victory/issues/1353 :(
class CompleteHealthGraph extends PureComponent {
  componentWillUnmount() {
    resetStoredData()
  }

  getScoreDate = (type) => {
    const { organizedSurveyData } = this.props
    if (!organizedSurveyData) return
    const scoresDate = get(organizedSurveyData, ['dates', type], null) 
    if (!scoresDate) return "No Survey Data Available"
    return moment(scoresDate).format('LL')
  }

  render () {
    const { organizedSurveyData } = this.props
    // Victory will not render a chart if it is contained in a fragment.
    return (
      <div>
        <VictoryChart
          polar
          width={450}
          height={345}
        >
          <VictoryScatter style={styles.gridOutlineScatter} data={gridData} />
          <VictoryArea style={styles.gridOutlineArea} data={gridData} />
          { has(organizedSurveyData, ['scores', 'preOp']) ? 
            <VictoryArea style={styles.areaStyles.preOpStyles} data={organizedSurveyData.scores.preOp} /> 
            : null }
          { has(organizedSurveyData, ['scores', 'preOp']) ? 
            <VictoryScatter style={styles.scatterStyles.preOpStyles} data={organizedSurveyData.scores.preOp} /> 
            : null }

          { has(organizedSurveyData, ['scores', 'secondToLastScore']) ? 
            <VictoryArea style={styles.areaStyles.previousStyles} data={organizedSurveyData.scores.secondToLastScore} /> 
            : null }
          { has(organizedSurveyData, ['scores', 'secondToLastScore']) ? 
            <VictoryScatter style={styles.scatterStyles.previousStyles} data={organizedSurveyData.scores.secondToLastScore} /> 
            : null }

          { has(organizedSurveyData, ['scores', 'lastScore']) ? 
            <VictoryArea style={styles.areaStyles.lastReportedStyles} data={organizedSurveyData.scores.lastScore} />
            : null }
          { has(organizedSurveyData, ['scores', 'lastScore']) ? 
            <VictoryScatter style={styles.scatterStyles.lastReportedStyles} data={organizedSurveyData.scores.lastScore} /> 
            : null }   
          <VictoryPolarAxis 
            dependentAxis
            domain={[0, 100]}
            style={{
              tickLabels: { display: 'none' },
              ticks: { display: 'none' },
              axisLabel: { display: 'none' },
              grid: { display: 'none' },
              axis: { display: 'none' }
            }}
          />
          <VictoryPolarAxis 
            labelPlacement='vertical'
            tickValues={['Psychosocial\nWell-Being', 'Satisfaction with Breast', 'Sexual\nWell-Being', 'Physical Well-Being']}
            tickCount={4}
            style={styles.polarAxis}
          />
        </VictoryChart>
        <div className='complete-health-content__legend'>
          <div className='complete-health-content__legend__container'>
            <div className='complete-health-content__legend__container__row'>
              <div className='complete-health-content__legend__container__row__color complete-health-content__legend__container__row__color--preop'/>
              <span className='complete-health-content__legend__container__row__text'>
              Preoperative: {this.getScoreDate('preOp')}
              </span>
            </div>
          </div>
          <div className='complete-health-content__legend__container'>
            <div className='complete-health-content__legend__container__row'>
              <div className='complete-health-content__legend__container__row__color complete-health-content__legend__container__row__color--previous'/>
              <span className='complete-health-content__legend__container__row__text'>
              Previous: {this.getScoreDate('secondToLastScore')}
              </span>
            </div>
          </div>
          <div className='complete-health-content__legend__container'>
            <div className='complete-health-content__legend__container__row'>
              <div className='complete-health-content__legend__container__row__color complete-health-content__legend__container__row__color--last' />
              <span className='complete-health-content__legend__container__row__text'>
              Last Score: {this.getScoreDate('lastScore')}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({ entityProfile }) => {
  const { surveyCategories, activeSurgeryTable } = entityProfile
  const surgeryDate = get(activeSurgeryTable, [[0], 'activeSurgeryDate'], null)
  if (!surveyCategories || !surgeryDate ) return {}
  const organizedSurveyData = getCompleteHealthGraphData(surveyCategories, surgeryDate)
  return { surveyCategories, organizedSurveyData }
}

export default connect(mapStateToProps)(CompleteHealthGraph)