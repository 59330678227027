import { Notification } from '@adkgroup/appkit-ui'
import { patchDiff } from '@adkgroup/appkit-utils'
import { ENTITY_STATUS } from '../../../app'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { camelizeKeys } from 'humps'

import { createStudyTeamDetailsConfig } from './studyProfileHelpers'
import { improveAxios, buildPostConfig } from '@/utils/axios/helpers'

const IMPROVE_ROLES = ['Doctor', 'SuperAdmin', 'Admin']


export default (props) => ({
  title: "Care Team Profile",
  detailForm: {
    title: "Care Team Member Details",
    toggleStatus: async (props, entity) => {
      const { mHealthMain: { api, dispatch }, dashboardUsers } = props
      if (entity.status !== "PENDING") {
        dispatch.setModalContent({
          visible: true,
          title: "Care Team Member Status Change",
          content: "Clicking confirm will trigger an e-mail to the care team member updating them on their current account status.",
          onOk: async () => {
            try {
              const newStatus = entity.status === "DISABLE" ? "CONFIRMED" : "DISABLE"
              await api.patchUser({ status: newStatus }, `/user/${entity.guid}`)
              dispatch.setData('entityProfile', {
                ...cloneDeep(entity),
                status: newStatus,
              })
              
              const dashboardUserIndex = findIndex(dashboardUsers, du => du.guid === entity.guid)
              if (dashboardUserIndex > -1) {
                let dashboardUsersCopy = cloneDeep(dashboardUsers)
                dashboardUsersCopy[dashboardUserIndex] = {
                  ...entity,
                  status: newStatus,
                }
                dispatch.setData('dashboardUsers', dashboardUsersCopy)
              }
              dispatch.setModalVisibility(false)
            } catch (error) {
              Notification({
                message: `Failed to ${entity.status === "DISABLE" ? 'activate' : 'deactivate'} dashboard user`,
                description: 'Please try again later'
              })
              dispatch.setModalVisibility(false)
            }
          }
        })
      } else {
        Notification({
          message: 'Dashboard user is pending',
          description: 'Please try again later'
        })
      }
    },
    ENTITY_STATUS,
    patchForm: true,
    formConfig: ref => [
      { 
        // title: 'Section Title',
        // className: 'custom',
        fields: [
          {
            type: 'text',
            label: 'First Name',
            id: 'firstName',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your first name!' }],
          },
          {
            type: 'text',
            label: 'Last Name',
            id: 'lastName',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your last name!' }],
          },
          {
            type: 'text',
            label: 'Email',
            id: 'email',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your e-mail!' }],
            disabled: true,
          },
          {
            type: 'select',
            formType: 'select',
            label: 'Role',
            id: 'role',
            // Requires .add permission for each role, ex: { physician: physican.add }
            permissibleActions: Object.keys(props.roles).reduce((result, item) => {
              result[item] = `${item.toLowerCase()}.add`
              return result
            }, {}),
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please select a role!' }],
            dropdownClassName: 'edit-entity-select-dropdown edit-entity-select-dropdown__role',
            validateTrigger: 'onChange',
            options: Object.values(props.roles)
              .filter(r => r.name !== 'Patient' && r.name !== 'PhysicianDelegate')
              .map((r, index) => {
                return {
                  id: r.name,
                  name: IMPROVE_ROLES[index],
                }
              }),
          },
          {
            type: 'mask',
            label: 'Office Number',
            placeholder: '000-000-0000',
            mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            id: 'officeNumber',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [
              { required: true, message: 'Please input a phone number!' },
              { pattern: /^[0-9]\d{2}-\d{3}-\d{4}$/, message: 'Valid phone number is required' }
            ],
          },
          {
            type: 'select',
            formType: 'select',
            label: 'Physician',
            id: 'parentPhysicianId',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please select a physician!' }],
            dropdownClassName: 'edit-entity-select-dropdown edit-entity-select-dropdown__physician',
            dependent: 'role',
            dependentValue: ['PhysicianDelegate'], // Physician delegate role id
            validateTrigger: 'onChange',
            options: props.physicians.map(p => {
              return {
                id: p.id,
                name: p.firstName + ' ' + p.lastName,
              }
            })
          },
          {
            type: 'text',
            label: 'Profile URL',
            id: 'profileUrl',
            required: true,
            colon: false,
            validateStatus: true,
            dependent: 'role',
            dependentValue: ['Physician'],
            validateTrigger: 'onChange',
            rules: [
              { required: true, message: 'Please provide a profile url!' },
              { pattern: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/, message: 'Valid profie URL is required!' }
            ],
          },
          {
            type: 'select',
            label: 'Specialty',
            id: 'specialty',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please select a specialty!' }],
            dropdownClassName: 'create-entity-select-dropdown create-entity-select-dropdown__physician',
            dependent: 'role',
            dependentValue: ['Physician'],
            validateTrigger: 'onChange',
            options: props.sharedUIReducer.doctorSpecialties.map((specialty) => ({
              id: specialty,
              name: specialty
            }))
          },
          {
            type: 'checkbox',
            label: 'Permissions',
            id: 'permissions',
            dependent: 'role',
            dependentValue: ['PhysicianDelegate'], // Physician delegate role id
            options: [{ 
              label: 'Ability to Edit',
              value: JSON.stringify(props.roles.PhysicianDelegate.permissions.map(p => ({ id: p.id, name: p.name })))
            }],
            buttonType: 'primary',
            className: 'create-entity-permissions',
            disableCheck: true,
          },
          {
            type: 'upload-photo',
            label: 'Profile Photo',
            id: 'profilePhotoUrl',
            required: false,
            colon: false,
            buttonIcon: 'upload',
            buttonLabel: 'Upload',
            buttonClassName: 'edit-entity-upload-photo-button',
            modalTitle: 'Upload Profile Photo',
            modalOkText: 'Upload',
            onUpload: async (file) => {
              const { mHealthMain: { api, dispatch }, entityProfile, dashboardUsers } = props
              let s3Key
              try {
                // Assemble form data payload
                const payload = new FormData()
                payload.append('file', file)
                // Post image to S3 and receive S3 public url back
                const S3Response = await api.uploadProfilePhotoToS3(payload)
                const profilePhotoUrl = get(S3Response, ['data', 'valueUri'], null)
                if (profilePhotoUrl) {
                  // Set key for error handling cleanup
                  s3Key = profilePhotoUrl.replace(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`, '')
                  // Set form field value
                  ref.props.form.setFieldsValue({ ["profilePhotoUrl"]: profilePhotoUrl })
                  // Patch user and set state (dashboardUser and dashboardUsers)
                  await api.patchUser({ profilePhotoUrl }, `/user/${entityProfile.guid}`)
                  const dashboardUserIndex = findIndex(dashboardUsers, du => du.guid === entityProfile.guid)
                  const updatedDashboardUser = {
                    ...cloneDeep(entityProfile),
                    profilePhotoUrl,
                  }
                  dispatch.setData('entityProfile', updatedDashboardUser)
                  if (dashboardUserIndex > -1) {
                    let dashboardUsersCopy = cloneDeep(dashboardUsers)
                    dashboardUsersCopy[dashboardUserIndex] = updatedDashboardUser
                    dispatch.setData('dashboardUsers', dashboardUsersCopy)
                  }
                } else {
                  throw 'error'
                }
              } catch (error) {
                Notification({
                  message: "Profile photo upload failed",
                  description: "Please try again later"
                })
                await api.deleteProfilePhotoFromS3(s3Key)
              }
            }
          },
          {
            type: 'submit',
            label: 'Update',
            id: 'submit',
            buttonType: 'primary',
            className: 'edit-entity-button',
            disableCheck: true,
            style: { flexBasis: '100%' }
          }
        ],
      },
    ],
    onSubmit: async (values, entity) => {
      console.log('here')
      const patchKeys = patchDiff(entity, values)
      if (patchKeys.length > 0) {
        const {
          mHealthMain: { api, dispatch },
          UI: { application },
          entityProfile,
          dashboardUsers,
          myProfile,
          physicians
        } = props
        dispatch.setModalContent({
          className: 'edit-form-modal',
          title: "Care Team Member Profile Update",
          content: "Saving these changes will be reflected on the care team member profile within the app. This will trigger an e-mail notification to the care team member letting them know changes have been made to their profile. ",
          onOk: async () => {
            try {
              let payload = {}
              patchKeys.forEach(key => {
                if (key === 'firstName') {
                  payload['name'] = values[key]
                } else if (key === 'parentPhysicianId' && values.role === 'PhysicianDelegate') {
                  const physician = find(physicians, p => p.id === values.parentPhysicianId)
                  if (physician) {
                    payload['physicianUsername'] = physician.username
                  }
                } else if (key !== 'permissions') {
                  payload[key] = values[key]
                }
              })
              const config = createStudyTeamDetailsConfig(values, entity.guid)
              await api.patchUser(payload, `/user/${entity.guid}`, entity.guid === myProfile.sub)
              await improveAxios.request(config)
              const dashboardUserIndex = findIndex(dashboardUsers, p => p.guid === entity.guid)
              dispatch.setData('entityProfile', {
                ...cloneDeep(entityProfile),
                ...payload,
              })

              if (dashboardUserIndex > -1) {
                let dashboardUsersCopy = cloneDeep(dashboardUsers)
                dashboardUsersCopy[dashboardUserIndex] = {
                  ...entity,
                  ...payload
                }
                dispatch.setData('dashboardUsers', dashboardUsersCopy)
              }
            
              if (patchKeys.includes('permissions')) {
                const permissionIds = isEmpty(values.permissions)
                  ? values.permissions
                  : JSON.parse(values.permissions).map(p => p.id)
                await api.patchUserPermissions(entityProfile.guid, { permissionIds })
              }

              if (payload['physicianUsername']) await dispatch.refetchEntities(props, 'patient')

              // Refetch myProfile if dashbaord user is editing his or her own profile
              if (entityProfile.guid === myProfile.sub) {
                const username = window.localStorage.getItem('username')
                let myProfileResponse = await api.getUser(username)

                let myNewProfile = {}
                if (myProfileResponse.data.userAttributes && myProfileResponse.data.userAttributes.length > 0) {
                  myProfileResponse.data.userAttributes.forEach(a => myNewProfile[a.name] = a.value)
                }
                // Check if emailVerified is present, if not (new account), add default key-value
                myNewProfile.emailVerified = myNewProfile.email_verified
                myNewProfile.physicianUsername = myProfileResponse.data.physicianUsername

                dispatch.setData('myProfile', {
                  ...camelizeKeys(myNewProfile),
                  role: myProfile.role,
                })
                
                // Refetch role and permissions only when required
                if (patchKeys.includes('role') || patchKeys.includes('permissions')) {
                  const userPermissionResponse = await api.getUserPermissions(username)
                  const userApplication = find(userPermissionResponse.data, app => app.applicationId === application.id)
                  if (userApplication) {
                    dispatch.setData('myProfile', {
                      ...window.MHealthMain.getStoreState().myProfile,
                      role: {
                        ...userApplication.application.role,
                        permissions: userApplication.application.role.permissions.map((role) => role.permission)
                      }
                    })
                  }
                }
              }

              Notification({ message: 'Care team member details updated' })
              dispatch.setModalVisibility(false)
            } catch (error) {
              Notification({
                message: 'Failed to edit care team member',
                description: 'Please verify all information is entered correctly'
              })
              dispatch.setModalVisibility(false)
            }
          }
        })
        dispatch.setModalVisibility(true)
      } else {
        Notification({
          message: 'Failed to edit care team member',
          description: 'No changes found'
        })
      }
    }
  },
  loginHistoryTable: {
    title: "Login History",
    tableConfigs: [
      {
        title: "Date",
        key: 'date',
        dataIndex: 'date',
        align: 'left',
      },
      {
        title: "Time",
        key: 'time',
        dataIndex: 'time',
        align: 'left',
      },
    ],
    goToPage: async (entity, pageNumber) => {
      try {
        const { mHealthMain: { api, dispatch } } = props
        const response = await api.getLoginHistory(
          '/loginHistory',
          entity.guid,
          15,
          pageNumber,
        )
        if (get(response, ['data', 'history'], []).length > 0) {
          const formattedData = response.data.history.map(datum => {
            const momentObjInUTC = moment.utc(datum)
            return {
              date: momentObjInUTC.local().format("MMMM D, YYYY"),
              time: momentObjInUTC.local().format("hh:mm:ss")
            }
          })
          dispatch.setData(
            'entityLoginHistory',
            { 
              history: formattedData, 
              pagination: response.data.paging
            }
          )
        } else {
          throw (response)
        }
      } catch (error) {
        Notification({
          message: 'Failed to load dashboard user login history',
          description: 'Please try again later'
        })
      }
    }
  }
})