import { Notification } from '@adkgroup/appkit-ui'

import * as types from './csvTypes'
import { buildPostConfig, improveAxios } from '@/utils/axios/helpers'

export const uploadCSVFileContents = (csvFileData) => async (dispatch) => {
  dispatch({ type: types.UPLOAD_CSV_FILE_CONTENTS_BEGIN })
  try {
    const config = buildPostConfig('patient/csvfile', 'POST', csvFileData)
    const response = await improveAxios.request(config)
    dispatch({
      type: types.UPLOAD_CSV_FILE_CONTENTS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    console.log(error)
    Notification({
      message: 'Failed to upload CSV',
      description: 'Please try again later.'
    })
    dispatch({ type: types.UPLOAD_CSV_FILE_CONTENTS_FAIL })
  }
}

export const uploadCSVFileToPatients = () => async (dispatch, getState) => {
  const { patientsToUpload } = getState().csvReducer
  dispatch({ type: types.UPLOAD_CSV_FILE_PATIENTS_BEGIN })
  try {
    const config = buildPostConfig('patient/csv', 'POST', patientsToUpload )
    const response = await improveAxios.request(config)
    dispatch({
      type: types.UPLOAD_CSV_FILE_PATIENTS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    Notification({
      message: 'Failed to upload Patients',
      description: 'Please try again later.'
    }) 
    dispatch({ type: types.UPLOAD_CSV_FILE_PATIENTS_FAIL })
  }
}

export const resetCSVData = () => ({
  type: types.RESET_CSV_DATA
})

export const removeCSVPatient = (index) => ({
  type: types.REMOVE_CSV_PATIENT,
  payload: index
})