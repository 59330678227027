import {
  SET_SITES,
  SET_TRIALS,
  SET_SELECTED_SITE,
  SET_SELECTED_TRIAL,
  ADD_NEW_SITE,
  ADD_NEW_TRIAL,
  EDIT_SITE,
  EDIT_TRIAL,
  LOGOUT_SUCCESS
} from '../../actionTypes'

const INITIAL_STATE = {
  sites: null,
  trials: null,
  selectedSite: window.localStorage.getItem('selectedSite') || null,
  selectedTrial: window.localStorage.getItem('selectedTrial') || null 
}

export default (state = INITIAL_STATE, action) => {
  let copy
  let trialIdx
  let siteIdx
  switch (action.type) {
    case SET_SITES:
      return {
        ...state,
        sites: action.sites
      }
    case SET_TRIALS:
      return {
        ...state,
        trials: action.trials
      }
    case SET_SELECTED_SITE:
      if (action.selectedSite) {
        window.localStorage.setItem('selectedSite', action.selectedSite)
      } else {
        window.localStorage.removeItem('selectedSite')
      }
      return {
        ...state,
        selectedSite: action.selectedSite ? action.selectedSite : null
      }
    case SET_SELECTED_TRIAL:
      if (action.selectedTrial) {
        window.localStorage.setItem('selectedTrial', action.selectedTrial)
      } else {
        window.localStorage.removeItem('selectedTrial')
      }
      return {
        ...state,
        selectedTrial: action.selectedTrial ? action.selectedTrial : null
      }
    case ADD_NEW_TRIAL:
      copy = [...state.trials]
      copy.push(action.trial)
      return {
        ...state,
        trials: copy
      }
    case ADD_NEW_SITE:
      let copy = [...state.trials]
      let trialIdx = copy.findIndex(t => t.id === action.trialId)
      copy[trialIdx].site.push(action.site)
      return {
        ...state,
        trials: copy
      }
    case EDIT_TRIAL:
      copy = [...state.trials]
      trialIdx = copy.findIndex(t => t.id === action.trial.id)
      copy[trialIdx] = {
        ...copy[trialIdx],
        ...action.trial
      }
      return {
        ...state,
        trials: copy
      }
    case EDIT_SITE:
      copy = [...state.trials]
      trialIdx = copy.findIndex(t => t.id === action.trialId)
      let siteIdx = copy[trialIdx].site.findIndex(s => s.id === action.site.id)
      copy[trialIdx].site[siteIdx] = {
        ...copy[trialIdx].site[siteIdx],
        ...action.site
      }
      return {
        ...state,
        trials: copy
      }
    case LOGOUT_SUCCESS:
      localStorage.removeItem('selectedSite')
      localStorage.removeItem('selectedTrial')
      return {
        ...state,
        selectedSite: null,
        selectedTrial: null
      }
    default:
      return state
  }
}

