import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, Switch } from 'react-router-dom'
import moment from 'moment'

import { Provider, connect } from 'react-redux'

import { initialize as initializeAxios } from './utils/axios/helpers'
import Main from './Main'
import PrivacyPolicy from './static/PrivacyPolicy'
import TermsOfService from './static/TermsOfService'
import { AuthScreen, BaseLayoutScreen } from './screens'

import { DEFAULT_ROUTE, LOGIN_ROUTE } from './configs/app'

import { Modal } from '@adkgroup/appkit-ui'

import './styles/antd.scss'
import './styles/base.scss'
import 'antd/lib/style/themes/default.less'
import './styles/createPatient.scss'
import './styles/header.scss'

import getBaseConfig from './configs/app/base'
import * as moduleConfigs from './configs/modules'

const mHealthMain = new Main()
mHealthMain.init()
initializeAxios()

const appkitUtils = require('@adkgroup/appkit-utils')

const GuestOnlyRoute = appkitUtils.GuestOnlyRoute(window.location.pathname.indexOf('/auth') !== -1 ? '/patients' : window.location.pathname, mHealthMain.isAuthenticated)
const AuthenticatedRoute = appkitUtils.AuthenticatedRoute(LOGIN_ROUTE, mHealthMain.isAuthenticated)

const DASHBOARD_VERSION = require('../package.json').version

class VisibleBaseLayout extends Component {

  componentDidMount() {
    window.localStorage.setItem('version', DASHBOARD_VERSION)
  }

  componentWillUnmount() {
    window.localStorage.removeItem('version')
  }

  render () {
    let baseConfig = getBaseConfig({
      ...this.props,
      mHealthMain: mHealthMain
    })

    let masterConfig = {
      baseConfig,
      ...moduleConfigs
    }

    return (
      <Router history={mHealthMain.history}>
        <div>
          {/* <Desktop> */}
          <Switch>
            <GuestOnlyRoute
              path={'/auth/:action'} // email verify route is going through here, temp fix
              mHealthMain={mHealthMain}
              component={AuthScreen}
              {...this.props}
              mConfig={masterConfig}
            />
            <GuestOnlyRoute
              path={'/privacy-policy'}
              component={PrivacyPolicy}
              mConfig={masterConfig}
            />
            <GuestOnlyRoute
              path={'/terms-of-service'}
              component={TermsOfService}
              mConfig={masterConfig}
            />
            <AuthenticatedRoute
              rootPath={DEFAULT_ROUTE}
              path={'/'}
              mHealthMain={mHealthMain}
              component={BaseLayoutScreen}
              {...this.props}
              mConfig={masterConfig}
            />
          </Switch>
          <Modal {...this.props} mHealthMain={mHealthMain} />
          {/* </Desktop> */}
          {/* <Mobile> */}
          {/* <div className='mobile-message'>This app does not support mobile. Please visit on a Laptop, Desktop or Tablet.</div> */}
          {/* </Mobile> */}
        </div>
      </Router>
    )
  }
}

function mapStateToProps (state) {
  let reducers = {}

  for (let reducerKey in mHealthMain.loadedReducers) {
    reducers[reducerKey] = state[reducerKey]
  }

  return reducers
}

const VisibleWrapped = connect(
  mapStateToProps
)(VisibleBaseLayout)

ReactDOM.render(
  <Provider store={mHealthMain.store}>
    <VisibleWrapped />
  </Provider>,
  document.getElementById('root')
)
