'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AUTH_ROUTE = exports.AUTH_ROUTE = '/login';
var USER_ROUTE = exports.USER_ROUTE = '/user';
var COHORT_ROUTE = exports.COHORT_ROUTE = '/cohort';
var PHOTO_UPLOAD_ROUTE = exports.PHOTO_UPLOAD_ROUTE = '/profilePhoto';
var APPLICATION_ROUTE = exports.APPLICATION_ROUTE = '/application';
var ROLE_ROUTE = exports.ROLE_ROUTE = '/role';
var PHYSICIAN_ROUTE = exports.PHYSICIAN_ROUTE = '/physician';
var QUESTIONNAIRE_ROUTE = exports.QUESTIONNAIRE_ROUTE = '/questionnaire';
var UPLOAD_ROUTE = exports.UPLOAD_ROUTE = '/server_file';
var SURVEY_ROUTE = exports.SURVEY_ROUTE = '/questionnaire/lookup';
var QUESTIONNAIRE_SCHEDULER_ROUTE = exports.QUESTIONNAIRE_SCHEDULER_ROUTE = '/questionnaire/queue/scheduler';