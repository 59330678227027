'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = patchUserPermissions;

var _endpoints = require('../../endpoints');

function patchUserPermissions(username, payload) {
  return ['PATCH', true, _endpoints.USER_ROUTE, payload, null, '/' + username + '/permissions'];
}