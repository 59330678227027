import {
  SET_SITES,
  SET_TRIALS,
  SET_SELECTED_SITE,
  SET_SELECTED_TRIAL,
  ADD_NEW_SITE,
  ADD_NEW_TRIAL,
  EDIT_SITE,
  EDIT_TRIAL
} from '../../actionTypes'

export const setTrials = (trials) => {
  return {
    type: SET_TRIALS,
    trials
  }
}

export const setSites = (sites) => {
  return {
    type: SET_SITES,
    sites
  }
}

export const setSelectedTrial = (selectedTrial) => {
  return {
    type: SET_SELECTED_TRIAL,
    selectedTrial
  }
}

export const setSelectedSite = (selectedSite) => {
  return {
    type: SET_SELECTED_SITE,
    selectedSite
  }
}

export const addTrial = (trial) => {
  return {
    type: ADD_NEW_TRIAL,
    trial
  }
}

export const addSite = (trialId, site) => {
  return {
    type: ADD_NEW_SITE,
    trialId,
    site
  }
}

export const editTrial = (trial) => {
  return {
    type: EDIT_TRIAL,
    trial
  }
}

export const editSite = (trialId, site) => {
  return {
    type: EDIT_SITE,
    trialId,
    site
  }
}