'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reactPerfectScrollbar = require('react-perfect-scrollbar');

var _reactPerfectScrollbar2 = _interopRequireDefault(_reactPerfectScrollbar);

require('react-perfect-scrollbar/dist/css/styles.css');

require('./ScrollBar.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _reactPerfectScrollbar2.default;