'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AntAlert = function AntAlert(props) {
  var type = props.type,
      message = props.message,
      _props$banner = props.banner,
      banner = _props$banner === undefined ? true : _props$banner,
      _props$showIcon = props.showIcon,
      showIcon = _props$showIcon === undefined ? true : _props$showIcon;

  return _react2.default.createElement(_antd.Alert, {
    type: type,
    message: message,
    banner: banner,
    showIcon: showIcon
  });
};

exports.default = AntAlert;