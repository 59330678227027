import React from 'react'
import S3 from '@/shared/aws'
import { Spin } from 'antd'

const EXTENSION_REGEX = /(?:\.([^.]+))?$/

class UploadedResourceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }

  componentDidMount () {
    this.fetchResource()
  }

  parseFileExtension = path => EXTENSION_REGEX.exec(path)[1]

  fetchResource = async () => {
    const { resource: { responseUrl } } = this.props
    const { REACT_APP_AWS_BUCKET_NAME } = process.env
    const key = responseUrl.replace(`https://${REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`, '')
    const response = await S3.getObject(key)
    const resourceURL = URL.createObjectURL(
      new Blob([response.Body],
      { type: `image/${this.parseFileExtension(responseUrl)}`})
    )
    this.setState({ resourceURL })
  }

  renderResource = () => {
    return <img
      className="uploaded-resource-image"
      src={this.state.resourceURL || ''}
      alt={"Resource cannot be loaded"}
      style={{ maxWidth: window.innerWidth * 0.50 }}
    />
  }

  render() {
    const { resource } = this.props
    return (
      <div className="uploaded-resource-modal-content">
        {this.state.resourceURL
          ? <>
            {this.renderResource()}
            <div className="uploaded-resource-text">{resource.response}</div>
          </>
          : <Spin className="uploaded-resource-loader"/>}
      </div>
    );
  }
}

export default UploadedResourceModal;