'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.refreshToken = exports.resendVerification = exports.verifyEmail = exports.confirmPassword = exports.forgotPassword = exports.authenticate = undefined;

var _authenticate = require('./authenticate');

var _refreshToken = require('./refreshToken');

var _refreshToken2 = _interopRequireDefault(_refreshToken);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.authenticate = _authenticate.authenticate;
exports.forgotPassword = _authenticate.forgotPassword;
exports.confirmPassword = _authenticate.confirmPassword;
exports.verifyEmail = _authenticate.verifyEmail;
exports.resendVerification = _authenticate.resendVerification;
exports.refreshToken = _refreshToken2.default;