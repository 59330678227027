import moment from 'moment'

let storedData = null

export const resetStoredData = () => storedData = null

export const getCompleteHealthGraphData = (surveyCategories, surgeryDate) => {
  if (storedData) return storedData
  let organizedSurveyData = {}
  surveyCategories.forEach((category) => {
    category.domains.forEach((domain) => {
      if (domain.name === 'Satisfaction with breast (BREAST-Q)') {
        organizedSurveyData.satisfaction = domain
      } else if (domain.name === "Physical well-being: chest (BREAST-Q)") {
        organizedSurveyData.physical = domain
      } else if (domain.name === "Sexual well-being (BREAST-Q)") {
        organizedSurveyData.sexual = domain
      } else if (domain.name === "Psychosocial well-being (BREAST-Q)") {
        organizedSurveyData.psychosocial = domain

      }
    })
  })
  const surveyDates = getValidDates(organizedSurveyData, surgeryDate)
  const graphData = getDataSet(organizedSurveyData, surveyDates)
  const completeGraphData = { ...checkForMissingSurveyData(graphData), dates: { ...surveyDates } }
  storedData = completeGraphData
  return completeGraphData
}

// Used to return a date that is rounded to the day. There is usually a few second time difference between dates returned by the BE.
const getFormattedDate = (date) => moment(date).startOf('day')

const getValidDates = (organizedSurveyData, surgeryDate) => {
  const validDates = {
    preOp: null,
    secondToLastScore: null,
    lastScore: null
  }
  Object.keys(organizedSurveyData).forEach(surveyDataSet => {
    let i = 0
    const dataLength = organizedSurveyData[surveyDataSet].data.length
    for (i; i < dataLength; i++) {
      const date = getFormattedDate(organizedSurveyData[surveyDataSet].data[i].date)
      // if date is before surgery
      // if there already is a preOp date and this new date isn't before current preop day. Return
      // If there isn't a preOp date. Assign this date as preop date.
      if (moment(date).isBefore(surgeryDate)) {
        if (validDates.preOp && moment(date).isAfter(moment(validDates.preOp))) break
        else { 
          validDates.preOp = getFormattedDate(date) 
        }
      } else {
        // If date is after surgery
        // If there isn't a lastScore date. Assign this date as last score.
        // If there already is a lastScore date and this new date is newer than the saved score -
        // - Assign the old date to secondToLastScore and set lastScore to the new date.
        if (!validDates.lastScore) { 
          validDates.lastScore = getFormattedDate(date)
          break
        }
        else if (moment(date).isAfter(validDates.lastScore)) {
          validDates.secondToLastScore = validDates.lastScore
          validDates.lastScore = getFormattedDate(date)
        }
        else {
          // if date is after surgery, but not after the current lastScore,
          // if there is no secondToLastScore, assign the date as the secondToLastScore
          // If there is a date, check to see if this new date is after the current saved one, if it is - override the date saved as secondToLastScore
          if (!validDates.secondToLastScore) validDates.secondToLastScore = getFormattedDate(date)
          else if (moment(date).isAfter(validDates.secondToLastScore) && !moment(date).isSame(validDates.lastScore)) validDates.secondToLastScore = getFormattedDate(date)
        }
      }
    }
  });
  return validDates
}

const checkForMissingSurveyData = (graphData) => {
  const completeData = {
    scores: { ...graphData },
    missingData: {
      preOp: [],
      secondToLastScore: [],
      lastScore: []
    }
  }
  Object.keys(graphData).forEach((graphKey) => {
    let hasPhysical = false
    let hasSexual = false
    let hasPsychosocial = false
    let hasSatisfaction = false
    let i = 0
    let dataLength = graphData[graphKey].length
    for (i; i < dataLength; i++) {
      const type = graphData[graphKey][i].x.replace(/\s+/g, ' ')
      switch (type) {
        case 'Physical Well-Being':
          hasPhysical = true
          break
        case 'Psychosocial Well-Being':
          hasPsychosocial = true
          break
        case 'Satisfaction with Breast':
          hasSatisfaction = true
          break
        case 'Sexual Well-Being':
          hasSexual = true
          break
      }
    }
    if (!hasPhysical) {
      completeData.scores[graphKey].push({ x: "Physical Well-Being", y: 0 })
      completeData.missingData[graphKey].push('Missing Physical Survey Data')
    }
    if (!hasSexual) { 
      completeData.scores[graphKey].push({ x: "Sexual\nWell-Being", y: 0 })
      completeData.missingData[graphKey].push('Missing Sexual Survey Data')
    }
    if (!hasPsychosocial) { 
      completeData.scores[graphKey].push({ x: "Psychosocial\nWell-Being", y: 0 })
      completeData.missingData[graphKey].push('Missing Psychosocial Survey Data') 
    }
    if (!hasSatisfaction) { 
      completeData.scores[graphKey].push({ x: "Satisfaction with Breast", y: 0 })
      completeData.missingData[graphKey].push('Missing Satisfaction Survey Data') 
    }
  })
  return completeData
}

const getGraphObject = (dataPoint, type) => {
  const { value, date } = dataPoint
  switch (type) {
    case "physical":
      return {
        x: 'Physical Well-Being',
        date,
        y: value
      }
    case "psychosocial":
      return {
        x: 'Psychosocial\nWell-Being',
        date,
        y: value
      }
    case "satisfaction":
      return {
        x: 'Satisfaction with Breast',
        date,
        y: value
      }
    case "sexual":
      return {
        x: 'Sexual\nWell-Being',
        date,
        y: value
      }
    default:
      console.log('Invalid type', type)
      break;
  }
}

const getDataSet = (organizedSurveyData, surveyDates) => {
  const graphData = {
    preOp: [],
    secondToLastScore: [],
    lastScore: []
  }
  Object.keys(organizedSurveyData).forEach(surveyDataSet => {
    organizedSurveyData[surveyDataSet].data.forEach(dataPoint => {
      if (moment(getFormattedDate(dataPoint.date)).isSame(surveyDates.preOp)) graphData.preOp.push(getGraphObject(dataPoint, surveyDataSet))
      if (moment(getFormattedDate(dataPoint.date)).isSame(surveyDates.secondToLastScore)) graphData.secondToLastScore.push(getGraphObject(dataPoint, surveyDataSet))
      if (moment(getFormattedDate(dataPoint.date)).isSame(surveyDates.lastScore)) graphData.lastScore.push(getGraphObject(dataPoint, surveyDataSet))
    })
  })
  return graphData
}