import { Notification } from '@adkgroup/appkit-ui'
import moment from 'moment'
import has from 'lodash/has'
import get from 'lodash/get'

import { improveAxios, buildRequestConfig } from '../../../../utils/axios/helpers'
import { constructPatientDetails, formatSurveyResponseData } from './participantInitHelpers'

const getPatientQuestionnaireScores = (guid) => buildRequestConfig(`dashboard/patients/${guid}/questionnaireScores`, 'get')
const getPatientDetailsConfig = (guid) => buildRequestConfig(`patient/${guid}/patientDetails`, 'get')
const getPatientCareTeam = (guid) => buildRequestConfig(`dashboard/patients/${guid}/careteam`, 'get')

const filteredObjectKeys = ['surgeryType', 'surgeryDate', 'nextPROMDate']

export default (props, guid) => ([
  { // getPatientResponse
    reduxName: 'patientResponses',
    request: async () => await props.mHealthMain.api.getPatientResponses(
      props.mHealthMain.models.patientResponses,
      null,
      null,
      props.mHealthMain.getStoreState().UI.patientResponseTable.pagination,
      guid
    ),
    fallback: () => props.mHealthMain.dispatch.setData('patientResponses', []),
    onError: () => Notification({
      message: 'Failed to load patient response data',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      props.mHealthMain.dispatch.setData('patientResponses', response.data.patientResponses.items)
      props.mHealthMain.dispatch.setTablePageInfo(
        'patientResponse',
        response.data.patientResponses.pageInfo,
        response.data.patientResponses.totalCount
      )
    }
  },
  { // getPatient
    reduxName: 'entityProfile',
    request: async () => await props.mHealthMain.api.getPatient(
      guid,
      // props.moduleConfig.tableTabs[0].columns.map((c) => {
      //   if (filteredObjectKeys.includes(c.fieldKey)) return ''
      //   else return c.fieldKey
      // })
      props.mHealthMain.models.patients
    ),
    fallback: () => props.mHealthMain.dispatch.setData('entityProfile', {}),
    onError: () => Notification({
      message: 'Failed to load patient data',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      const entityData = response.data.patients.items[0]
      if (entityData) {
        props.mHealthMain.dispatch.setData(
          'entityProfile',
          { 
            ...props.mHealthMain.getStoreState().entityProfile,
            ...entityData,
            dateOfBirth: entityData.dateOfBirth && moment(entityData.dateOfBirth).format('YYYY-MM-DD'),
            phone: entityData.phone && entityData.phone.substr(2), // remove +1
          }
        )
      }
    }
  },
  { // getLoginHistory
    reduxName: 'entityLoginHistory',
    request: async () => await props.mHealthMain.api.getLoginHistory(
      '/patient/loginHistory',
      guid,
      15,
    ),
    fallback: () => props.mHealthMain.dispatch.setData('entityLoginHistory', {}),
    onError: () => Notification({
      message: 'Failed to load patient login history',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      if (get(response, ['data', 'history'], []).length > 0) {
        const formattedData = response.data.history.map(datum => {
          const momentObjInUTC = moment.utc(datum)
          return {
            date: momentObjInUTC.local().format("MMMM D, YYYY"),
            time: momentObjInUTC.local().format("hh:mm:ss")
          }
        })
        props.mHealthMain.dispatch.setData(
          'entityLoginHistory',
          { history: formattedData, pagination: response.data.paging }
        )
      } else {
        props.mHealthMain.dispatch.setData(
          'entityLoginHistory',
          { history: [], pagination: {} }
        )
      }
    }
  },
  { // getPatientDetails
    request: async () => await improveAxios.request(getPatientDetailsConfig(guid)),
    fallback: () => props.mHealthMain.dispatch.setData('entityProfile', {}),
    onError: () => Notification({
      message: 'Failed to load patient details',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      if (has(response, ['data', 'attributes']) && response.data.attributes.length) {
        props.mHealthMain.dispatch.setData(
          'entityProfile',
          { 
            ...props.mHealthMain.getStoreState().entityProfile,
            ...constructPatientDetails(response.data.attributes[0])
          }
        )
      } else return null
    }
  },
  {
    request: async () => await improveAxios.request(getPatientQuestionnaireScores(guid)),
    fallback: () => props.mHealthMain.dispatch.setData('entityProfile', {}),
    onError: () => Notification({
      message: 'Failed to load patient details',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      if (response && response.data) {
        props.mHealthMain.dispatch.setData(
          'entityProfile',
          { 
            ...props.mHealthMain.getStoreState().entityProfile,
            surveyCategories: formatSurveyResponseData(response.data)
          })
      } else return null
    }
  },
  {
    request: async () => await improveAxios.request(getPatientCareTeam(guid)),
    fallback: () => props.mHealthMain.dispatch.setData('entityProfile', {}),
    onError: () => Notification({
      message: 'Failed to load patient details',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      if (response && response.data) {
        props.mHealthMain.dispatch.setData(
          'entityProfile',
          { 
            ...props.mHealthMain.getStoreState().entityProfile,
            patientDoctors: response.data
          })
      } else return null
    }
  }
])