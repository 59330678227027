import columns from './columns'
import find from 'lodash/find'
import { buildRequestConfig, improveAxios } from '@/utils/axios/helpers'
import { Notification } from '@adkgroup/appkit-ui'
let fileDownload = require('js-file-download')

export default {
  dataFilter: (data) => data,
  modelName: 'patientResponse',
  scroll: false,
  onRowClick: (props, rowIndex) => {},
  columns,
  actions: (props) => ([
    {
      action: 'nullData',
      label: 'Missed Questions',
      value: find(props.UI.patientResponseTable.filters, f => Object.keys(f)[0] === 'nullData')
        ? find(props.UI.patientResponseTable.filters, f => Object.keys(f)[0] === 'nullData').nullData
        : false,
      onChange: (e) => {
        const entityType = props.tableConfig[0].modelName
        props.mHealthMain.dispatch.setTableFilter(
          entityType,
          { nullData: e.target.checked },
          false
        )
      }
    },
    {
      action: 'clearFilters',
      label: 'Clear Filters',
    },
    {
      action: 'export',
      className: 'patient-responses-table-export',
      // permissions: ['read_0_export', 'read_1_export'],
      label: 'Export Data',
      onClick: async () => {
        try {
          const config = buildRequestConfig(`dashboard/patient/${props.entityProfile.guid}/response/export`, 'get')
          const response = await improveAxios.request(config)
          fileDownload(response.data, 'questionnaire_export.csv')
        } catch (error) {
          console.log(error)
          Notification({
            message: 'Failed to download',
            description: 'Please try again later.'
          })
        }
        // const exportRes = await props.mHealthMain.api.exportData('questionnaireResponse', `?username=${props.entityProfile.guid}`)
        // fileDownload(JSON.stringify(exportRes.data), 'questionnaire_export.json')
      }
    },
  ]),
}