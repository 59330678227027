'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getSurveys;

var _endpoints = require('../../endpoints');

function getSurveys() {
  return ['GET', true, _endpoints.SURVEY_ROUTE, null, null];
}