import { studyTeamTab } from './tabs'
import profile from './profile'
import initEntityData from './initEntityData'
import create from './create'
import permissions from './permissions'

export default {
  navBarName: 'Care Team',
  module: 'EntityManagementModule',
  name: 'studyteammodule', // has to be uniqe each module,
  route: '/dashboardUsers',
  iconName: 'team',
  paths: {
    list: '/dashboardUsers',
    profile: '/dashboardUsers/:entityId/profile',
    create: '/dashboardUsers/action/create',
  },
  tableTabs: [
    studyTeamTab
  ],
  ...permissions,
  profile,
  defaultColumns: ['firstName', 'lastName', 'email', 'role', 'cohorts', 'status'],
  navItem: {
    onClick: (props, item) => props.mHealthMain.setActiveModule(item.masterConfig.name, window.location.pathname)
  },
  initEntityData,
  create,
}