import * as mHealthModules from './modules'
import * as mHealthModuleConfigs from './configs/modules'
import * as addOns from './addOns'

import { MHealthAPI } from '@adkgroup/appkit-baseapi'
import { mHealthMain } from '@adkgroup/appkit-utils'

import {
  BASENAME, AXIOS_BASE_URL, GRAPHQL_BASE_URL,
  HEADERS, STORAGE_DRIVER,
  MODELS, DEFAULT_MODULE,
} from './configs/app'

import { customAxiosRequests, customGqlRequests } from './configs/app/network'

const baseActions = {}
const baseOperations = {}
const baseReducers = {}

// Use this to overrirde any default mHealth functionality. Should not be required often.
export default class Main extends mHealthMain {
  constructor (props) {
    super(props)
    this.models = MODELS
    this.api = new MHealthAPI(
      { 
        AXIOS_BASE_URL,
        GRAPHQL_BASE_URL,
        HEADERS,
        STORAGE_DRIVER,
        customAxiosRequests,
        customGqlRequests,
      },
      this.dispatch.logoutUser
    )
    super.parseAddons(addOns)
    super.init(
      this.api,
      baseActions,
      baseOperations,
      baseReducers,
      mHealthModules,
      this.models,
      mHealthModuleConfigs,
      BASENAME
    )
  }

  activateDefaultModule () {
    super.activateDefaultModule(DEFAULT_MODULE)
  }
}