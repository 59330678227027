import { dataActionTypes } from '../../actionTypes'

const setData = (field, value, actionType) => {
  return {
    type: actionType ? actionType : dataActionTypes[field],
    [field]: value
  }
}

export {
  setData
}