import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { CSVReader } from 'react-papaparse'
import { Button, Icon } from 'antd'
import { Notification } from '@adkgroup/appkit-ui'

import CsvButton from './components/CsvButton'
import PatientList from './components/PatientList'
import { uploadCSVFileContents, resetCSVData } from '@/features/CSVImport/redux/csvActions'
import './CSVModal.scss'

const readerRef = React.createRef()

class CSVModal extends PureComponent {
  componentWillUnmount () {
    const { resetCSVData } = this.props
    resetCSVData()
  }

  formatCSVData = (data) => {
    const joinedData = data.map((row) => row.data.join(','))
    const formattedData = []
    joinedData.forEach((stringRow) => {
      if (stringRow[0] === ',') {
        formattedData.push(stringRow.slice(1, stringRow.length))
      }
      else if (stringRow.length) {
        formattedData.push(stringRow)
      }
    })
    return formattedData
  }

  handleOnDrop = (data) => {
    const { uploadCSVFileContents } = this.props
    uploadCSVFileContents(this.formatCSVData(data))
  }

  handleOnError = () => {
    Notification({
      message: 'Failed to read CSV file',
      description: 'Please try again later.'
    })
  }

  handleOpenDialog = (e) => {
    if (readerRef.current) {
      readerRef.current.open(e)
    }
  }

  getFileName = (file) => {
    const { uploadCsvFileFailed, uploadedCsvFile, numberOfUploadErrors, uploadedPatients } = this.props
    if (uploadCsvFileFailed) return 'Failed to upload!'
    if (numberOfUploadErrors) return `${file.name} Has ${numberOfUploadErrors} Error(s)`
    if (uploadedPatients) return `${file.name} Imported`
    if (!file) return 'No file chosen'
    if (file && file.name && uploadedCsvFile) return `${file.name} Ready to import`
    if (file && file.name) return `Importing ${file.name}`
  }

  renderIcon = () => {
    const { numberOfUploadErrors, uploadedPatients } = this.props
    if (uploadedPatients) {
      return (
        <Icon type='check-circle' className='o-csv__modal__upload__filename__icon--success' />
      )
    }
    if (numberOfUploadErrors) {
      return (
        <Icon type='exclamation-circle' className='o-csv__modal__upload__filename__icon--error' />
      )
    }
    return null
  }

  render () {
    const { 
      uploadingCsvFile, 
      uploadCsvFileFailed, 
      uploadedCsvFile, 
      uploadingPatients, 
      uploadedPatients,
      numberOfUploadErrors
    } = this.props
    return (
      <div>
        <CSVReader 
          ref={readerRef}
          onDrop={this.handleOnDrop}
          onError={this.handleOnError}
          addRemoveButton
          onRemoveFile={this.handleOnRemoveFile}
        >
          {({ file }) => (
            <div className='o-csv__modal__upload'>
              <div className='o-csv__modal__upload__filename'>
                { this.renderIcon() }
                <span className='o-csv__modal__upload__filename--name'>{this.getFileName(file)}</span>
              </div>
              <div className='o-csv__modal__upload__button__container'>
                <CsvButton 
                  hasErrors={!!numberOfUploadErrors} 
                  readerRef={readerRef} 
                />
                {uploadedCsvFile && (!uploadingPatients || !uploadedPatients) ? <Button
                  size='large'
                  onClick={this.handleOpenDialog}
                  disabled={uploadingCsvFile || uploadCsvFileFailed}
                  loading={uploadingCsvFile}
                  className={`o-csv__modal__upload__button__change`}
                >
                  Change File
                </Button> : null }
              </div>
            </div>
          )}
        </CSVReader>
        <PatientList />
      </div>
    )
  }
}

const mapStateToProps = ({ csvReducer }) => {
  const { 
    patientsToUpload, 
    meta: { 
      uploadingCsvFile, 
      uploadCsvFileFailed, 
      uploadedCsvFile,
      uploadedPatients,
      uploadingPatients
    } 
  } = csvReducer
  let numberOfUploadErrors = 0
  patientsToUpload.forEach((patient) => {
    if (patient.validationMessage) numberOfUploadErrors++
  })
  return { 
    uploadingCsvFile, 
    uploadCsvFileFailed, 
    uploadedCsvFile, 
    numberOfUploadErrors, 
    uploadedPatients, 
    uploadingPatients 
  }
}

const mapDispatchToProps = { uploadCSVFileContents, resetCSVData }

export default connect(mapStateToProps, mapDispatchToProps)(CSVModal)