import React from 'react'
import { connect } from 'react-redux'
import { CreateEntity } from '@adkgroup/appkit-ui'

import { wipeTables } from '../../../../features/TreatmentTable/redux/createPatientActions'

class AddEntityScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = { }
  }

  UNSAFE_componentWillMount() {
    wipeTables(this.props.dispatch)
  }

  render () {
    // *refactor*
    const { location, moduleConfig } = this.props
    const entityType = location.pathname.split('/')[1]
    return (
      <div className='add-entity-screen'>
        <CreateEntity
          {...this.props}
          entityType={entityType}
          configs={moduleConfig.create(this.props)}
        />
      </div>
    )
  }
}

export default connect(null, { wipeTables })(AddEntityScreen)