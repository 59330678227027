'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _antd = require('antd');

var _ScrollBar = require('../ScrollBar');

var _ScrollBar2 = _interopRequireDefault(_ScrollBar);

var _navigation = require('../../navigation');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./Sider.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var fileDownload = require('js-file-download');

// @withI18n()

var Sider = function (_PureComponent) {
  (0, _inherits3.default)(Sider, _PureComponent);

  function Sider() {
    (0, _classCallCheck3.default)(this, Sider);
    return (0, _possibleConstructorReturn3.default)(this, (Sider.__proto__ || (0, _getPrototypeOf2.default)(Sider)).apply(this, arguments));
  }

  (0, _createClass3.default)(Sider, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          menus = _props.menus,
          theme = _props.theme,
          isMobile = _props.isMobile,
          collapsed = _props.collapsed,
          onCollapseChange = _props.onCollapseChange;


      return _react2.default.createElement(
        _antd.Layout.Sider,
        {
          width: 256,
          theme: theme,
          breakpoint: 'lg',
          trigger: null,
          collapsible: true,
          collapsed: collapsed,
          onBreakpoint: isMobile ? null : onCollapseChange,
          className: 'sider'
        },
        _react2.default.createElement(
          'div',
          { className: 'menuContainer' },
          _react2.default.createElement(
            'div',
            {
              className: 'button',
              onClick: onCollapseChange.bind(this, !collapsed)
            },
            _react2.default.createElement(_antd.Icon, {
              type: (0, _classnames2.default)({
                'right': collapsed,
                'left': !collapsed
              })
            })
          ),
          _react2.default.createElement(
            _ScrollBar2.default,
            {
              options: {
                // Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
                suppressScrollX: true
              }
            },
            _react2.default.createElement(_navigation.SiderMenu, (0, _extends3.default)({}, this.props, {
              menus: menus,
              theme: theme,
              isMobile: isMobile,
              collapsed: collapsed,
              onCollapseChange: onCollapseChange
            }))
          )
        )
      );
    }
  }]);
  return Sider;
}(_react.PureComponent);

Sider.propTypes = {
  menus: _propTypes2.default.array,
  theme: _propTypes2.default.string,
  isMobile: _propTypes2.default.bool,
  collapsed: _propTypes2.default.bool,
  onThemeChange: _propTypes2.default.func,
  onCollapseChange: _propTypes2.default.func
};

exports.default = Sider;