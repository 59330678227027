import { scroller } from 'react-scroll'

import {
  SET_POPOVER_STATE,
  SET_MODAL_CONTENT,
  SET_MODAL_VISIBILITY,
  SET_APP_BUSY_STATE,
  SET_SELECTED_TABLE_ROWS,
  SET_TABLE_COLUMNS,
  SET_TABLE_FILTER,
  RESET_TABLE_FILTER,
  RESET_ALL_TABLE_FILTERS,
  SET_TABLE_PAGE_SIZE,
  SET_TABLE_PAGE_NUMBER,
  SET_TABLE_PAGE_INFO,
  SET_TABLE_SORT,
} from '../../actionTypes'

export default (busyState) => ({
  type: SET_APP_BUSY_STATE,
  busy: busyState
})

export const selectTableRows = (tableRowsSelected) => ({
  type: SET_SELECTED_TABLE_ROWS,
  tableRowsSelected
})

// this is an action that does not update a reducer.. just scrolls.
export const scrollToTop = () => async dispatch => {
  scroller.scrollTo('module-content-top', {
    duration: 400,
    delay: 0,
    smooth: true,
    containerId: 'main-content-area',
    offset: -150
  })
}

export const setPopoverState = (anchorEl, content, onClose, anchorOrigin, transformOrigin) => ({
  type: SET_POPOVER_STATE,
  anchorEl,
  content,
  onClose,
  anchorOrigin,
  transformOrigin
})

export const setModalContent = ({
  title,
  content,
  onOk,
  okText,
  onCancel,
  okButtonProps,
  cancelButtonProps,
  className,
  width,
  visible
}) => ({
  type: SET_MODAL_CONTENT,
  title,
  content,
  onOk,
  okText,
  onCancel,
  okButtonProps,
  cancelButtonProps,
  className,
  width,
  visible,
})

export const setModalVisibility = (visible) => {
  return {
    type: SET_MODAL_VISIBILITY,
    visible,
  }
}

export const setBusyState = (busy) => ({
  type: SET_APP_BUSY_STATE,
  busy
})

export const setTableColumns = (entityType, columns) => ({
  type: SET_TABLE_COLUMNS,
  entityType,
  columns,
})

export const setTableFilter = (entityType, filters, reset) => ({
  type: SET_TABLE_FILTER,
  entityType,
  filters,
  reset,
})

export const resetTableFilter = (entityType, removeIndex) => ({
  type: RESET_TABLE_FILTER,
  entityType,
  removeIndex,
})

export const resetAllTableFilters = (entityType) => ({
  type: RESET_ALL_TABLE_FILTERS,
  entityType,
})

export const setTablePageSize = (entityType, pageSize) => ({
  type: SET_TABLE_PAGE_SIZE,
  entityType,
  pageSize
})

export const setTablePageNumber = (entityType, pageNumber) => ({
  type: SET_TABLE_PAGE_NUMBER,
  entityType,
  pageNumber,
})

export const setTablePageInfo = (entityType, pageInfo, totalCount) => ({
  type: SET_TABLE_PAGE_INFO,
  entityType,
  pageInfo,
  totalCount,
})

export const setTableSort = (entityType, sort) => ({
  type: SET_TABLE_SORT,
  entityType,
  sort,
})