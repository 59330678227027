import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import './PatientHeaderSummary.scss'

const PatientHeaderSummary = ({ 
  previousSurgeryTable = [],
  activeTreatmentTable = [],
  previousTreatmentTable = [],
  activeSurgeryTable = []
}) => {

  return (
    <div className='patient__header__summary'>
      <div className='patient__header__summary__item'>
        <p className='patient__header__summary__item__header'>Previous Surgeries:</p>
        {previousSurgeryTable.length ? 
          previousSurgeryTable.map(
            (surgery, index) => 
              <p 
                key={`${surgery.previousSurgeryType}-${index}`} 
                className='patient__header__summary__item__stat'
              >
                {surgery.previousSurgeryType}
              </p>
          ) : (
            <p className='patient__header__summary__item__stat'>None</p>
          )
        }
      </div>
      <div className='patient__header__summary__item'>
        <p className='patient__header__summary__item__header'>Active Treatment:</p>
        {activeTreatmentTable.length ? 
          activeTreatmentTable.map(
            (surgery, index) => 
              <p 
                key={`${surgery.activeTreatment}-${index}`} 
                className='patient__header__summary__item__stat'
              >
                {surgery.activeTreatment}
              </p>
          ) : (
            <p className='patient__header__summary__item__stat'>None</p>
          )
        }
      </div>
      <div className='patient__header__summary__item'>
        <p className='patient__header__summary__item__header'>Previous Treatments:</p>
        {previousTreatmentTable.length ? 
          previousTreatmentTable.map(
            (surgery, index) => 
              <p 
                key={`${surgery.previousTreatment}-${index}`} 
                className='patient__header__summary__item__stat'
              >
                {surgery.previousTreatment}
              </p>
          ) : (
            <p className='patient__header__summary__item__stat'>None</p>
          )
        }
      </div>
      <div className='patient__header__summary__item'>
        <p className='patient__header__summary__item__header'>Last Surgery Type:</p>
        <p className='patient__header__summary__item__stat'>
          {
            activeSurgeryTable.length ? (
              <p className='patient__header__summary__item__stat'>{activeSurgeryTable[0].activeSurgeryType}</p>
            ) : (
              <p className='patient__header__summary__item__stat'>None</p>
            )
          }
        </p>
      </div>
      <div className='patient__header__summary__item'>
        <p className='patient__header__summary__item__header'>Last Surgery Date:</p>
        {
          activeSurgeryTable.length ? (
            <p className='patient__header__summary__item__stat'>{moment(activeSurgeryTable[0].activeSurgeryDate).format('MM-DD-YYYY')}</p>
          ) : (
            <p className='patient__header__summary__item__stat'>N/A</p>
          )
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ entityProfile }) => {
  const { 
    previousSurgeryTable, 
    activeTreatmentTable, 
    previousTreatmentTable,
    activeSurgeryTable
  } = entityProfile
  return { 
    previousSurgeryTable, 
    activeTreatmentTable, 
    previousTreatmentTable,
    activeSurgeryTable
  }
}

export default connect(mapStateToProps)(PatientHeaderSummary)