'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patchUserPermissions = exports.getUserPermissions = undefined;

var _getUserPermissions = require('./getUserPermissions');

var _getUserPermissions2 = _interopRequireDefault(_getUserPermissions);

var _patchUserPermissions = require('./patchUserPermissions');

var _patchUserPermissions2 = _interopRequireDefault(_patchUserPermissions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.getUserPermissions = _getUserPermissions2.default;
exports.patchUserPermissions = _patchUserPermissions2.default;