'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames2 = require('classnames');

var _classnames3 = _interopRequireDefault(_classnames2);

var _Loader = require('../Loader');

var _Loader2 = _interopRequireDefault(_Loader);

require('./Page.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Page = function (_Component) {
  (0, _inherits3.default)(Page, _Component);

  function Page() {
    (0, _classCallCheck3.default)(this, Page);
    return (0, _possibleConstructorReturn3.default)(this, (Page.__proto__ || (0, _getPrototypeOf2.default)(Page)).apply(this, arguments));
  }

  (0, _createClass3.default)(Page, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          children = _props.children,
          _props$loading = _props.loading,
          loading = _props$loading === undefined ? false : _props$loading,
          _props$inner = _props.inner,
          inner = _props$inner === undefined ? false : _props$inner;

      var loadingStyle = {
        height: 'calc(100vh - 184px)',
        overflow: 'hidden'
      };
      return _react2.default.createElement(
        'div',
        {
          className: (0, _classnames3.default)(className, (0, _defineProperty3.default)({}, 'contentInner', inner)),
          style: loading ? loadingStyle : null
        },
        loading ? _react2.default.createElement(_Loader2.default, { spinning: true }) : '',
        children
      );
    }
  }]);
  return Page;
}(_react.Component);

exports.default = Page;


Page.propTypes = {
  className: _propTypes2.default.string,
  children: _propTypes2.default.node,
  loading: _propTypes2.default.bool,
  inner: _propTypes2.default.bool
};