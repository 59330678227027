import React, { Component } from 'react'
import { Page, EntityTable } from '@adkgroup/appkit-ui'
import { ACCEPTED_STATUS, ENTITY_STATUS } from '../../../../configs/app'

export default class EntityManagementScreen extends Component {
  state = { loading: null }
  render () {
    return (
      <Page
        className='table-container'
        loading={this.state.loading}
      >
        <EntityTable
          {...this.props}
          ACCEPTED_STATUS={ACCEPTED_STATUS}
          ENTITY_STATUS={ENTITY_STATUS}
          tableConfig={this.props.moduleConfig.tableTabs}
        />
      </Page>
    )
  }
}