'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (arr) {
  var queryFragment = '';
  arr.forEach(function (element) {
    queryFragment += element + ', ';
  });
  return queryFragment;
};