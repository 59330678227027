export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const SET_SITES = 'SET_SITES'
export const SET_STUDIES = 'SET_STUDIES'
export const SET_TRIALS = 'SET_TRIALS'
export const SET_STATUS_COUNTS = 'SET_STATUS_COUNTS'
export const CHANGE_APPNAV_OPENSTATE = 'CHANGE_APPNAV_OPENSTATE'
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT'
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY'
export const SET_APP_BUSY_STATE = 'SET_APP_BUSY_STATE'
export const SET_POPOVER_STATE = 'SET_POPOVER_STATE'
export const SET_SELECTED_SITE = 'SET_SELECTED_SITE'
export const SET_SELECTED_TRIAL = 'SET_SELECTED_TRIAL'
export const SET_SUBJECTS = 'SET_SUBJECTS'
export const ADD_NEW_TRIAL = 'ADD_NEW_TRIAL'
export const EDIT_TRIAL = 'EDIT_TRIAL'
export const ADD_NEW_SITE = 'ADD_NEW_SITE'
export const EDIT_SITE = 'EDIT_SITE'
export const SET_GROUPS = 'SET_GROUPS'
export const SET_SELECTED_TABLE_ROWS = 'SET_SELECTED_TABLE_ROWS'


export const SET_MY_PROFILE = 'SET_MY_PROFILE'
export const SET_PATIENTS = 'SET_PATIENTS'
export const SET_DASHBOARDUSERS = 'SET_DASHBOARDUSERS'
export const SET_PHYSICIANS = 'SET_PHYSICIANS'
export const SET_ROLES = 'SET_ROLES'
export const SET_COHORTS = 'SET_COHORTS'
export const SET_SURVEYS = 'SET_SURVEYS'
export const SET_APPLICATION = 'SET_APPLICATION'

export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS'
export const SET_TABLE_FILTER = 'SET_TABLE_FILTER'
export const RESET_TABLE_FILTER = 'RESET_TABLE_FILTER'
export const RESET_ALL_TABLE_FILTERS = 'RESET_ALL_TABLE_FILTERS'
export const SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE'
export const SET_TABLE_PAGE_NUMBER = 'SET_TABLE_PAGE_NUMBER'
export const SET_TABLE_PAGE_INFO = 'SET_TABLE_PAGE_INFO'
export const SET_TABLE_SORT = 'SET_TABLE_SORT'
export const SET_PATIENT_RESPONSES = 'SET_PATIENT_RESPONSES'
export const SET_ENTITY_PROFILE = 'SET_ENTITY_PROFILE'
export const SET_ENTITY_LOGIN_HISTORY = 'SET_ENTITY_LOGIN_HISTORY'

export const dataActionTypes = {
  dashboardUsers: SET_DASHBOARDUSERS,
  myProfile: SET_MY_PROFILE,
  patients: SET_PATIENTS,
  physicians: SET_PHYSICIANS,
  roles: SET_ROLES,
  cohorts: SET_COHORTS,
  surveys: SET_SURVEYS,
  studies: SET_STUDIES,
  subjects: SET_SUBJECTS,
  groups: SET_GROUPS,
  statusCounts: SET_STATUS_COUNTS,
  patientResponses: SET_PATIENT_RESPONSES,
  entityProfile: SET_ENTITY_PROFILE,
  entityLoginHistory: SET_ENTITY_LOGIN_HISTORY,
  application: SET_APPLICATION,
}