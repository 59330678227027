import columns from './columns'
import { initEntityData } from '../../../../../modules/EntityManagementModule/utils'
let fileDownload = require('js-file-download')

export default {
  title: 'Care Team',
  dataFilter: (data) => data,
  modelName: 'dashboardUser',
  onRowClick: async (props, rowIndex) => {
    const selectedDashboardUser = props.dashboardUsers[rowIndex]
    await initEntityData(props, selectedDashboardUser.guid, 'dashboardUsers')
    props.mHealthMain.dispatch.navigateTo(
      `/dashboardUsers/${selectedDashboardUser.guid}/profile`,
      props.location.pathname,
      { 
        entityType: 'dashboardUsers',
        entity: selectedDashboardUser 
      }
    )
  },
  scroll: true,
  columns,
  actions: (props) => ([
    {
      action: 'clearFilters',
      label: 'Clear Filters'
    },
    {
      action: 'export',
      className: 'study-team-table-export',
      // permissions: ['read_0_export', 'read_1_export'],
      label: 'Export Data',
      onClick: async () => {
        const exportRes = await props.mHealthMain.api.exportData('user')
        fileDownload(JSON.stringify(exportRes.data), 'study_team_export.json')
      }
    },
  ]),
}