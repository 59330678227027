import React, { PureComponent } from 'react'
import { Radio } from 'antd'

import './FormRadio.scss'

class FormRadio extends PureComponent {

  handleOnChange = (e) => {
    const { formRef } = this.props
    formRef.props.form.setFieldsValue({
      [this.props.id]: e.target.value
    })
  }

  render () {
    const { options, value, disabled } = this.props
    return (
      <Radio.Group
        value={ value || undefined }
        onChange={this.handleOnChange}
        className='form-radio'
        disabled={disabled}
      >
        {options.map((option, index) => (
          <Radio key={`${option.id}-${index}`} value={option.id}>{option.name}</Radio>
        ))}
      </Radio.Group>
    )
  }
}

export default FormRadio