import React from 'react'
import { initEntityData } from '../../utils'
import { EntityDetailForm, LoginHistory } from './components'
import './Profile.scss'

import { wipeTables } from '@/features/TreatmentTable/redux/createPatientActions'
import { Icon } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { Notification } from '@adkgroup/appkit-ui'

class ProfileScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      guid: null, 
      entityType: null
    };
  }

  UNSAFE_componentWillMount() {
    wipeTables(this.props.dispatch)
  }

  componentDidMount () {
    this.init()
  }

  init = async () => {
    const { location, entityProfile } = this.props
    const guid = location.payload
      ? location.payload.entity.guid
      : location.pathname.split('/')[2]
    const entityType = location.payload
      ? location.payload.entityType
      : location.pathname.split('/')[1]
    this.setState({ guid, entityType })
    if (entityProfile.guid !== guid) {
      await initEntityData(this.props, guid, entityType)
    }
  }

  renderResponseCTA = (configs) => {
    const { guid, entityType } = this.state
    return <div 
      className='response-cta-container'
      onClick={() => configs.onClick(entityType, guid)}
    >
      {configs.icon}
      <div className='response-cta-label'>{configs.label}</div>
    </div>
  }

  renderDeactivePatientCTA = () => {
    const { mHealthMain: { api, dispatch }, patients, entityProfile } = this.props
    const { guid } = this.state
    return <div
      className='deactivate-patient-cta-container'
      onClick={() => {
        dispatch.setModalContent({
          title: "Patient Status Change",
          content: "Clicking confirm will deactivate pending patient permanently",
          visible: true,
          onOk: async () => {
            try {
              await api.patchUser({ status: 'DISABLE' }, `/patient/${guid}`)
              dispatch.setData('entityProfile', {
                ...cloneDeep(entityProfile),
                status: 'DISABLE',
              })
  
              const patientIndex = findIndex(patients, p => p.guid === guid)
              if (patientIndex > -1) {
                let patientsCopy = cloneDeep(patients)
                patientsCopy[patientIndex] = {
                  ...entityProfile,
                  status: 'DISABLE',
                }
                dispatch.setData('patients', patientsCopy)
              }
              dispatch.setModalVisibility(false)
            } catch (error) {
              Notification({
                message: 'Failed to deactivate patient',
                description: 'Please try again later'
              })
              dispatch.setModalVisibility(false)
            }
          }
        })
      }}
    >
      <Icon className='deactivate-patient-cta-icon' type='user-delete' />
      <div className='deactivate-patient-cta-label'>Deactivate Patient</div>
    </div>
  }

  render() {
    const { moduleConfig, entityProfile, entityLoginHistory } = this.props
    const entity = entityProfile
    const configs = moduleConfig.profile(this.props)
    if (entity) {
      return (
        <div className='entity-profile-container'>
          <div className='entity-profile-top-container'>
            <div className='entity-profile-title'>{configs.title}</div>
            {entity.status === 'UNCONFIRMED' ? this.renderDeactivePatientCTA() : null}
            { configs.navCTA && this.renderResponseCTA(configs.navCTA)}
          </div>
          <div className='entity-profile-lower-container'>
            <EntityDetailForm
              { ...this.props }
              entity={entity}
              configs={configs.detailForm}
            />
            <LoginHistory
              { ...this.props }
              entity={entity}
              data={entityLoginHistory.history}
              configs={configs.loginHistoryTable}
            />
          </div>
        </div>
      );
    } else {
      return null
    }
  }
}

export default ProfileScreen;
