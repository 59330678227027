import React from 'react'
import { Icon } from 'antd'

import './TwoDashIcon.scss'

const TwoDashIcon = ({ className }) => {
  return (
    <Icon className={`o-two__dash__icon ${className ? className : null}`} type='pause' />
  )
}

export default TwoDashIcon