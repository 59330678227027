import * as types from './sharedTypes'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SHARED_UI_FIELD: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: return state
  }
}