'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queueScheduler = undefined;

var _queueScheduler = require('./queueScheduler');

var _queueScheduler2 = _interopRequireDefault(_queueScheduler);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.queueScheduler = _queueScheduler2.default;