import * as types from './types'

const INITIAL_STATE = {
  activeDomain: undefined,
  activeAnswers: undefined,
  meta: {
    loadingAnswers: false
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_OVERVIEW_TABLE_DATA: {
      return {
        ...state,
        activeDomain: action.payload,
        activeAnswers: null
      }
    }
    case types.GET_OVERVIEW_TABLE_ANSWERS_DATA_BEGIN: {
      return {
        ...state,
        activeAnswers: action.payload,
        meta: {
          ...state.meta,
          loadingAnswers: true
        }
      }
    }
    case types.GET_OVERVIEW_TABLE_ANSWERS_DATA_SUCCESS: {
      return {
        ...state,
        activeAnswers: action.payload,
        meta: {
          ...state.meta,
          loadingAnswers: false
        }
      }
    }
    case types.GET_OVERVIEW_TABLE_ANSWERS_DATA_FAILED: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAnswers: false
        }
      }
    }
    case types.WIPE_OVERVIEW_TABLE_ANSWERS: {
      return {
        ...state,
        activeAnswers: undefined
      }
    }
    case types.SET_OVERVIEW_TABLE_ANSWERS_DATA_WITH_EXTRA_INFO: {
      return {
        ...state,
        activeDomain: action.payload.domain,
        activeAnswers: action.payload.answersData,
        meta: {
          ...state.meta,
          loadingAnswers: false
        }
      }
    }
    case types.WIPE_OVERVIEW_TABLE: {
      return {
        ...state,
        activeDomain: undefined,
        activeAnswers: undefined
      }
    }
    default:
      return state
  }
}