'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (arr) {
  var argString = '';
  arr.forEach(function (obj) {
    var key = (0, _keys2.default)(obj)[0];
    argString += typeof obj[key] === 'string' ? key + ': "' + obj[key] + '", ' : Array.isArray(obj[key]) ? key + ': "[' + obj[key].join(', ') + ']", ' : key === 'phone' ? key + ': "+1" + ' + obj[key] + ', ' : key + ': ' + obj[key] + ', ';
  });
  return argString;
};