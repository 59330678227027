import React from 'react'
import { baseDataConfig } from './baseData'

export default (props) => ({
  baseDataRequests: baseDataConfig(props),
  login: {
    title: ''
  },
  logo: (
    <h3>
      <strong>
        mHealth Dashboard
      </strong>
    </h3>
  ),
})