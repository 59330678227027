'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InactivityTimer = function (_Component) {
  (0, _inherits3.default)(InactivityTimer, _Component);

  function InactivityTimer() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, InactivityTimer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = InactivityTimer.__proto__ || (0, _getPrototypeOf2.default)(InactivityTimer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      timerFinished: false,
      timer: undefined
    }, _this.onClick = function () {
      var _this$props = _this.props,
          onClickAfterTimer = _this$props.onClickAfterTimer,
          minToCheck = _this$props.minToCheck;
      var _this$state = _this.state,
          timer = _this$state.timer,
          timerFinished = _this$state.timerFinished;

      if (timerFinished) {
        onClickAfterTimer();
      } else {
        if (timer) clearTimeout(timer);
        var t = setTimeout(function () {
          _this.setState({
            timerFinished: true
          });
        }, 1000 * 60 * minToCheck);
        _this.setState({ timer: t });
      }
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(InactivityTimer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      addListenerMulti(document, EVENTS, this.onClick);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      removeListenerMulti(document, EVENTS, this.onClick);
    }
  }, {
    key: 'render',
    value: function render() {
      return null;
    }
  }]);
  return InactivityTimer;
}(_react.Component);

exports.default = InactivityTimer;


function addListenerMulti(el, s, fn) {
  s.split(' ').forEach(function (e) {
    return el.addEventListener(e, fn);
  });
}

function removeListenerMulti(el, s, fn) {
  s.split(' ').forEach(function (e) {
    return el.removeEventListener(e, fn);
  });
}

var EVENTS = 'mousemove keydown wheel DOMMouseScroll mouseWheel mousedown touchstart touchmove MSPointerDown MSPointerMove';