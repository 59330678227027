'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import { Form } from '../../dataEntry'

// class ResetPassEmail extends Component {
//   state = {  }

//   handleSubmit = (e) => {
//     e.preventDefault();
//     this.props.form.validateFields(async (err, values) => {
//       if (!err) {
//         await this.props.mHealthMain.api.initiateReset(values.userName)
//         this.props.mHealthMain.dispatch.navigateTo('/auth/login')
//       }
//     });
//   }

//   formConfig = () => {
//     return [
//       {
//         type: 'custom',
//         content: this.props.APPCONFIG.LOGIN_TITLE && <h2>{this.props.APPCONFIG.LOGIN_TITLE}</h2>,
//       },
//       {
//         type: 'custom',
//         content: this.props.APPCONFIG.LOGO && <div className={'login-logo'}>
//           <img alt="logo" src={this.props.APPCONFIG.LOGO} />
//         </div>,
//       },
//       {
//         type: 'custom',
//         content: <div className={'reset-email-description'}>
//           Your password has expired. Please enter your email below to trigger a new password reset email notification
//         </div>,
//       },
//       {
//         type: 'text',
//         label: 'Username',
//         id: 'email',
//         required: false,
//         colon: false,
//         validateStatus: true,
//         rules: [
//           { required: true, message: 'Please input your username!' },
//           {
//             pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
//             message: 'Please enter a valid e-mail'
//           }
//         ],
//       },
//       {
//         type: 'submit',
//         id: 'submit',
//         label: 'Retrieve Password',
//         buttonType: 'primary',
//         className: 'login-form-button',
//         disableCheck: true,
//       }
//     ]
//   }


//   render() {
//     const { getFieldDecorator } = this.props.form;
//     return (
//       <Form onSubmit={this.handleSubmit} className="login-form">
//         <h2>{this.props.APPCONFIG.LOGIN_TITLE}</h2>
//         <h4>Your password has expired. Please enter your username below.</h4>
//         <Form.Item>
//           {getFieldDecorator('userName', {
//             rules: [{ required: true, message: 'Please input your username!' }],
//           })(
//             <Input onChange={event => this.setState({ username: event.target.value })} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
//           )}
//         </Form.Item>
//         <Button
//           label="Send Reset Instruction Email
//           </Button>"
//           type="primary"
//           htmlType="submit"
//           className="login-form-button"
//         />
//       </Form>
//     )
//   }

//   render() {
//     return (
//       <Form
//         className="login-form"
//         onSubmit={this.onSubmit}
//         configs={this.formConfig()}
//       />
//     )
//   }
// }

// export default Form.create()(ResetPassEmail)

exports.default = function () {
  return _react2.default.createElement(
    'div',
    null,
    'hi'
  );
};