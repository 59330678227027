'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoles = undefined;

var _getRoles = require('./getRoles');

var _getRoles2 = _interopRequireDefault(_getRoles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.getRoles = _getRoles2.default;