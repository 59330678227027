import { Notification } from '@adkgroup/appkit-ui'
import find from 'lodash/find'
import get from 'lodash/get'

import { getRoleOptions, getRoute, getRole } from './createHelpers'
import { improveAxios, buildPostConfig, buildRequestConfig } from '../../../../utils/axios/helpers'

export default (props) => {
  return {
    title: "Add a Care Team Member",
    formConfig: ref => [
      { 
        // title: 'Section Title',
        // className: 'custom',
        fields: [
          {
            type: 'text',
            label: 'First Name',
            id: 'firstName',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your first name!' }],
          },
          {
            type: 'text',
            label: 'Last Name',
            id: 'lastName',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your last name!' }],
          },
          {
            type: 'text',
            label: 'Email Address',
            id: 'email',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [
              { required: true, message: 'Please input your e-mail!' },
              { pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Valid e-mail is required' }
            ],
          },
          {
            type: 'mask',
            label: 'Office Number',
            placeholder: '000-000-0000',
            mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            id: 'officeNumber',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [
              { required: true, message: 'Please input a phone number!' },
              { pattern: /^[0-9]\d{2}-\d{3}-\d{4}$/, message: 'Valid phone number is required' }
            ],
          },
          {
            type: 'select',
            label: 'Role',
            id: 'role',
            // Requires .add permission for each role, ex: { physician: physican.add }
            permissibleActions: Object.keys(props.roles)
              .filter(r => r !== 'Patient')  
              .reduce((result, item) => {
                result[item] = `${item.toLowerCase()}.add`
                return result
              }, {}),
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please select a role!' }],
            dropdownClassName: 'create-entity-select-dropdown create-entity-select-dropdown__role',
            validateTrigger: 'onChange',
            options: Object.values(props.roles)
              .filter(r => r.name !== 'Patient' && r.name !== 'PhysicianDelegate')
              .map(r => {
                return {
                  id: r.id,
                  name: r.name.split(/(?=[A-Z])/).join(' '),
                  pascalName: r.name,
                }
              }),
          },
          {
            type: 'select',
            label: 'Specialty',
            id: 'specialty',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please select a specialty!' }],
            dropdownClassName: 'create-entity-select-dropdown create-entity-select-dropdown__physician',
            dependent: 'role',
            dependentValue: [props.roles.Physician.id],
            validateTrigger: 'onChange',
            options: props.sharedUIReducer.doctorSpecialties.map((specialty) => ({
              id: specialty,
              name: specialty
            }))
          },
          {
            type: 'text',
            label: 'Profile URL',
            id: 'profileURL',
            required: true,
            colon: false,
            validateStatus: true,
            dependent: 'role',
            dependentValue: [props.roles.Physician.id],
            validateTrigger: 'onChange',
            rules: [
              { required: true, message: 'Please provide a profile url!' },
              { pattern: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/, message: 'Valid profie URL is required!' }
            ],
          },
          {
            type: 'checkbox',
            label: 'Permissions',
            id: 'permissions',
            dependent: 'role',
            dependentValue: [props.roles.PhysicianDelegate.id], // Physician delegate role id
            options: [{ 
              label: 'Ability to Edit',
              value: props.roles.PhysicianDelegate.permissions
                .filter(p => !p.isDefault)
                .map(p => p.id)
            }],
            buttonType: 'primary',
            className: 'create-entity-permissions',
            disableCheck: true,
          },
          {
            type: 'upload-photo',
            label: 'Profile Photo',
            id: 'profilePhotoUrl',
            required: false,
            colon: false,
            buttonIcon: 'upload',
            buttonLabel: 'Upload Image',
            buttonClassName: 'create-entity-upload-photo-button',
            modalTitle: 'Upload Profile Photo',
            modalOkText: 'Upload',
            onUpload: async (file) => {
              const { mHealthMain: { api } } = props
              let s3Key
              try {
                // Assemble form data payload
                const payload = new FormData()
                payload.append('file', file)
                // Post image to S3 and receive S3 public url back
                const S3Response = await api.uploadProfilePhotoToS3(payload)
                const profilePhotoUrl = get(S3Response, ['data', 'valueUri'], null)
                if (profilePhotoUrl) {
                  // Set key for error handling cleanup
                  s3Key = profilePhotoUrl.replace(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`, '')
                  // Set form field value
                  ref.props.form.setFieldsValue({ ["profilePhotoUrl"]: profilePhotoUrl })
                } else {
                  throw 'error'
                }
              } catch (error) {
                Notification({
                  message: "Profile photo upload failed",
                  description: "Please try again later"
                })
                await api.deleteProfilePhotoFromS3(s3Key)
              }
            }
          },
          {
            type: 'checkbox',
            label: 'Permissions',
            id: 'permissions',
            dependent: 'role',
            dependentValue: ['PhysicianDelegate'],
            options: [{ name: 'patient.add', id: 'patient.add' }, { name: 'patient.edit', id: 'patient.edit' } ],
            buttonType: 'primary',
            className: 'create-entity-permissions',
            disableCheck: true,
            style: { flexBasis: '100%' }
          },
          {
            type: 'submit',
            label: 'Create Member',
            id: 'submit',
            buttonType: 'primary',
            className: 'create-entity-button',
            disableCheck: true,
            style: { flexBasis: '100%' }
          }
        ],
      }
    ],
    onSubmit: async values => {
      const { myProfile, physicians, roles, mHealthMain: { api, dispatch } } = props
      let payload = {
        email: values.email,
        attributes: [
          {
            name: "name",
            value: values.firstName
          },
          {
            name: "family_name",
            value: values.lastName
          },
        ],
        profilePhotoUrl: values.profilePhotoUrl
      }
      try {
        const roleObj = find(Object.values(roles), r => r.id === values.role)
        const route = roleObj ? roleObj.route : null
        // Verify route is valid
        if (!route) {
          Notification({
            message: 'Error in selecting a valid user role.',
            description: 'Please try again later.'
          })
          throw ('fetchError')
        }

        // Add physician delegate's assigned physcian to payload
        if (route === 'admin') {
          const group = find(roles, r => r.id === values.role)
          // Add group to separate superadmin vs admin in Cognito
          payload = {
            ...payload,
            group: (group && group.name) || '',
          }
        } else if (route === 'physician/delegate') {
          let assignedPhysician
          if (myProfile.role.name === 'PhysicianDelegate') {
            assignedPhysician = physicians.find(p => p.username === myProfile.physicianUsername)
          } else {
            assignedPhysician = physicians.find(p => p.id === values.physician)
          }
          if (assignedPhysician === undefined) {
            Notification({
              message: 'Unable to find physician',
              description: 'Please try again later'
            })
            throw ('fetchError')
          } else {
            payload = {
              ...payload,
              parentPhysicianUsername: assignedPhysician.username,
            }
            const postUserResponse = await api.postUser(payload, route)
            if (values.permissions) {
              await api.patchUserPermissions(postUserResponse.data.username, {
                permissionIds: values.permissions[0],
              })
            }
          }
        }
        
        if (route !== 'physician/delegate') {
          const userDetailPayload = values.role === 2 // New user === Physician?
            ? {
              specialty: get(values, 'specialty', null),
              profileUrl: get(values, 'profileURL', null),
              officeNumber: values.officeNumber
            }
            : {
              specialty: null,
              profileUrl: null,
              officeNumber: values.officeNumber
            }
          const response = await api.postUser(payload, route)
          const userDetailReqConfig = buildPostConfig(`studyTeam/${response.data.user.username}/details`, 'post', userDetailPayload)
          await improveAxios.request(userDetailReqConfig)
          const cohortResponse = await props.mHealthMain.api.getCohorts()
          props.mHealthMain.dispatch.setData(
            'cohorts',
            cohortResponse.data
          )
        }
        Notification({
          message: 'New Study Team Member account created successfully.',
          description: 'Please complete account creation by confirming your email address.'
        })
        await dispatch.refetchEntities(props, 'dashboardUser')
        dispatch.navigateTo('/dashboardUsers')
      } catch (error) {
        if (get(error, ['response', 'status'], undefined) === 409) {
          Notification({
            message: 'Failed to add new user',
            description: 'An account with with the given email already exists'
          })
        } else if (error !== 'fetchError') {
          Notification({
            message: 'Failed to add new user',
            description: 'Cannot find assigned physician, please notify admin'
          })
        } else {
          Notification({
            message: 'Failed to add new user',
            description: 'Please try again later'
          })
        }
      }
    }
  }
}