import React from 'react'
import { Notification } from '@adkgroup/appkit-ui'
import { patchDiff } from '@adkgroup/appkit-utils'
import { ENTITY_STATUS } from '../../../app'
import ResponseIcon from '../../../assets/nav-to-response.svg'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import omit from 'lodash/omit'

import { improveAxios } from '../../../../utils/axios/helpers'
import { FormRadio, FormCheckbox, FormTime } from '@/shared/components'
import TreatmentTable from '../../../../features/TreatmentTable/components/TreatmentTable'
import { createPatientDetailsConfig } from './participantProfileHelpers'

export default (props) => ({
  title: "Patient Profile",
  navCTA: {
    icon: <img className='response-cta-icon' alt='patient-profile' src={ResponseIcon} />,
    label: "View Patient Responses",
    onClick: (entityType, guid) => {
      const { mHealthMain, location } = props
      mHealthMain.dispatch.navigateTo(
        `/${entityType}/${guid}/response`,
        location.pathname
      )
    }
  },
  detailForm: {
    permissions: ['patient.edit'],
    title: "Patient Details",
    toggleStatus: async (props, entity) => {
      const { mHealthMain: { api, dispatch }, patients } = props
      if (entity.status !== "PENDING") {
        dispatch.setModalContent({
          title: "Patient Status Change",
          content: "Clicking confirm will notify the patient to let them know their current account status. ",
          visible: true,
          onOk: async () => {
            try {
              const newStatus = entity.status === "DISABLE" ? "CONFIRMED" : "DISABLE"
              await api.patchUser({ status: newStatus }, `/patient/${entity.guid}`)
              dispatch.setData('entityProfile', {
                ...cloneDeep(entity),
                status: newStatus,
              })
              
              const patientIndex = findIndex(patients, p => p.guid === entity.guid)
              if (patientIndex > -1) {
                let patientsCopy = cloneDeep(patients)
                patientsCopy[patientIndex] = {
                  ...entity,
                  status: newStatus,
                }
                dispatch.setData('patients', patientsCopy)
              }
              dispatch.setModalVisibility(false)
            } catch (error) {
              Notification({
                message: `Failed to ${entity.status === "DISABLE" ? 'activate' : 'deactivate'} patient`,
                description: 'Please try again later'
              })
              dispatch.setModalVisibility(false)
            }
          }
        })
      } else {
        Notification({
          message: `Patient is pending`,
          description: 'Please try again later'
        })
      }
    },
    ENTITY_STATUS,
    patchForm: true,
    formConfig: (ref) => [
      { 
        // title: 'Section Title',
        // className: 'custom',
        fields: [
          {
            type: 'text',
            label: 'First Name',
            id: 'firstName',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your first name!' }],
          },
          {
            type: 'text',
            label: 'Last Name',
            id: 'lastName',
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please input your last name!' }],
          },
          {
            type: 'text',
            label: 'MRN',
            placeholder: '111-1111',
            id: 'mrn',
            colon: false,
          },
          {
            type: 'date',
            label: 'Date of Birth',
            placeholder: 'Select Date',
            id: 'dateOfBirth',
            initialValueTransform: value => value && moment(value),
            required: true,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please enter a date of birth!' }],
            dropdownClassName: 'edit-entity-calendar-picker-container edit-entity-calendar-picker-container__date-of-birth',
            format: 'YYYY-MM-DD',
            disabledDate: current => current > moment().endOf('day'),
            allowClear: true,
            showToday: true,
            validateTrigger: 'onChange'
          },
          {
            type: 'mask',
            label: 'Phone Number',
            placeholder: '617-555-0123',
            mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            id: 'phone',
            initialValueTransform: value => value && value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6),
            required: true,
            colon: false,
            validateStatus: true,
            rules: [
              { required: true, message: 'Please input your phone number!' },
              { pattern: /^[0-9]\d{2}-\d{3}-\d{4}$/, message: 'Valid phone number is required' }
            ],
            disabled: true,
          },
          {
            type: 'text',
            label: 'Email Address',
            id: 'emailAddress',
            required: false,
          },
          {
            type: 'customFormItem',
            label: 'Preferred Contact Method',
            id: 'preferredContactMethod',
            required: true,
            colon: false,
            rules: [
              { required: true, message: 'Please select a contact method!' },
            ],
            content: (props) => <FormCheckbox 
              disabled={get(props, ['entityProfile', 'status'], 'UNCONFIRMED') !== "CONFIRMED" }
              formRef={ref}
              options={[
                { id: 'Text', name: 'Text' },
                { id: 'Email', name: 'Email' },
                { id: 'Phone', name: 'Phone' },
              ]}
              {...props} 
            />,
          },
          {
            type: 'customFormItem',
            label: 'Permission To Text',
            id: 'permissionToText',
            required: true,
            colon: false,
            rules: [
              { required: true, message: 'Please select if allowed to text!' },
            ],
            content: (props) => <FormRadio 
              disabled={get(props, ['entityProfile', 'status'], 'UNCONFIRMED') !== "CONFIRMED" }
              formRef={ref}
              options={[
                { id: 'yes', name: 'Yes' },
                { id: 'no', name: 'No' },
              ]}
              {...props} 
            />,
          },
          {
            type: 'select',
            formType: 'multi-select',
            label: 'Care Team Members',
            placeholder: 'Select Care Team Members',
            id: 'physicianIds',
            required: false,
            colon: false,
            validateStatus: true,
            rules: [{ required: true, message: 'Please select a care team member!' }],
            dropdownClassName: 'edit-entity-select-dropdown edit-entity-select-dropdown__physician',
            options: props.physicians.map(p => {
              return {
                id: p.id,
                name: `${p.firstName} ${p.lastName}`,
              }
            })
          },
          {
            type: 'upload-photo',
            label: 'Profile Photo',
            id: 'profilePhotoUrl',
            required: false,
            colon: false,
            buttonIcon: 'upload',
            buttonLabel: 'Upload',
            buttonClassName: 'edit-entity-upload-photo-button',
            modalTitle: 'Upload Profile Photo',
            modalOkText: 'Upload',
            onUpload: async (file) => {
              const { mHealthMain: { api, dispatch }, entityProfile, patients } = props
              let s3Key
              try {
                // Assemble form data payload
                const payload = new FormData()
                payload.append('file', file)
                // Post image to S3 and receive S3 public url back
                const S3Response = await api.uploadProfilePhotoToS3(payload)
                const profilePhotoUrl = get(S3Response, ['data', 'valueUri'], null)
                if (profilePhotoUrl) {
                  // Set key for error handling cleanup
                  s3Key = profilePhotoUrl.replace(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`, '')
                  // Set form field value
                  ref.props.form.setFieldsValue({ ["profilePhotoUrl"]: profilePhotoUrl })
                  // Patch user and set state (patient and patients)
                  await api.patchUser({ profilePhotoUrl }, `/patient/${entityProfile.guid}`)
                  const patientIndex = findIndex(patients, p => p.guid === entityProfile.guid)
                  const updatedPatient = {
                    ...cloneDeep(entityProfile),
                    profilePhotoUrl,
                  }
                  dispatch.setData('entityProfile', updatedPatient)
                  if (patientIndex > -1) {
                    let patientsCopy = cloneDeep(patients)
                    patientsCopy[patientIndex] = updatedPatient
                    dispatch.setData('patients', patientsCopy)
                  }
                } else {
                  throw 'error'
                }
              } catch (error) {
                Notification({
                  message: "Profile photo upload failed",
                  description: "Please try again later"
                })
                await api.deleteProfilePhotoFromS3(s3Key)
              }
            }
          },
          {
            type: 'date',
            label: 'Date of Next Clinical Appointment',
            placeholder: 'Select Date',
            id: 'dateOfNextAppointment',
            required: false,
            validateStatus: true,
            dropdownClassName: 'edit-entity-calendar-picker-container edit-entity-calendar-picker-container__date-of-appointment',
            format: 'YYYY-MM-DD',
            allowClear: true,
            showToday: false,
            validateTrigger: 'onChange',
            disabledDate: current => current < moment().endOf('day'),
          },
          {
            type: 'customFormItem',
            label: 'Time of Next Clinical Appointment',
            id: 'timeOfNextAppointment',
            required: false,
            colon: false,
            content: (props) => <FormTime 
              disabled={get(props, ['entityProfile', 'status'], 'UNCONFIRMED') !== "CONFIRMED" }
              formRef={ref}
              placeholder='Select Time' 
              {...props}
            />,
          },
          // {
          //   type: 'select',
          //   label: 'Location of Next Clinical Appointment',
          //   placeholder: 'Select Location',
          //   id: 'locationOfNextAppointment',
          //   required: false,
          //   colon: false,
          //   validateStatus: true,
          //   dropdownClassName: 'edit-entity-location-picker-container',
          //   options: ['there', 'here', 'somewhere'].map((location) => ({
          //     id: location,
          //     name: location
          //   })),
          //   validateTrigger: 'onChange'
          // },
        ]
      },
      {
        title: 'Active Surgery',
        className: 'profile-subsection',
        fields: [
          {
            type: 'custom',
            content: <TreatmentTable
              tableName='activeSurgeryTable'
              mHealthDispatch={props.dispatch}
              mHealthMain={props.mHealthMain}
              disabled={props.entityProfile.status !== "CONFIRMED" }
              isProfileTable
              columns={
                [
                  {
                    title: 'Surgery Type',
                    dataKey: 'activeSurgeryType',
                    key: 'activeSurgeryType',
                    editable: true,
                    width: '33%',
                    type: 'select',
                    options: props.sharedUIReducer.surgeryTypes.map((st) => ({
                      id: st,
                      name: st.charAt(0).toUpperCase() + st.slice(1)
                    }))
                  },
                  {
                    title: 'Surgery Date',
                    key: 'activeSurgeryDate',
                    dataKey: 'activeSurgeryDate',
                    editable: true,
                    type: 'date'
                  }
                ]
              }
              dataObject={
                [
                  {
                    key: 0,
                    activeSurgeryType: '',
                    activeSurgeryDate: ''
                  },
                ]
              }
            />,
          }
        ]
      },
      {
        title: 'Previous Surgeries',
        className: 'profile-subsection',
        fields: [
          {
            type: 'custom',
            content: <TreatmentTable 
              mHealthDispatch={props.dispatch}
              mHealthMain={props.mHealthMain}
              disabled={props.entityProfile.status !== "CONFIRMED" }
              isProfileTable
              tableName='previousSurgeryTable'
              columns={
                [
                  {
                    title: 'Surgery Type',
                    dataKey: 'previousSurgeryType',
                    key: 'previousSurgeryType',
                    editable: true,
                    width: '33%',
                    type: 'select',
                    options: props.sharedUIReducer.surgeryTypes.map((st) => ({
                      id: st,
                      name: st.charAt(0).toUpperCase() + st.slice(1)
                    }))
                  },
                  {
                    title: 'Surgery Date',
                    dataKey: 'previousSurgeryDate',
                    width: '33%',
                    key: 'previousSurgeryDate',
                    disabledDate: current => current > moment().endOf('day'),
                    editable: true,
                    type: 'date'
                    
                  }
                ]
              }
              dataObject={
                [
                  {
                    key: 0,
                    previousSurgeryType: '',
                    previousSurgeryDate: ''
                  },
                ]
              }
              canAddNewRow
              canRemoveRow
              deleteLabel='Remove'
              buttonLabel='Add Previous Surgery'
            />,
          }
        ]
      },
      {
        title: 'Active Treatments',
        className: 'profile-subsection',
        fields: [
          {
            type: 'custom',
            content: <TreatmentTable 
              mHealthDispatch={props.dispatch}
              mHealthMain={props.mHealthMain}
              disabled={props.entityProfile.status !== 'CONFIRMED' }
              isProfileTable
              tableName='activeTreatmentTable'
              columns={
                [
                  {
                    title: 'Treatment',
                    dataKey: 'activeTreatment',
                    key: 'activeTreatment',
                    editable: true,
                    width: '33%',
                    type: 'select',
                    options: props.sharedUIReducer.treatmentTypes.map((tt) => ({
                      id: tt,
                      name: tt.charAt(0).toUpperCase() + tt.slice(1)
                    }))
                  },
                  {
                    title: 'Treatment Dates',
                    dataKey: 'activeTreatmentDates',
                    width: '50%',
                    key: 'activeTreatmentDates',
                    editable: true,
                    type: 'daterange'
                    
                  }
                ]
              }
              dataObject={
                [
                  {
                    key: 0,
                    activeTreatment: '',
                    activeTreatmentDates: ''
                  },
                ]
              }
              canAddNewRow
              canRemoveRow
              deleteLabel='Remove'
              buttonLabel='Add Active Treatment'
            />,
          }
        ]
      },
      {
        title: 'Previous Treatments',
        className: 'profile-subsection',
        fields: [
          {
            type: 'custom',
            content: <TreatmentTable
              mHealthDispatch={props.dispatch}
              mHealthMain={props.mHealthMain}
              disabled={props.entityProfile.status !== 'CONFIRMED' }
              isProfileTable
              tableName='previousTreatmentTable'
              columns={
                [
                  {
                    title: 'Treatment',
                    dataKey: 'previousTreatment',
                    key: 'previousTreatment',
                    width: '33%',
                    editable: true,
                    type: 'select',
                    options: props.sharedUIReducer.treatmentTypes.map((tt) => ({
                      id: tt,
                      name: tt.charAt(0).toUpperCase() + tt.slice(1)
                    }))
                  },
                  {
                    title: 'Treatment Dates',
                    dataKey: 'previousTreatmentDates',
                    width: '50%',
                    key: 'previousTreatmentDates',
                    disabledDate: current => current > moment().endOf('day'),
                    editable: true,
                    type: 'daterange'
                  }
                ]
              }
              dataObject={
                [
                  {
                    key: 0,
                    previousTreatment: '',
                    previousTreatmentDates: ''
                  },
                ]
              }
              canAddNewRow
              canRemoveRow
              deleteLabel='Remove'
              buttonLabel='Add Previous Treatment'
            />,
          },
          {
            type: 'submit',
            label: 'Update',
            id: 'submit',
            buttonType: 'primary',
            className: 'edit-entity-button',
            disableCheck: true,
            style: { flexBasis: '100%' }
          }
        ]
      }
    ],
    onSubmit: async (values, entity) => {
      values = {
        ...values,
        dateOfBirth: values.dateOfBirth && values.dateOfBirth.format("YYYY-MM-DD"),
        phone: values.phone && values.phone.replace(/-/g, ''),
        mrn: values.mrn && values.mrn.replace(/-/g, ''),
      }
      let patchKeys = patchDiff(entity, values)
      patchKeys = omit([
        ...patchKeys
      ], ['permissionToText', 'preferredContactMethod', 'timeOfNextAppointment'])
      patchKeys = Object.values(patchKeys)
      const { mHealthMain: { api, dispatch }, entityProfile, patients } = props
      dispatch.setModalContent({
        visible: true,
        width: '50%',
        title: "Patient Profile Update",
        content: "Saving these changes will be reflected on the patients profile within the app. This will trigger a text message notification to the patient letting them know changes have been made to their profile. ",
        onOk: async () => {
          let payload = {}
          if (patchKeys && patchKeys.length) {
            patchKeys.forEach(key => {
              if (key === 'firstName') {
                payload['name'] = values.firstName
              } else if (key === 'phone') {
                payload['phoneNumber'] = '+1' + values.phone.replace(/-/g, '')
              } else if (key === 'mrn') {
                payload[key] = values.mrn.replace(/-/g, '')
              } else if (key === 'cohorts') {
                payload[key] = values.cohorts.map(str => parseInt(str, 10))
              } else if (key === 'physicians') {
                payload['physicianIds'] = values.physicians.map(str => parseInt(str, 10))
              } else {
                payload[key] = values[key]
              }
            })
          }
          try {
            const tables = {
              'activeSurgeryTable': entityProfile.activeSurgeryTable,
              'previousSurgeryTable': entityProfile.previousSurgeryTable,
              'previousTreatmentTable': entityProfile.previousTreatmentTable,
              'activeTreatmentTable': entityProfile.activeTreatmentTable,
            }
            const config = createPatientDetailsConfig(values, tables, entity.guid, entity)
            await api.patchUser(payload, `/patient/${entity.guid}`)
            await improveAxios.request(config)
            payload = {
              ...payload,
              surgeryType: entity.activeSurgeryTable[0].activeSurgeryType,
              surgeryDate: moment(entity.activeSurgeryTable[0].activeSurgeryDate),
              nextPROMDate: entity.nextPROMDate
            }
            const patientIndex = findIndex(patients, p => p.guid === entity.guid)
            dispatch.setData('entityProfile', {
              ...cloneDeep(entityProfile),
              ...payload,
            })

            if (patientIndex > -1) {
              let patientsCopy = cloneDeep(patients)
              patientsCopy[patientIndex] = {
                ...entity,
                ...payload
              }
              dispatch.setData('patients', patientsCopy)
            }
            Notification({ message: 'Patient details updated' })
            dispatch.setModalVisibility(false)
          } catch (error) {
            Notification({
              message: 'Failed to edit patient',
              description: 'Please verify all information is entered correctly'
            })
          }
        }
      })
      dispatch.setModalVisibility(true)
    }
  },
  loginHistoryTable: {
    title: "Login History",
    tableConfigs: [
      {
        title: "Date",
        key: 'date',
        dataIndex: 'date',
        align: 'left',
      },
      {
        title: "Time",
        key: 'time',
        dataIndex: 'time',
        align: 'left',
      },
    ],
    goToPage: async (entity, pageNumber) => {
      try {
        const { mHealthMain: { api, dispatch }, entityLoginHistory } = props
        const response = await api.getLoginHistory(
          '/patient/loginHistory',
          entity.guid,
          15,
          pageNumber,
        )
        if (get(response, ['data', 'history'], []).length > 0) {
          const formattedData = response.data.history.map(datum => {
            const momentObjInUTC = moment.utc(datum)
            return {
              date: momentObjInUTC.local().format("MMMM D, YYYY"),
              time: momentObjInUTC.local().format("hh:mm:ss")
            }
          })
          dispatch.setData(
            'entityLoginHistory',
            { 
              history: entityLoginHistory.history.concat(formattedData), 
              pagination: response.data.paging
            }
          )
        } else {
          throw (response)
        }
      } catch (error) {
        Notification({
          message: 'Failed to load patient login history',
          description: 'Please try again later'
        })
      }
    }
  }
})