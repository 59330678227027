import React from 'react'
import { Icon } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'
import get from 'lodash/get'

import './PatientGraphHeader.scss'

const PatientGraphHeader = ({ surveyName, hasAnswers, mhealthDispatch, shouldClearOnBack, surveyAnswerDate }) => {

  const handleEnterPress = (event) => {
    if (event.keyCode === 13) handleButtonClick()
    else return
  }

  const handleButtonClick = () => {
    if (shouldClearOnBack) {
      mhealthDispatch.wipeOverviewTable()
    } else {
      mhealthDispatch.wipeOverviewTableAnswers()
    }
  }

  const renderSubtitle = () => {
    if (!surveyName.includes('BREAST-Q')) return null
    else {
      if (!hasAnswers) {
        return (
          <p className='patient-overview-graph__header__info--subtitle'>Higher scores reflect a better outcome</p>
        )
      } else return null
    }
  }
  
  return (
    <div className='patient-overview-graph__header'>
      <div className='patient-overview-graph__header__info'>
        <h3 className='patient-overview-graph__header__info--title'>{surveyName}</h3>
        {surveyAnswerDate ? <p className='patient-overview-graph__header__info--subtitle'>Completion Date: {moment(surveyAnswerDate).format('LL')}</p> : null }
        {renderSubtitle()}
      </div>
      {
        hasAnswers ? (
          <div tabIndex='0' onKeyPress={handleEnterPress} onClick={handleButtonClick} className='patient-overview-graph__header__back'>
            <Icon className='patient-overview-graph__header__back__icon' type='arrow-left' />
            <span className='patient-overview-graph__header__back__text'>Back</span>
          </div>
        ) : null
      }
    </div>
  )
}

const mapStateToProps = ({ overviewReducer }) => {
  const { activeDomain, activeAnswers } = overviewReducer
  const surveyAnswerDate = get(activeAnswers, 'date', null)
  const shouldClearOnBack = get(activeDomain, 'clearOnBack', false)
  return { shouldClearOnBack, surveyAnswerDate }
}

export default connect(mapStateToProps)(PatientGraphHeader)