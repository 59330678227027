import get from 'lodash/get'

import { buildPostConfig } from '@/utils/axios/helpers'

export const createStudyTeamDetailsConfig = (values, username) => {
  return buildPostConfig(`studyTeam/${username}/details`, 'put', {
    profileUrl: get(values, 'profileUrl', ''),
    officeNumber: get(values, 'officeNumber', ''),
    specialty: get(values, 'specialty', '')
  })
}