import React, { createContext, PureComponent } from 'react'
import { Input, Select, DatePicker } from 'antd'
import moment from 'moment'
import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'

const { Option } = Select;
const { RangePicker } = DatePicker


const TreatmentRow = ({ ...props }) => {
  return (
    <tr {...props} />
  )
}

class EditableCell extends PureComponent {
  constructor (props) { 
    super(props)
    this.state = {
      editing: true
    }
  }

  handleOnChange = (e, extraInfo) => {
    const { handleInputChange, record, dataKey } = this.props
    handleInputChange(e, record.key, dataKey, extraInfo)
  }


  renderInputMethod = () => {
    const { 
      inputType,
      selectOptions,
      key,
      disabledDate,
      record,
      dataKey,
      isDisabled
    } = this.props
    switch (inputType.toLowerCase()) {
      case 'select': {
        return <Select
          key={uuidv4()}
          size='large'
          className='table-select'
          onChange={(e) => this.handleOnChange(e, null)}
          value={record[dataKey]}
          disabled={isDisabled}
          dropdownMatchSelectWidth={false}
        >
          {
            selectOptions && selectOptions.map((option, index) => (
              <Option key={`${option.name}-${index}-${uuidv4()}`} value={option.id}>{option.name}</Option>
            ))
          }
        </Select>
      }
      case 'date': {
        return <DatePicker
          key={`date-picker-${uuidv4()}`}
          onChange={(e) => this.handleOnChange(e, null)}
          size='large'
          className='table-date'
          placeholder='Select Date'
          format='YYYY-MM-DD' 
          disabledDate={disabledDate}
          value={record[dataKey]}
          disabled={isDisabled}
        />
      }
      case 'daterange': {
        const startDate = get(record, [dataKey, 0], undefined)
        const endDate = get(record, [dataKey, 1], undefined)
        return (
          <div className='table-range-date-container'>
            <DatePicker 
              key={`date-start-${uuidv4()}`}
              onChange={(e) => this.handleOnChange(e, { type: 'firstDate' })}
              size='large'
              placeholder='Start date'
              className='table-range-date-input'
              format='YYYY-MM-DD' 
              disabledDate={disabledDate}
              value={startDate}
              disabled={isDisabled}
            />
            <DatePicker 
              key={`date-end-${uuidv4()}`}
              onChange={(e) => this.handleOnChange(e, { type: 'secondDate' })}
              size='large'
              className='table-range-date-input'
              format='YYYY-MM-DD' 
              placeholder='End date'
              disabledDate={disabledDate}
              value={endDate}
              disabled={isDisabled}
            />
          </div>
        )
      }
      default: {
        return <Input 
          key={`text-input-${uuidv4()}`}
          onChange={(e) => this.handleOnChange(e.target.value, null)}
          size='large'
          className='table-input'
          value={record[dataKey]}
          disabled={isDisabled}
        />
      }
    }
  }


  render () {
    const {
      editable,
      record,
      index,
      children,
      className,
      onClick
    } = this.props
    return (
      <td className={className} onClick={onClick}>
        {editable ? (
          this.renderInputMethod()
        ) : children}
      </td>
    )
  }
}

export { TreatmentRow }
export default EditableCell