import React from 'react'
import { AntTable } from '@adkgroup/appkit-ui'
import get from 'lodash/get'

class AttemptModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { data, configs, record, mHealthMain } = this.props
    return (
      <AntTable
        data={get(data, ['attempts', 'items'], null) ? data.attempts.items : []}
        configs={configs}
        className='response-attempt-table'
        pagination={false}
      />
    )
  }
}

export default AttemptModal