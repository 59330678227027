'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactTextMask = require('react-text-mask');

var _reactTextMask2 = _interopRequireDefault(_reactTextMask);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MaskInput = function (_React$Component) {
  (0, _inherits3.default)(MaskInput, _React$Component);

  function MaskInput() {
    (0, _classCallCheck3.default)(this, MaskInput);
    return (0, _possibleConstructorReturn3.default)(this, (MaskInput.__proto__ || (0, _getPrototypeOf2.default)(MaskInput)).apply(this, arguments));
  }

  (0, _createClass3.default)(MaskInput, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          ref = _props.ref,
          key = _props.key,
          mask = _props.mask,
          className = _props.className,
          value = _props.value,
          onChange = _props.onChange,
          onBlur = _props.onBlur,
          _props$placeholder = _props.placeholder,
          placeholder = _props$placeholder === undefined ? null : _props$placeholder,
          _props$disabled = _props.disabled,
          disabled = _props$disabled === undefined ? false : _props$disabled;

      return _react2.default.createElement(_reactTextMask2.default, {
        ref: ref,
        key: key,
        mask: mask,
        value: value,
        className: 'mask-input ' + className,
        placeholder: placeholder,
        guide: false,
        onChange: onChange,
        onBlur: onBlur,
        style: {
          border: this.props['data-__field'].errors && this.props['data-__field'].errors.length ? '1px solid #f5222d' : undefined,
          backgroundColor: disabled ? '#f5f5f5' : undefined,
          cursor: disabled ? 'not-allowed' : undefined,
          color: disabled ? 'rgba(0, 0, 0, 0.25)' : undefined
        },
        disabled: disabled
      });
    }
  }]);
  return MaskInput;
}(_react2.default.Component);

exports.default = MaskInput;