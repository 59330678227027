'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.todayToCustomFormat = todayToCustomFormat;
exports.toDateFormat = toDateFormat;
exports.toCustomFormat = toCustomFormat;
exports.startOfWeek = startOfWeek;
exports.endOfWeek = endOfWeek;
exports.toTimestamp = toTimestamp;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function todayToCustomFormat(str) {
  return (0, _moment2.default)().format(str);
}
function toDateFormat(moment) {
  return moment.format('YYYY/MM/DD');
}
function toCustomFormat(moment, str) {
  return moment.format(str);
}
function startOfWeek(momentDate) {
  return momentDate ? momentDate.startOf('week') : (0, _moment2.default)().startOf('week');
}
function endOfWeek(momentDate) {
  return momentDate ? momentDate.endOf('week') : (0, _moment2.default)().endOf('week');
}
function toTimestamp(momentObj) {
  return momentObj.format('YYYY-MM-DDTHH:mm:ss');
}