import { Notification } from '@adkgroup/appkit-ui'
import union from 'lodash/union'

const filteredObjectKeys = ['surgeryType', 'surgeryDate', 'nextPROMDate']

export const refetchEntities = (props, entityType, isTable, guid) => async dispatch => {
  const { mHealthMain, moduleConfig } = props
  const { UI } = mHealthMain.getStoreState()
  const fixedColumns = mHealthMain.models[`${entityType}s`]
  const dynamicColumns = UI[`${entityType}Table`].columns.filter((c) => !filteredObjectKeys.includes(c))
  const apiName = entityType.charAt(0).toUpperCase() + entityType.substring(1)
  try {
    const getEntitiesReq = await mHealthMain.api[`get${apiName}s`](
      union(fixedColumns, dynamicColumns),
      UI[`${entityType}Table`].sort,
      UI[`${entityType}Table`].filters,
      UI[`${entityType}Table`].pagination,
      guid
    )
    const payload = getEntitiesReq.data[`${entityType}s`].items.map(p => {
      if (moduleConfig.refetchPreRedux) {
        return moduleConfig.refetchPreRedux(p)
      } else {
        return p
      }
    })
    mHealthMain.dispatch.setData(`${entityType}s`, payload)
    mHealthMain.dispatch.setTablePageInfo(
      entityType,
      getEntitiesReq.data[`${entityType}s`].pageInfo,
      getEntitiesReq.data[`${entityType}s`].totalCount
    )
  } catch (error) {
    console.log(error)
    Notification({
      message: `Failed to load ${entityType} data`,
      description: 'Please try again later'
    })
  }
  if (isTable) isTable(false) // this.setState({ loading: false })
}