import EntityManagement from './EntityManagement'
import Profile from './Profile'
import AddEntity from './AddEntity'
import Response from './Response'
import PatientOverview from './PatientOverview'

export default (masterConfig, props) => ([
  {
    screen: EntityManagement,
    path: masterConfig.paths.list,
    exact: true,
    requiredPermissions: masterConfig.requiredPermissions.list,
    onPermissionError: masterConfig.onPermissionError.list,
  },
  {
    screen: AddEntity,
    path: masterConfig.paths.create,
    exact: true,
    requiredPermissions: masterConfig.requiredPermissions.create(props),
    onPermissionError: masterConfig.onPermissionError.create
  },
  {
    screen: Profile,
    path: masterConfig.paths.profile,
    exact: true,
    requiredPermissions: masterConfig.requiredPermissions.profile,
    onPermissionError: masterConfig.onPermissionError.profile
  },
  {
    screen: Response,
    path: masterConfig.paths.response,
    exact: true,
    requiredPermissions: masterConfig.requiredPermissions.response,
    onPermissionError: masterConfig.onPermissionError.response
  },
  {
    screen: PatientOverview,
    path: masterConfig.paths.overview,
    exact: true,
    authorized: [-1, 1, 2, 3, 4, 5],
    permissionsCfg: [{
      requiredPems: [],
      name: 'editable'
    }]
  }
])
