import React from 'react'
import { Notification } from '@adkgroup/appkit-ui'
import moment from 'moment'
import get from 'lodash/get'
import find from 'lodash/find'

import { createPatientDetailsConfig } from './createHelpers'
import { improveAxios } from '../../../../utils/axios/helpers'
import TreatmentTable from '../../../../features/TreatmentTable/components/TreatmentTable'
import {
  FormTime,
  FormCheckbox,
  FormRadio
} from '@/shared/components'

export default (props) => {
  return {
    title: "Add a Patient",
    formConfig: (ref) => {
      return [
        {
          className: 'basic-info create-patient',
          fields: [
            {
              type: 'text',
              label: 'First Name',
              id: 'name',
              required: true,
              colon: false,
              validateStatus: true,
              rules: [{ required: true, message: 'Please input your first name!' }],
            },
            {
              type: 'text',
              label: 'Last Name',
              id: 'lastName',
              required: true,
              colon: false,
              validateStatus: true,
              rules: [{ required: true, message: 'Please input your last name!' }],
            },
            {
              type: 'text',
              label: 'MRN',
              placeholder: '111-1111',
              id: 'mrn',
              colon: false,
              validateStatus: true,
            },
            {
              type: 'date',
              label: 'Date of Birth',
              placeholder: 'Select Date',
              id: 'dateOfBirth',
              required: true,
              colon: false,
              validateStatus: true,
              rules: [{ required: true, message: 'Please enter a date of birth!' }],
              dropdownClassName: 'create-entity-calendar-picker-container create-entity-calendar-picker-container__date-of-birth',
              format: 'YYYY-MM-DD',
              disabledDate: current => current > moment().endOf('day'),
              allowClear: true,
              showToday: true,
              validateTrigger: 'onChange'
            },
            {
              type: 'mask',
              label: 'Phone Number',
              placeholder: '617-555-0123',
              mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
              id: 'phoneNumber',
              required: true,
              colon: false,
              validateStatus: true,
              rules: [
                { required: true, message: 'Please input a phone number!' },
                { pattern: /^[0-9]\d{2}-\d{3}-\d{4}$/, message: 'Valid phone number is required' }
              ],
            },
            {
              type: 'text',
              label: 'Email Address',
              id: 'emailAddress',
              required: false,
            },
            {
              type: 'upload-photo',
              label: 'Profile Photo',
              id: 'profilePhotoUrl',
              required: false,
              colon: false,
              buttonIcon: 'upload',
              buttonLabel: 'Upload Image',
              buttonClassName: 'create-entity-upload-photo-button',
              modalTitle: 'Upload Profile Photo',
              modalOkText: 'Upload',
              onUpload: async (file) => {
                const { mHealthMain: { api } } = props
                let s3Key
                try {
                  // Assemble form data payload
                  const payload = new FormData()
                  payload.append('file', file)
                  // Post image to S3 and receive S3 public url back
                  const S3Response = await api.uploadProfilePhotoToS3(payload)
                  const profilePhotoUrl = get(S3Response, ['data', 'valueUri'], undefined)
                  if (profilePhotoUrl && ref) {
                  // Set key for error handling cleanup
                    s3Key = profilePhotoUrl.replace(`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/`, '')
                    // Set form field value
                    ref.props.form.setFieldsValue({ ["profilePhotoUrl"]: profilePhotoUrl })
                  } else {
                    throw 'error'
                  }
                } catch (error) {
                  Notification({
                    message: "Profile photo upload failed",
                    description: "Please try again later"
                  })
                  await api.deleteProfilePhotoFromS3(s3Key)
                }
              }
            },
            {
              type: 'customFormItem',
              label: 'Preferred Contact Method',
              id: 'preferredContactMethod',
              required: true,
              colon: false,
              valuePropName: "checked",
              rules: [
                { required: true, message: 'Please select a contact method!' },
              ],
              content: (props) => <FormCheckbox
                formRef={ref}
                options={[
                  { id: 'Text', name: 'Text' },
                  { id: 'Email', name: 'Email' },
                  { id: 'Phone', name: 'Phone' },
                ]}
                {...props}
              />,
            },
            {
              type: 'customFormItem',
              label: 'Permission To Text',
              id: 'permissionToText',
              required: true,
              colon: false,
              rules: [
                { required: true, message: 'Please select if allowed to text!' },
              ],
              content: (props) => <FormRadio
                formRef={ref}
                options={[
                  { id: 'yes', name: 'Yes' },
                  { id: 'no', name: 'No' },
                ]}
                {...props}
              />,
            },
            {
              type: 'select',
              formType: 'multi-select',
              label: 'Care Team Member',
              placeholder: 'Select Care Team Members',
              id: 'physician',
              required: false,
              colon: false,
              skip: props.myProfile.role.name === 'Physician' || props.myProfile.role.name === 'PhysicianDelegate',
              dropdownClassName: 'create-entity-select-dropdown create-entity-select-dropdown__physician',
              options: props.physicians.map(p => {
                return {
                  id: p.id,
                  name: `${p.firstName} ${p.lastName}`,
                }
              })
            },
            {
              type: 'date',
              label: 'Date of Next Clinical Appointment',
              placeholder: 'Select Date',
              id: 'dateOfNextAppointment',
              required: false,
              validateStatus: true,
              dropdownClassName: 'create-entity-calendar-picker-container create-entity-calendar-picker-container__date-of-appointment',
              format: 'YYYY-MM-DD',
              allowClear: true,
              showToday: false,
              validateTrigger: 'onChange',
              disabledDate: current => current < moment().endOf('day'),
            },
            {
              type: 'customFormItem',
              label: 'Time of Next Clinical Appointment',
              id: 'timeOfNextAppointment',
              required: false,
              colon: false,
              content: (props) => <FormTime
                formRef={ref}
                placeholder='Select Time'
              />,
            },
          ]
        },
        {
          title: 'Active Surgery',
          className: 'active-surgery create-patient',
          fields: [
            {
              type: 'custom',
              content: <TreatmentTable
                tableName='activeSurgeryTable'
                mHealthDispatch={props.dispatch}
                columns={
                  [
                    {
                      title: 'Surgery Type',
                      dataKey: 'activeSurgeryType',
                      key: 'activeSurgeryType',
                      editable: true,
                      width: '33%',
                      type: 'select',
                      options: props.sharedUIReducer.surgeryTypes.map((st) => ({
                        id: st,
                        name: st.charAt(0).toUpperCase() + st.slice(1)
                      }))
                    },
                    {
                      title: 'Surgery Date',
                      key: 'activeSurgeryDate',
                      dataKey: 'activeSurgeryDate',
                      editable: true,
                      type: 'date'

                    }
                  ]
                }
                dataObject={
                  [
                    {
                      key: 0,
                      activeSurgeryType: '',
                      activeSurgeryDate: ''
                    },
                  ]
                }
              />,
            }
          ]
        },
        {
          title: 'Previous Surgeries',
          className: 'previous-surgeries create-patient',
          fields: [
            {
              type: 'custom',
              content: <TreatmentTable
                mHealthDispatch={props.dispatch}
                tableName='previousSurgeryTable'
                columns={
                  [
                    {
                      title: 'Surgery Type',
                      dataKey: 'previousSurgeryType',
                      key: 'previousSurgeryType',
                      editable: true,
                      width: '33%',
                      type: 'select',
                      options: props.sharedUIReducer.surgeryTypes.map((st) => ({
                        id: st,
                        name: st.charAt(0).toUpperCase() + st.slice(1)
                      }))
                    },
                    {
                      title: 'Surgery Date',
                      dataKey: 'previousSurgeryDate',
                      width: '33%',
                      key: 'previousSurgeryDate',
                      disabledDate: current => current > moment().endOf('day'),
                      editable: true,
                      type: 'date'

                    }
                  ]
                }
                dataObject={
                  [
                    {
                      key: 0,
                      previousSurgeryType: '',
                      previousSurgeryDate: ''
                    },
                  ]
                }
                canAddNewRow
                canRemoveRow
                deleteLabel='Remove Surgery'
                buttonLabel='Add Previous Surgery'
              />,
            }
          ]
        },
        {
          title: 'Active Treatments',
          className: 'active-treatments create-patient',
          fields: [
            {
              type: 'custom',
              content: <TreatmentTable
                mHealthDispatch={props.dispatch}
                tableName='activeTreatmentTable'
                columns={
                  [
                    {
                      title: 'Treatment',
                      dataKey: 'activeTreatment',
                      key: 'activeTreatment',
                      editable: true,
                      width: '33%',
                      type: 'select',
                      options: props.sharedUIReducer.treatmentTypes.map((tt) => ({
                        id: tt,
                        name: tt.charAt(0).toUpperCase() + tt.slice(1)
                      }))
                    },
                    {
                      title: 'Treatment Dates',
                      dataKey: 'activeTreatmentDates',
                      width: '33%',
                      key: 'activeTreatmentDates',
                      editable: true,
                      type: 'daterange'

                    }
                  ]
                }
                dataObject={
                  [
                    {
                      key: 0,
                      activeTreatment: '',
                      activeTreatmentDates: ''
                    },
                  ]
                }
                canAddNewRow
                canRemoveRow
                deleteLabel='Remove Treatment'
                buttonLabel='Add Active Treatment'
              />,
            }
          ]
        },
        {
          title: 'Previous Treatments',
          className: 'previous-treatments create-patient',
          fields: [
            {
              type: 'custom',
              content: <TreatmentTable
                mHealthDispatch={props.dispatch}
                tableName='previousTreatmentTable'
                columns={
                  [
                    {
                      title: 'Treatment',
                      dataKey: 'previousTreatment',
                      key: 'previousTreatment',
                      width: '33%',
                      editable: true,
                      type: 'select',
                      options: props.sharedUIReducer.treatmentTypes.map((tt) => ({
                        id: tt,
                        name: tt.charAt(0).toUpperCase() + tt.slice(1)
                      }))
                    },
                    {
                      title: 'Treatment Dates',
                      dataKey: 'previousTreatmentDates',
                      width: '33%',
                      key: 'previousTreatmentDates',
                      disabledDate: current => current > moment().endOf('day'),
                      editable: true,
                      type: 'daterange'

                    }
                  ]
                }
                dataObject={
                  [
                    {
                      key: 0,
                      previousTreatment: '',
                      previousTreatmentDates: ''
                    },
                  ]
                }
                canAddNewRow
                canRemoveRow
                deleteLabel='Remove Treatment'
                buttonLabel='Add Previous Treatment'
              />,
            }
          ]
        },
        {
          className: 'submit',
          fields: [
            {
              type: 'submit',
              label: 'Create Patient',
              id: 'submit',
              buttonType: 'primary',
              className: 'create-entity-button',
              disableCheck: true,
              style: { flexBasis: '100%' }
            },
          ]
        }
      ]
    },
    onSubmit: async values => {
      const { myProfile, mHealthMain: { api, dispatch, getStoreState }, physicians } = props
      const tables = getStoreState().createPatientReducer
      const adminUsername = myProfile.sub

      // If dashboard user is physician or physician delegate, automatically assign physicianIds in payload
      let physician
      if (myProfile.role.name === 'Physician' || myProfile.role.name === 'PhysicianDelegate') {
        physician = myProfile.physicianUsername
          ? find(physicians, p => p.username === myProfile.physicianUsername) // if Physician Delegate creating patient
          : find(physicians, p => p.username === myProfile.sub)               // if Physician creating patient
      }

      const payload = {
        name: values.name,
        lastName: values.lastName,
        sex: '',
        dateOfBirth: values.dateOfBirth.format('YYYY-MM-DD'),
        phoneNumber: '+1' + values.phoneNumber.replace(/-/g, ''),
        mrn: values.mrn && values.mrn !== undefined
          ? values.mrn.replace(/-/g, '')
          : undefined,
        // cohorts: values.cohort && values.cohort.length && values.cohort.map(str => parseInt(str, 10)),
        cohorts: [],
        profilePhotoUrl: values.profilePhotoUrl,
        physicianIds: physician
          ? [physician.id]
          : values.physician
      }
      
      try {
        const response = await api.postUser(payload, `/user/${adminUsername}/patient`)
        const config = createPatientDetailsConfig(values, tables, response.data.username)
        await improveAxios.request(config)
        try {
          await api.scheduleSurvey(response.data.username, true)
        } catch (error) {
          Notification({
            message: 'Failed to schedule survey',
            description: 'Account was created, but no survey was scheduled'
          })
        }
        finally {
          // Following code block subscribes patient to questionnaire scheduler immediately,
          // and allows BE to manually input Day 0 survey. Uncomment to skip this feature.
          const newUserGuid = get(response, ['data', 'username'], undefined)
          if (newUserGuid) await api.queueScheduler({ username: newUserGuid, newlyCreated: true })

          Notification({ message: 'New patient successfully added' })
          await props.mHealthMain.dispatch.refetchEntities(props, 'patient')
          dispatch.navigateTo('/patients')
        }
      } catch (error) {
        if (get(error, ['response', 'status'], undefined) === 409) {
          Notification({
            message: 'Failed to add new patient',
            description: 'An account with with the given phone number already exists'
          })
        } else {
          Notification({
            message: 'Failed to add new patient',
            description: 'Please verify all information is entered correctly'
          })
        }
      }
    }
  }
}