'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (availablePermissions, content, requiredPermissions) {
  var mode = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'AND';

  switch (mode) {
    case 'AND':
      var allowed = true;
      requiredPermissions.forEach(function (permission) {
        if (availablePermissions.indexOf(permission) === -1) {
          allowed = false;
        }
      });
      return allowed ? content : null;
    case 'OR':
      var allowed = false;
      requiredPermissions.forEach(function (permission) {
        if (availablePermissions.indexOf(permission) !== -1) {
          allowed = true;
        }
      });
      return allowed ? content : null;
  }
};