import AWS from 'aws-sdk'
import { returnAWSCredentials } from './helpers'

class AWSCognitoManager {
  constructor() {
    this.env = returnAWSCredentials();
    this.s3 = null;
    this.region = null;
    this.bucketName = null;
    this.identityPoolId = null;
    this.authenticated = false;
  }

  init = ({
    idToken,
    bucketName = this.env.REACT_APP_AWS_BUCKET_NAME,
    region = this.env.REACT_APP_AWS_REGION,
    identityPoolId = this.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    loginPoolId = this.env.REACT_APP_AWS_LOGIN_POOL
  }) => new Promise((resolve, reject) => {
    this.bucketName = bucketName;
    this.region = region;
    this.identityPoolId = identityPoolId;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: `${region}:${identityPoolId}`,
      Logins: {
        [`cognito-idp.${region}.amazonaws.com/${region}_${loginPoolId}`]: idToken,
      },
      region
    }, {
      // optionally provide configuration to apply to the underlying service clients
      // if configuration is not provided, then configuration will be pulled from AWS.config
      // region should match the region your identity pool is located in
      region,
    });

    if (AWS.config.credentials.expired) {
      AWS.config.credentials.refresh((error) => {
        if (error) {
          console.warn(error);
          return reject(error);
        }
        AWS.config.credentials.get((error) => {
          if (error) {
            console.warn('Error getting AWS credentials', error);
            this.authenticated = false;
            reject(error);
          } else {
            console.log('AWS Cognito manager initialized');
            this.authenticated = true;
            // AWS.config.update({
            //   endpoint: returnAWSBaseEndpointForPlatform(),
            // });
            this.s3 = new AWS.S3();
            console.log('AWS S3 manager initialized');
            resolve(AWS.config.credentials);
          }
        });
      });
    }
  })

  checkIfAuthenticated = () => new Promise((resolve, reject) => {
    if (!this.authenticated) {
      return reject(new Error('AWS is not authenticated. Please call AWSCognitoManager.initialize with the appropriate credentials before attempting to get resources.'));
    }
    AWS.config.credentials.get((error) => {
      if (error) {
        return reject(error);
      }
      if (AWS.config.credentials.expired) {
        console.warn('AWS token expired! Refreshing...');
        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.warn('Error refreshing AWS token', error, error.stack);
            return reject(error);
          }
          console.log('AWS token refreshed! New token expires at', AWS.config.credentials.expireTime);
          return resolve(AWS.config.credentials);
        });
      } else {
        resolve(AWS.config.credentials);
      }
    });
  })

  getObject = (key) => new Promise(async (resolve, reject) => {
    try {
      await this.checkIfAuthenticated();
    } catch (error) {
      return reject(error);
    }
    const params = {
      Bucket: this.bucketName,
      Key: key,
    };
    try {
      const data = await this.s3.getObject(params).promise()
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}

export default new AWSCognitoManager()