import * as Actions from './redux/actions'
import * as Operations from './redux/operations'
import * as Reducers from './redux/reducers'

import { mHealthModule } from '@adkgroup/appkit-utils'

import routeConfig from './screens'
import './themes/defaultScreen.scss'
import './themes/addEntityScreen.scss'
import './themes/profileScreen.scss'
import './themes/responseScreen.scss'

export default class EntityManagementModule extends mHealthModule{  
  constructor (mHealthMain, config) {
    super(mHealthMain)
    super.init(
      'EntityManagementModule',
      config.navBarName,
      config.iconName,
      config.route,
      routeConfig,
      config
    )
  }

  getReducers = () => {
    return super.getReducers(Reducers)
  }

  getActions = () => {
    return super.getActions(Actions)
  }

  getOperations = () => {
    return super.getOperations(Operations)
  }
}
