'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _antd = require('antd');

require('./Bread.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// @withI18n()
// @withRouter
var Bread = function (_PureComponent) {
  (0, _inherits3.default)(Bread, _PureComponent);

  function Bread() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Bread);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Bread.__proto__ || (0, _getPrototypeOf2.default)(Bread)).call.apply(_ref, [this].concat(args))), _this), _this.generateBreadcrumbs = function (paths) {
      return paths.map(function (item, key) {
        var content = _react2.default.createElement(
          _react.Fragment,
          null,
          item.icon ? _react2.default.createElement(_antd.Icon, { type: item.icon, style: { marginRight: 4 } }) : null,
          item.name
        );

        return _react2.default.createElement(
          _antd.Breadcrumb.Item,
          { key: key },
          paths.length - 1 !== key ?
          // <Link to={addLangPrefix(item.route) || '#'}>{content}</Link>
          _react2.default.createElement(
            'div',
            null,
            content
          ) : content
        );
      });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Bread, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          routeList = _props.routeList,
          location = _props.location,
          i18n = _props.i18n;

      // Find a route that matches the pathname.
      // const currentRoute = routeList.find(
      //   _ => _.route && pathMatchRegexp(_.route, location.pathname)
      // )

      // Find the breadcrumb navigation of the current route match and all its ancestors.
      // const paths = currentRoute
      //   ? queryAncestors(routeList, currentRoute, 'breadcrumbParentId').reverse()
      //   : [
      //       routeList[0],
      //       {
      //         id: 404,
      //         name: i18n.t`Not Found`,
      //       },
      //     ]

      return _react2.default.createElement(
        _antd.Breadcrumb,
        { className: 'bread' },
        this.generateBreadcrumbs([])
      );
    }
  }]);
  return Bread;
}(_react.PureComponent);
// import Link from 'umi/navlink'
// import withRouter from 'umi/withRouter'
// import { withI18n } from '@lingui/react'
// import { pathMatchRegexp, queryAncestors, addLangPrefix } from 'utils'
// import styles from './Bread.less'


Bread.propTypes = {
  routeList: _propTypes2.default.array
};

exports.default = Bread;