import { Notification } from '@adkgroup/appkit-ui'
import moment from 'moment'
import has from 'lodash/has'
import get from 'lodash/get'
import find from 'lodash/find'

import { buildRequestConfig, improveAxios } from '../../../../utils/axios/helpers'

const getDoctorDetailsConfig = (guid) => buildRequestConfig(`studyTeam/${guid}/details`, 'get')

export default (props, guid) => ([
  { // getDashboardUser
    request: async () => await props.mHealthMain.api.getDashboardUser(
      guid,
      props.mHealthMain.models.dashboardUsers
    ),
    notification: () => Notification({
      message: 'Failed to load dashboard user data',
      description: 'Please try again later'
    }),
    fallback: () => props.mHealthMain.dispatch.setData('entityProfile', {}),
    onError: () => Notification({
      message: 'Failed to load study team member profile',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      const entityData = response.data.dashboardUsers.items[0]
      props.mHealthMain.dispatch.setData(
        'entityProfile',
        {
          ...props.mHealthMain.getStoreState().entityProfile,
          ...entityData
        }
      )
    }
  },
  { // getUserPermission
    request: async () => await props.mHealthMain.api.getUserPermissions(guid),
    notification: () => Notification({
      message: 'Failed to load dashboard user permissions',
      description: 'Please try again later'
    }),
    fallback: () => {},
    onError: () => Notification({
      message: 'Failed to load dashboard user permissions',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      const { entityProfile, UI: { application } } = window.MHealthMain.getStoreState()
      const userApplication = find(response.data, app => app.applicationId === application.id)
      if (userApplication) {
        props.mHealthMain.dispatch.setData('entityProfile', {
          ...entityProfile,
          permissions: userApplication.application.role.permissions.map((role) => role.permission)
        })
      }
    }
  },
  { // getUserPermission
    request: async () => await props.mHealthMain.api.getUserPermissions(guid),
    notification: () => Notification({
      message: 'Failed to load dashboard user permissions',
      description: 'Please try again later'
    }),
    fallback: () => {},
    onError: () => Notification({
      message: 'Failed to load dashboard user permissions',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      const { entityProfile, UI: { application } } = window.MHealthMain.getStoreState()
      const userApplication = find(response.data, app => app.applicationId === application.id)
      if (userApplication) {
        props.mHealthMain.dispatch.setData('entityProfile', {
          ...entityProfile,
          permissions: userApplication.application.role.permissions.map((role) => role.permission)
        })
      }
    }
  },
  { // getLoginHistory
    request: async () => await props.mHealthMain.api.getLoginHistory(
      '/loginHistory',
      guid,
      15,
    ),
    fallback: () => props.mHealthMain.dispatch.setData('entityLoginHistory', {}),
    onError: () => Notification({
      message: 'Failed to load study team member login history',
      description: 'Please try again later'
    }),
    onSuccess: (response) => {
      if (get(response, ['data', 'history'], []).length > 0) {
        const formattedData = response.data.history.map(datum => {
          const momentObjInUTC = moment.utc(datum)
          return {
            date: momentObjInUTC.local().format("MMMM D, YYYY"),
            time: momentObjInUTC.local().format("hh:mm:ss")
          }
        })
        props.mHealthMain.dispatch.setData(
          'entityLoginHistory',
          { history: formattedData, pagination: response.data.paging }
        )
      }
    }
  },
  { // getDoctorDetails
    request: async () => await improveAxios.request(getDoctorDetailsConfig(guid)),
    fallback: () => {},
    onSuccess: (response) => {
      if (has(response, ['data', 'attributes'])) {
        props.mHealthMain.dispatch.setData(
          'entityProfile',
          {
            ...props.mHealthMain.getStoreState().entityProfile,
            ...response.data.attributes[0]
          }
        )
      } else return null
    }
  }
])