'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FormItem = function (_React$Component) {
  (0, _inherits3.default)(FormItem, _React$Component);

  function FormItem(props) {
    (0, _classCallCheck3.default)(this, FormItem);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FormItem.__proto__ || (0, _getPrototypeOf2.default)(FormItem)).call(this, props));

    _this.state = {};
    return _this;
  }

  (0, _createClass3.default)(FormItem, [{
    key: 'render',
    value: function render() {
      var _props$form = this.props.form,
          getFieldDecorator = _props$form.getFieldDecorator,
          getFieldError = _props$form.getFieldError,
          isFieldTouched = _props$form.isFieldTouched;
      var _props$config = this.props.config,
          label = _props$config.label,
          id = _props$config.id,
          required = _props$config.required,
          colon = _props$config.colon,
          validateStatus = _props$config.validateStatus,
          _props$config$validat = _props$config.validateTrigger,
          validateTrigger = _props$config$validat === undefined ? 'onBlur' : _props$config$validat,
          initialValue = _props$config.initialValue,
          rules = _props$config.rules,
          style = _props$config.style;


      return _react2.default.createElement(
        _antd.Form.Item,
        {
          label: id !== 'submit' && label,
          required: required,
          colon: colon,
          validateStatus: validateStatus ? getFieldError(id) ? 'error' : '' : null,
          style: style
        },
        getFieldDecorator(id, {
          initialValue: initialValue,
          rules: rules,
          validateTrigger: validateTrigger
        })(this.props.content)
      );
    }
  }]);
  return FormItem;
}(_react2.default.Component);

exports.default = FormItem;