import moment from 'moment'

export const formatTableDataForAPI = (tables) => {
  const {
    activeSurgeryTable,
    previousSurgeryTable,
    activeTreatmentTable,
    previousTreatmentTable
  } = tables
  const previousSurgeries = previousSurgeryTable && previousSurgeryTable.map((tableDataObject) => ({
    surgeryType: tableDataObject.previousSurgeryType,
    date: moment(tableDataObject.previousSurgeryDate).format('YYYY-MM-DD')
  }))
  const activeTreatments = activeTreatmentTable && activeTreatmentTable.map((tableDataObject) => ({
    startDate: moment(tableDataObject.activeTreatmentDates[0]).format('YYYY-MM-DD'),
    endDate: moment(tableDataObject.activeTreatmentDates[1]).format('YYYY-MM-DD'),
    treatmentType: tableDataObject.activeTreatment
  }))
  const previousTreatments = previousTreatmentTable && previousTreatmentTable.map((tableDataObject) => ({
    startDate: moment(tableDataObject.previousTreatmentDates[0]).format('YYYY-MM-DD'),
    endDate: moment(tableDataObject.previousTreatmentDates[1]).format('YYYY-MM-DD'),
    treatmentType: tableDataObject.previousTreatment
  }))
  return {
    surgeryType: activeSurgeryTable[0].activeSurgeryType,
    ...activeSurgeryTable[0].activeSurgeryDate && { surgeryDate: moment(activeSurgeryTable[0].activeSurgeryDate).format('YYYY-MM-DD') },
    previousSurgeries,
    activeTreatments,
    previousTreatments
  }
}