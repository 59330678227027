import * as types from './createPatientTypes'
import cloneDeep from 'lodash/cloneDeep'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_TABLE_FIELD_VALUE: {
      const { tableName, dataObject } = action.payload
      if (!state[tableName]) {
        return {
          ...state,
          [tableName]: [
            dataObject
          ]
        }
      }
      return {
        ...state,
        [tableName]: [
          ...state[tableName],
          dataObject
        ]
      }
    }
    case types.REMOVE_TABLE_ROW: {
      const { tableName, rowIndex } = action.payload
      const clonedTable = cloneDeep(state[tableName])
      clonedTable.splice(rowIndex, 1)
      return {
        ...state,
        [tableName]: clonedTable
      }
    }
    case types.WIPE_PATIENT_TABLES: {
      return INITIAL_STATE
    }
    case types.SET_PATIENT_TABLE_FIELD_DATA: {
      const { tableName, fieldKey, fieldData, dataKey, extraInfo } = action.payload
      const clonedTable = cloneDeep(state[tableName])
      let fieldObject = clonedTable.filter((dataset) => dataset.key === fieldKey)[0]
      if (!fieldObject) return state
      if (extraInfo && (extraInfo.type === 'firstDate' || extraInfo.type === 'secondDate')) {
        if (fieldObject[dataKey] && fieldObject[dataKey].length) {
          if (extraInfo.type === 'firstDate') fieldObject[dataKey][0] = fieldData
          else fieldObject[dataKey][1] = fieldData
        } else {
          if (extraInfo.type === 'firstDate') fieldObject[dataKey] = [fieldData, undefined]
          else fieldObject[dataKey] = [undefined, fieldData]
        }
      } else fieldObject[dataKey] = fieldData
      return {
        ...state,
        [tableName]: clonedTable
      }
    }
    default: return state
  }
}