"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (status) {
  if (status >= 200 && status <= 299) {
    return true;
  }

  return false;
};