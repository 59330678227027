'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (avilablePems, pemPropsCfg) {
  var pemProps = {};
  pemPropsCfg.forEach(function (propCfg) {
    var allowed = false;
    propCfg.requiredPems.forEach(function (pem) {
      if (avilablePems.indexOf(pem) !== -1) {
        allowed = true;
      }
    });
    if (allowed) {
      pemProps[propCfg.name] = true;
    }
  });
  return pemProps;
};