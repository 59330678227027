import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Button, Popconfirm } from 'antd'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import * as profileTableHelpers from '../../helpers/profileTableHelpers'
import { addNewTableRow, removeTableRow, setPatientTableData } from '../../redux/createPatientActions'
import TreatmentCell, { TreatmentRow } from './components/TreatmentCell'
import './TreatmentTable.scss'

class TreatmentTable extends Component {
  constructor(props) {
    super(props)
    this.columns = props.columns || [{
        title: 'Undefined table',
        dataIndex: 'name',
        width: '100%',
        editable: false
      }]
      this.dataKeys = Object.keys(props.dataObject[0])
      if (props.canRemoveRow) this.columns.push({
        title: 'Action',
        dataKey: 'action',
        datIndex: 'action',
        type: 'action',
        render: (text, record, index) => {
          return this.renderDeleteColumn(index)
        }
    })  
  }

  renderDeleteColumn = (index) => {
    const { disabled, deleteLabel } =  this.props
    if (disabled) return (
      <Button
        disabled={disabled}
        type='link'
        className='editable-table-delete__button'
      >
        {deleteLabel || 'Delete'}
      </Button>
    )
    return (
      <Popconfirm title="Confirm delete?" onConfirm={() => this.handleDelete(index)}>
        <Button
          type='link'
          className='editable-table-delete__button'
        >
          {deleteLabel || 'Delete'}
        </Button>
      </Popconfirm>
    )
  }

  componentDidMount() {
    const { isProfileTable, tableData, canAddNewRow } = this.props
    if (isProfileTable && !tableData.length && !canAddNewRow) {
      this.handleAdd()
    } else if (!canAddNewRow) this.handleAdd()
  }

  handleDelete = (rowIndex) => {
    if (this.props.isDisabled) return
    const { tableName, mHealthDispatch, isProfileTable, mHealthMain } = this.props
    if (isProfileTable) profileTableHelpers.handleProfileTableDelete(mHealthMain, tableName, rowIndex)
    else removeTableRow(tableName, rowIndex, mHealthDispatch) 
  }

  handleAdd = () => {
    const { tableName, mHealthDispatch, isProfileTable, mHealthMain } = this.props
    const newData = this.dataKeys.reduce((a, key) => Object.assign(a, { [key]: null }), {})
    newData.key = `${tableName}-${uuidv4()}`
    if (isProfileTable) profileTableHelpers.handleProfileTableAdd(mHealthMain, tableName, newData)
    else addNewTableRow(tableName, newData, mHealthDispatch)
  }
  
  handleInputchange = (e, tableFieldKey, dataKey, extraInfo) => {
    const {tableName, mHealthDispatch, isProfileTable, mHealthMain } = this.props
    if (isProfileTable) {
      profileTableHelpers.handleProfileTableChange(e, mHealthMain, tableName, dataKey, tableFieldKey, extraInfo)
    } else setPatientTableData(tableName, e, tableFieldKey, dataKey, mHealthDispatch, extraInfo)
  }


  render () {
    const { canAddNewRow, buttonLabel, tableData, tableName } = this.props
    const components = {
      body: {
        row: TreatmentRow,
        cell: TreatmentCell
      }
    }
    const columns = this.columns.map((col, index) => {
      if (!col.editable) return col
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataKey: col.dataKey,
          editable: col.editable,
          inputType: col.type,
          selectOptions: col.options,
          required: col.required,
          disabledDate: col.disabledDate,
          isDisabled: this.props.disabled,
          handleInputChange: this.handleInputchange
        })
      }
    })
    return (
      <div className='o-table__container'>
        {
          tableData && tableData.length ? <Table
            className='editable-table'
            pagination={false}
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={tableData}
            columns={columns}
            rowKey={(record) => `${tableName}-${uuidv4()}`}
            /> : null
        }
        {canAddNewRow ? ( 
        <Button
          className='o-table__container_add__button'
          type='primary'
          icon={'plus-circle'}
          style={{ marginBottom: 16 }}
          onClick={this.handleAdd}
          disabled={this.props.disabled}
          >
            {buttonLabel || 'Add new'}
          </Button> ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({ createPatientReducer, entityProfile }, ownProps) => {
  let tableData
  if (ownProps.isProfileTable) tableData = get(entityProfile, [ownProps.tableName], [])
  else tableData = get(createPatientReducer, [ownProps.tableName], [])
  return { tableData }
}

export default connect(mapStateToProps, { addNewTableRow, removeTableRow, setPatientTableData })(TreatmentTable)