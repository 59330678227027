import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import last from 'lodash/last'
import { v4 } from 'uuid'

import './PatientSummary.scss'

const PatientSummary = ({ graphData, graphType, lastName, activeSurgeryTable }) => {
  if (!graphType) return null
  const getOverview = () => {
    switch (graphType) {
      case "How I'm feeling physically":
        return `Ms/Mrs ${lastName} completed questions about symptoms related to her breast(s). Her last recorded score was ${Math.round(last(graphData).value)}, on ${moment(last(graphData).date).format('LL')}.`;
      case "How my breasts look and feel":
        return `Ms/Mrs ${lastName} completed questions about how her breast(s) look and feel. Her last recorded score was ${Math.round(last(graphData).value)}, on ${moment(last(graphData).date).format('LL')}.`;
      case "How I'm feeling emotionally":
        return `Ms/Mrs ${lastName} completed questions about her emotional wellbeing. Her last recorded score was ${Math.round(last(graphData).value)}.`;
      case "How I'm feeling about my sexuality": {
        if (graphData.length > 2 && moment(graphData[graphData.length - 2].date).isBefore(activeSurgeryTable[0].activeSurgeryDate) && moment(last(graphData).date).isAfter(activeSurgeryTable[0].activeSurgeryDate)) {
          return `Ms/Mrs ${lastName} completed questions about her sexuality. Her last recorded score was ${Math.round(last(graphData).value)}, on ${moment(last(graphData).date).format('LL')}.`;
        } else {
          return `Ms/Mrs ${lastName} completed questions about how she is feeling sexually. Her last recorded score was ${Math.round(last(graphData).value)}, on ${moment(last(graphData).date).format('LL')}.`;
        }
      }
      default:
        return '';
    }
  }

  const getScoreComparison = () => {
    if (graphData.length < 2) return ''
    const scoreDifference = last(graphData).value - graphData[graphData.length - 2]
    if (moment(graphData[graphData.length - 2].date).isBefore(activeSurgeryTable[0].activeSurgeryDate) && moment(last(graphData).date).isAfter(activeSurgeryTable[0].activeSurgeryDate)) {
      // The date before last is pre-op
      // The last date is post-op, compare pre-op to post-op
      if (scoreDifference === 0) {
        return "Her score hasn't changed compared to her pre-operative score."
      } else if (scoreDifference > 0) {
        return `Her score is higher than her pre-operative score ${moment(last(graphData).date).format('LL')}`
      }
      else {
        return `Her score is lower than her pre-operative score ${moment(last(graphData).date).format('LL')}`
      }
    } else {
      if (scoreDifference === 0) {
        return 'Her score is the same as her last score.'
      } else if (scoreDifference > 0) {
        return `Her score has increased compared to her last score ${moment(last(graphData).date).format('LL')}`
      }
      else {
        return `Her score has decreased compared to her last score ${moment(graphData[graphData.length - 2].date).format('LL')}`
      }
    }
  }

  const getResources = () => {
    let resources = []
    if (graphType === "How I'm feeling physically") {
      resources = [
        { 
          name: "BWH Rehabilitation", 
          link: "https://www.brighamandwomens.org/Departments_and_Services/surgery/surgical-oncology/breast-surgery/rehabilitation-after-breast-surgery.aspx" 
        },
        {
          name: "Young and Strong Physical Activity (DFCI)",
          link: "https://youngandstrong.dana-farber.org/physical-activity.html "
        },
        {
          name: "The Leonard P. Zakim Center for Integrative Therapies and Healthy Living (DFCI)",
          link: "https://www.dana-farber.org/zakim"
        }
      ]
    } else if (graphType === "How I'm feeling emotionally") {
      resources = [
        {
          name: "Breast Oncology Social Work",
          link: "https://www.dana-farber.org/breast-oncology-program/our-team/"
        },
        {
          name: "Psychosocial Oncology Program",
          link: "https://www.dana-farber.org/psychosocial"
        },
        {
          name: "The Leonard P. Zakim Center for Integrative Therapies and Healthy Living",
          link: "https://www.dana-farber.org/zakim"
        },
        {
          name: "SoulMates Program",
          link: "https://www.dana-farber.org/soulmates"
        },
        {
          name: "Young and Strong Program",
          link: "https://www.dana-farber.org/YoungWomenBreastCancer"
        },
        {
          name: "One-to-One: Support for Cancer Patients",
          link: "https://www.dana-farber.org/onetoone"
        },
        {
          name: "Adult Survivorship Program",
          link: "https://www.dana-farber.org/survivor"
        }
      ]
    } else if (graphType === "How I'm feeling about my sexuality") {
      resources = [ 
        {
          name: "Sexual Health Program at DFCI.",
          link: "https://www.dana-farber.org/for-patients-and-families/care-and-treatment/support-services-and-amenities/sexual-health-program/"
        }
      ]
    }
    if (!resources.length) return ""
    else {return resources.map((resource) => ( 
      <>
        <a 
          key={v4()} 
          target='_blank' 
          rel='noopener noreferrer' 
          href={resource.link}
          className='patient-graph-summary__resources__links__link'
        >
          {resource.name}
        </a>
        <br />
      </>
    ))}
  }

  return (
    <div className='patient-graph-summary'>
      <h3 className='patient-graph-summary--title'>Graph Summary and Recommendation:</h3>
      <p className='patient-graph-summary--body'>
        {getOverview() + " " + getScoreComparison() + "\n" }
      </p>
      <h3 className='patient-graph-summary__resources'>Resources</h3>
      <div className='patient-graph-summary__resources__links'>{getResources()}</div>
    </div>
  )
}

const mapStateToProps = ({ entityProfile }) => {
  const { lastName, activeSurgeryTable } = entityProfile 
  return { lastName, activeSurgeryTable }
}

export default connect(mapStateToProps)(PatientSummary)