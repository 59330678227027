import React, { PureComponent } from 'react'

import PatientCareTeam from './components/PatientCareTeam'
import PatientHeaderSummary from './components/PatientHeaderSummary'
import './PatientHeaderBottom.scss'

class PatientHeaderBottom extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selection: 'summary'
    }
  }

  render () {
    const { selection } = this.state
    return (
      <div className='patient__header__bottom'>
        <div className='patient__header__bottom__labels'>
          <button 
            className={
              `patient__header__bottom__labels__button 
            ${selection === 'summary' ? 'patient__header__bottom__labels__button--active' : ''}`
            }
            onClick={() => this.setState({ selection: 'summary' })}
          >
            <p>Summary</p>
          </button>
          <button 
            onClick={() => this.setState({ selection: 'careteam' })}
            className={
              `patient__header__bottom__labels__button 
            ${selection === 'careteam' ? 'patient__header__bottom__labels__button--active' : ''}`
            }
          >
            <p>Care Team</p>
          </button>
          <div className='patient__header__bottom__labels--divider' />
        </div>
        <div>
          { selection === 'summary' ? <PatientHeaderSummary /> : <PatientCareTeam />}
        </div>
      </div>
    )
  }
}


export default PatientHeaderBottom