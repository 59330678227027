'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.multiValidate = exports.isValidDate = exports.validEmail = exports.securePassword = exports.isNotBlank = undefined;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isNotBlank = exports.isNotBlank = function isNotBlank(value) {
  return {
    error: !value || /^[\s]*$/.test(value) ? 'This field can not be blank' : null
  };
};

var securePassword = exports.securePassword = function securePassword(value) {
  return {
    error: !value || !/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,8})/.test(value) ? 'Password must be 8 characters in length and contain at least one special character, one uppercase character and one lower case character.' : null
  };
};

var validEmail = exports.validEmail = function validEmail(value) {
  return {
    error: !value || !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? 'Valid email is required' : null
  };
};

var isValidDate = exports.isValidDate = function isValidDate(beforeDate, afterDate) {
  return function (value) {
    var error = '';
    if ((0, _moment2.default)(value, ['YYYY-MM-DD']).isValid()) {
      if (beforeDate && (0, _moment2.default)(value, ['YYYY-MM-DD']) > beforeDate) {
        error += 'Date is after latest valid date ';
      }
      if (afterDate && (0, _moment2.default)(value, ['YYYY-MM-DD']) < afterDate) {
        error += 'Date is before earliest valid date';
      }
    } else {
      error += 'Date is invalid';
    }
    return { error: error };
  };
};

// takes an array of validation functions to perform on one field
var multiValidate = exports.multiValidate = function multiValidate(functions) {
  return function (value) {
    var errors = [];
    functions.forEach(function (func) {
      var funcVal = func(value);
      if (funcVal.error && errors.indexOf(funcVal.error) === -1) {
        errors.push(funcVal.error);
      }
    });
    return { error: errors.join('. ') };
  };
};