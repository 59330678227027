'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSurveys = undefined;

var _getSurveys = require('./getSurveys');

var _getSurveys2 = _interopRequireDefault(_getSurveys);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.getSurveys = _getSurveys2.default;