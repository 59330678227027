import { patientTab } from './tabs'
import responsesConfig from './responses'
import profile from './profile'
import initEntityData from './initEntityData'
import create from './create'
import permissions from './permissions'

export default {
  navBarName: 'Patients',
  module: 'EntityManagementModule',
  name: 'patientmodule', // has to be uniqe each module,
  route: '/patients',
  iconName: 'user',
  paths: {
    list: '/patients',
    profile: '/patients/:entityId/profile',
    response: '/patients/:entityId/response',
    create: '/patients/action/create',
    overview: '/patients/:entityId/overview',
  },
  ...permissions,
  tableTabs: [ patientTab ],
  patientResponses: [ responsesConfig ],
  profile,
  // defaultColumns: ['firstName', 'lastName', 'mrn', 'dateOfBirth', 'guid', 'status'], old default columns
  create,
  defaultColumns: ['firstName', 'lastName', 'guid', 'status'],
  navItem: {
    onClick: async (props, item) => {
      props.mHealthMain.setActiveModule(item.masterConfig.name, window.location.pathname)
      await props.mHealthMain.dispatch.setTableFilter(
        'patient',
        { status: 'mainSideBar' }
      )
    }
  },
  subMenu: [
    {
      title: 'Active',
      countKey: 'approved',
      onClick: async (props, item) => {
        await props.mHealthMain.setActiveModule(item.masterConfig.name, window.location.pathname)
        await props.mHealthMain.dispatch.setTableFilter(
          'patient',
          { status: 'Active' }
        )
      }
    },
    {
      title: 'Pending',
      countKey: 'pending',
      onClick: async (props, item) => {
        await props.mHealthMain.setActiveModule(item.masterConfig.name, window.location.pathname)
        await props.mHealthMain.dispatch.setTableFilter(
          'patient',
          { status: 'Pending' }
        )
      }
    },
    {
      title: 'Deactivated',
      countKey: 'declined',
      onClick: async (props, item) => {
        await props.mHealthMain.setActiveModule(item.masterConfig.name, window.location.pathname)
        await props.mHealthMain.dispatch.setTableFilter(
          'patient',
          { status: 'Deactivated' }
        )
      }
    },
  ],
  initEntityData,
  refetchPreRedux: data => {
    let attributes = data.attributes
    if (attributes && attributes.length) {
      attributes = JSON.parse(data.attributes[0])
    }
    return {
      ...data,
      nextPROMDate: attributes && attributes.nextPROMDate || '',
      surgeryType: attributes && attributes.surgeryType || '',
      surgeryDate: attributes && attributes.surgeryDate || '',
      phone: data.phone ? data.phone.substr(2) : undefined, // remove +1
    }
  }
}