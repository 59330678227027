import React, { PureComponent } from 'react'
import { Icon } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import TwoDashIcon from '@/shared/components/TwoDashIcon'
import PatientHeaderImage from './components/PatientHeaderImage'
import PatientHeaderBottom from './components/PatientHeaderBottom'
import './PatientHeader.scss'

class PatientHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleButtonToggle = () => this.setState((state) => ({ isOpen: !state.isOpen }) )


  render () {
    const { isOpen } = this.state
    const { firstName, lastName, age, profilePhotoUrl } = this.props
    return (
      <div className='patient__overview__header'>
        <div className='patient__overview__header__top'>
          <div className='patient__overview__header__top__left'>
            <PatientHeaderImage profilePhotoUrl={profilePhotoUrl} />
            <div className='patient__overview__header__top__left__info'>
              <p className='patient__overview__header__top__left__info--name'>{`${firstName || ''} ${lastName || ''}`}</p>
              <p className='patient__overview__header__top__left__info--age'>{`Age: ${age || ''}`}</p>
            </div>
          </div>
          <div className='patient__overview__header__top__right'>
            <div tabIndex={0} onClick={this.handleButtonToggle} className='patient__overview__header__top__right__button'>
              <p>Patient Summary</p>
              <Icon type='down' />
            </div>
          </div>
        </div>
        <div style={!isOpen ? { height: 0 } : null} className='patient__overview__header__bottom'>
          <PatientHeaderBottom /> 
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ entityProfile }) => {
  const { firstName, lastName, dateOfBirth, profilePhotoUrl } = entityProfile
  const age = moment().diff(moment(dateOfBirth), 'years')
  return { firstName, lastName, age, profilePhotoUrl }
}

export default connect(mapStateToProps)(PatientHeader)