const INITIAL_STATE = {
  tester: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state
      
  }
}