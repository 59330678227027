import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import { Notification } from '@adkgroup/appkit-ui'

import * as types from './types'
import { improveAxios, buildRequestConfig } from '@/utils/axios/helpers'

export const setOverviewTableData = (domain) => {
  return {
    type: types.SET_OVERVIEW_TABLE_DATA,
    payload: domain
  }
}

const getOverviewTableAnswerDataBegin = () => ({
  type: types.GET_OVERVIEW_TABLE_ANSWERS_DATA_BEGIN
})

const getOverviewTableAnswerDataSuccess = (data) => ({
  type: types.GET_OVERVIEW_TABLE_ANSWERS_DATA_SUCCESS,
  payload: data
})

const getOverviewTableAnswerDataFailed = (error) => {
  Notification({
    message: 'Failed to load patient answers',
    description: 'Please try again later'
  })
  return {
    type: types.GET_OVERVIEW_TABLE_ANSWERS_DATA_FAILED,
    payload: error
  }
}

const getOverviewTableAnswerDataWithExtraInfo = (dateCompleted, data, domain) => ({
  type: types.SET_OVERVIEW_TABLE_ANSWERS_DATA_WITH_EXTRA_INFO,
  payload: {
    domain: { ...domain, clearOnBack: true },
    answersData: {
      questions: keyBy(data.items, (item) => item.identifier),
      answers: data.response.items,
      date: dateCompleted,
      firstQuestion: data.items[0]
    }
  }
})

export const setOverviewTableAnswerData = (dateCompleted, questionnaireId, questionnaireResponseId, domain = null) => async (dispatch) => {
  dispatch(getOverviewTableAnswerDataBegin())
  try {
    const config = buildRequestConfig(`questionnaire/${questionnaireId}?responseId=${questionnaireResponseId}`, 'get')
    const response = await improveAxios.request(config)
    if (!isEmpty(domain)) {
      dispatch(getOverviewTableAnswerDataWithExtraInfo(dateCompleted, response.data, domain))
    } else {
      dispatch(getOverviewTableAnswerDataSuccess(
        { 
          questions: keyBy(response.data.items, (item) => item.identifier),
          answers: response.data.response.items,
          date: dateCompleted,
          firstQuestion: response.data.items[0]
        }
      ))
    }
  } catch (error) {
    console.log(error)
    dispatch(getOverviewTableAnswerDataFailed(error))
  }
}

export const wipeOverviewTableAnswers = () => ({
  type: types.WIPE_OVERVIEW_TABLE_ANSWERS
})

export const wipeOverviewTable = () => ({
  type: types.WIPE_OVERVIEW_TABLE
})