const styles = {
  gridOutlineScatter: {
    data: {
      opacity: 1,
      fill: '#E5E4E5'
    }
  },
  gridOutlineArea: {
    data: {
      fillOpacity: 0,
      strokeOpacity: 1,
      fill: '#E5E4E5'
    }
  },
  polarAxis: {
    axis: {
      display: 'none'
    },
    tickLabels: { 
      fontSize: 16,
      stroke: "#1A2760",
      fill: "#1A2760",
      strokeWidth: 0.3
    }
  },
  scatterStyles: {
    preOpStyles: { 
      data: { fill: '#E31C50', opacity: 1 }
    },
    previousStyles: {
      data: { fill: '#09A1B8', opacity: 1 }
    },
    lastReportedStyles: {
      data: { fill: '#1A2760', opacity: 1 }
    }
  },
  areaStyles: {
    preOpStyles: { 
      data: { 
        fill: '#E31C50', 
        fillOpacity: 0.4, 
        strokeWidth: 2,
        stroke: '#E31C50'
      }
    },
    previousStyles: {
      data: { 
        fill: '#09A1B8', 
        fillOpacity: 0.4,
        strokeWidth: 2,
        stroke: '#09A1B8'
      }
    },
    lastReportedStyles: {
      data: { 
        fill: '#1A2760', 
        fillOpacity: 0.4,
        strokeWidth: 2,
        stroke: '#1A2760'
      }
    }
  }
}

export default styles