'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getUser;

var _endpoints = require('../../endpoints');

function getUser(username) {
  return ['GET', true, _endpoints.USER_ROUTE, null, null, '/' + username];
}