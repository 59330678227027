import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PatientRow from './components/PatientRow'
import './PatientList.scss'

class PatientList extends PureComponent {


  getTextLabel = () => {
    const { patientsToUpload, uploadedPatients } = this.props
    if (uploadedPatients) return `The following ${patientsToUpload.length} patient(s) have been imported!`
    return `The following ${patientsToUpload.length} patient(s) will be imported:`
  }

  render () {
    const { patientsToUpload } = this.props
    return (
      <>
      { patientsToUpload.length ? <p className='c-csv__patient__list__text'>{this.getTextLabel()}</p> : null }
      <div className='c-csv__patient__list'>
        {
          patientsToUpload.map((patient, index) => (
            <PatientRow 
              index={index}
              key={`${patient.name}-${patient.lastName}-${patient.mrn || 0}`}
              name={`${patient.name} ${patient.lastName}`}
              canBeRemoved={true}
              errorMessage={patient.validationMessage}
            />
          ))
        }
      </div>
      </>
    )
  }
}

const mapStateToProps = ({ csvReducer }) => {
  const { patientsToUpload, meta: { uploadedPatients } } = csvReducer
  return { patientsToUpload, uploadedPatients }
}

export default connect(mapStateToProps)(PatientList)