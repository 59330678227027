'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = function (columns, mHealthMain, props) {
  var fixedColumns = props.moduleConfig.defaultColumns;
  var dynamicColumns = props.UI[props.tableConfig[0].modelName + 'Table'].columns;

  // Compile the configurations of fixed and dynamic columns from redux right before rendering
  var finalConfigs = void 0;
  if (fixedColumns && dynamicColumns.length) {
    var fixedConfigs = columns.filter(function (col) {
      return fixedColumns.indexOf(col.fieldKey) !== -1;
    });
    var fixedLeftConfigs = fixedConfigs.filter(function (col) {
      return col.fixed === 'left';
    });
    var fixedRightConfigs = fixedConfigs.filter(function (col) {
      return col.fixed === 'right';
    });

    var dynamicConfigs = columns.filter(function (col) {
      return dynamicColumns.indexOf(col.fieldKey) !== -1;
    });

    finalConfigs = fixedLeftConfigs.concat(dynamicConfigs).concat(fixedRightConfigs);
  } else {
    finalConfigs = columns;
  }

  return finalConfigs.map(function (col) {
    switch (col.type) {
      // TODO: DRY this up.. no need to be passing render, filter etc into multiple types
      case 'custom':
        return {
          fixed: col.fixed,
          title: col.value,
          dataIndex: col.fieldKey,
          fieldKey: col.fieldKey,
          key: 'col-' + col.fieldKey,
          filtered: col.filtered,
          render: col.render,
          sorter: col.sorter,
          filterFunction: col.filterFunction,
          filterType: col.filterType,
          filterKeys: col.filterKeys,
          filterOptions: col.filterOptions,
          filterTransform: col.filterTransform
        };
      case 'text':
        return {
          width: col.width,
          fixed: col.fixed,
          title: col.value,
          className: 'col-' + col.fieldKey,
          dataIndex: col.fieldKey,
          fieldKey: col.fieldKey,
          key: 'col-' + col.fieldKey,
          filtered: col.filtered,
          filterTransform: col.filterTransform,
          sorter: col.sorter,
          filterFunction: col.filterFunction,
          render: col.render ? function (text, record, index) {
            return col.render(record, mHealthMain);
          } : undefined,
          filterType: col.filterType,
          filterKeys: col.filterKeys,
          filterOptions: col.filterOptions
        };
      case 'select':
        return {
          fixed: col.fixed,
          title: col.value,
          fieldKey: col.fieldKey,
          key: 'col-' + col.fieldKey,
          sorter: col.sorter,
          filterFunction: col.filterFunction,
          render: function render(guy) {
            return _react2.default.createElement(
              _antd.Select,
              { value: guy.phase, style: { width: col.width } },
              col.options.map(function (co) {
                return _react2.default.createElement(
                  _antd.Select.Option,
                  { value: co.value, key: co.value },
                  co.label
                );
              })
            );
          },
          filterType: col.filterType,
          filterKeys: col.filterKeys,
          filterOptions: col.filterOptions,
          filterTransform: col.filterTransform
        };
      case 'toggle':
        return {
          fixed: col.fixed,
          title: col.value,
          key: 'col-' + col.fieldKey,
          fieldKey: col.fieldKey,
          align: 'left',
          filtered: col.filtered,
          filters: col.filters,
          filterFunction: col.filterFunction,
          filteredValue: col.filteredValue,
          render: function render(guy) {
            return _react2.default.createElement(_antd.Switch, { checked: guy[col.fieldKey], onChange: function onChange(value) {
                return col.onChange(guy, props, value);
              } });
          },
          filterType: col.filterType,
          filterKeys: col.filterKeys,
          filterOptions: col.filterOptions,
          filterTransform: col.filterTransform
        };
      case 'action':
        return {
          className: col.className,
          fixed: col.fixed,
          title: col.value,
          fieldKey: col.fieldKey,
          width: col.width,
          key: 'col-actionsmenu',
          align: 'center',
          render: col.render,
          filterType: col.filterType,
          filterKeys: col.filterKeys,
          filterOptions: col.filterOptions,
          filterTransform: col.filterTransform
        };
      default:
        return {
          fixed: col.fixed,
          title: col.value,
          dataIndex: col.fieldKey,
          key: 'col-' + col.fieldKey,
          width: col.width,
          filterType: col.filterType,
          filterKeys: col.filterKeys,
          filterOptions: col.filterOptions,
          filterTransform: col.filterTransform
        };
    }
  });
};