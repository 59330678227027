'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _antd = require('antd');

var _has = require('lodash/has');

var _has2 = _interopRequireDefault(_has);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Option = _antd.Select.Option;

var AntSelect = function (_React$Component) {
  (0, _inherits3.default)(AntSelect, _React$Component);

  function AntSelect(props) {
    (0, _classCallCheck3.default)(this, AntSelect);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AntSelect.__proto__ || (0, _getPrototypeOf2.default)(AntSelect)).call(this, props));

    _this.renderOptions = function (options, type) {
      var _this$props = _this.props,
          permissibleActions = _this$props.permissibleActions,
          userPermissions = _this$props.userPermissions;

      switch (type) {
        case 'select':
        default:
          return options.map(function (o, index) {
            if ((0, _has2.default)(permissibleActions, o.pascalName)) {
              // This element exists inside of the array as an option that is safeguarded by a permission check.
              if (userPermissions.map(function (p) {
                return p.name;
              }).includes(permissibleActions[o.pascalName])) {
                return _react2.default.createElement(
                  Option,
                  {
                    key: 'select-option-' + index,
                    value: o.id
                  },
                  o.name
                );
              } else return undefined;
            }
            return _react2.default.createElement(
              Option,
              {
                key: 'select-option-' + index,
                value: o.id
              },
              o.name
            );
          });
      }
    };

    _this.state = {};
    return _this;
  }

  (0, _createClass3.default)(AntSelect, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          value = _props.value,
          type = _props.type,
          style = _props.style,
          className = _props.className,
          options = _props.options,
          onChange = _props.onChange,
          onBlur = _props.onBlur,
          placeholder = _props.placeholder,
          dropdownClassName = _props.dropdownClassName,
          disabled = _props.disabled;

      return _react2.default.createElement(
        _antd.Select,
        {
          value: value,
          className: className,
          dropdownClassName: dropdownClassName,
          placeholder: placeholder || 'Select',
          mode: type === 'multi-select' && 'multiple',
          onChange: onChange,
          onBlur: onBlur,
          showSearch: true,
          optionFilterProp: 'children',
          filterOption: function filterOption(input, option) {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          },
          showArrow: true,
          disabled: disabled
        },
        this.renderOptions(options, type)
      );
    }
  }]);
  return AntSelect;
}(_react2.default.Component);

exports.default = AntSelect;