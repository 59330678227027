import { LOGOUT_SUCCESS } from '../../actionTypes'
import { setModalVisibility } from '../UI'

export const logoutUser = () => dispatch => {
  window.localStorage.removeItem('username')
  window.localStorage.removeItem('session_token')
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('refresh_token')

  dispatch(setModalVisibility(false))
  dispatch(receiveLogout())
}

export const receiveLogout = () => ({
  type: LOGOUT_SUCCESS,
  isFetching: false,
  isAuthenticated: false
})