import axios from 'axios';
import * as axiosConfigs from '../../configs/app'

export let improveAxios

export const initialize = () => {
  const axiosInstance = axios.create({
    baseURL: `${axiosConfigs.AXIOS_BASE_URL}`,
    timeout: 60000,
  });
  setImproveAxios(axiosInstance)
};

const setImproveAxios = (axiosInstance) => improveAxios = axiosInstance 


const getToken = () => axiosConfigs.STORAGE_DRIVER.getItem('session_token')

export const buildPostConfig = (endpoint, method, data, requestConfig) => {
  let req = {
    url: `/${endpoint}`,
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + getToken()
    }
  };
  if (data) {
    req = {
      ...req,
      data
    };
  }
  if (requestConfig) {
    req = {
      ...req,
      requestConfig
    };
  }
  return req;
};


export const buildRequestConfig = (endpoint, method, headerType) => {
  const req = {
    url: `/${endpoint}`,
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + getToken()
    }
  };
  return req;
};
