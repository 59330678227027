import React, { PureComponent } from 'react'
import { v4 } from 'uuid'

import PatientSurveyCategoryButton from './PatientSurveyCategoryButton'
import './PatientSurveyCategory.scss'

class PatientSurveyCategory extends PureComponent {
  constructor(props) {
    super(props)
  }

  render () {
    const { category } = this.props
    return (
      <div className='patient-survey-category'>
        <h3 id={category.category} className='patient-survey-category__title'>{category.category}</h3>
        <table className='patient-survey-category__table'>
          <thead>
            <tr className='patient-survey-category__table__header'>
              <th className='patient-survey-category__table__header--survey'>Survey</th>
              <th className='patient-survey-category__table__header--date'>Date Completed</th>
            </tr>
          </thead>
          <tbody>
            {
              category.domains.map((domain) => <PatientSurveyCategoryButton mhealthDispatch={this.props.dispatch} domain={domain}  key={v4()} />)
            }
          </tbody>
        </table>
      </div>
    )
  }
}


export default PatientSurveyCategory