import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import PatientGraph from './components/PatientGraph'
import PatientSummary from './components/PatientSummary'
import PatientGraphHeader from './components/PatientGraphHeader'
import CompleteHealth from './components/CompleteHealth'
import PatientAnswers from './components/PatientAnswers'
import { LoadingIcon } from '@/shared/components'
import './PatientGraph.scss'

class PatientGraphContainer extends PureComponent {


  renderLoadingIndicator = () => {
    const { loadingAnswers } = this.props
    if (loadingAnswers) {
      return (
        <div className='patient-overview-graph__loading'>
          <LoadingIcon />
        </div>
      )
    } else return null
  }

  renderContent = () => {
    const { activeDomain, lastName, mhealthDispatch, hasAnswers } = this.props
    if (hasAnswers) {
      return (
        <>
          <PatientGraphHeader mhealthDispatch={mhealthDispatch} hasAnswers={hasAnswers} surveyName={activeDomain.name} />
          <PatientAnswers mhealthDispatch={mhealthDispatch} />
          {this.renderLoadingIndicator()}
        </>
      )
    } else {
      return (
        <>
          <PatientGraphHeader mhealthDispatch={mhealthDispatch} hasAnswers={hasAnswers} surveyName={activeDomain.name} />
          <PatientGraph mhealthDispatch={mhealthDispatch} graphData={activeDomain.data} />
          <PatientSummary 
            graphData={activeDomain.data} 
            graphType={activeDomain.graphType} 
            patientLastName={lastName}
          />
          {this.renderLoadingIndicator()}
        </>
      )
    }
  }

  render () {
    const { activeDomain, lastName, mhealthDispatch, hasAnswers, loadingAnswers } = this.props
    if (!activeDomain && !hasAnswers) return <CompleteHealth />
    return (
      <div className='patient-overview-graph'>
        <div className='patient-overview-graph__content'>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ overviewReducer }) => {
  const { activeDomain, activeAnswers, meta } = overviewReducer
  const { loadingAnswers } = meta
  const hasAnswers = !isEmpty(activeAnswers)
  return { activeDomain, hasAnswers, loadingAnswers }
}

export default connect(mapStateToProps)(PatientGraphContainer)