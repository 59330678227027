export const UPLOAD_CSV_FILE_CONTENTS_BEGIN = 'UPLOAD_CSV_FILE_CONTENTS_BEGIN'
export const UPLOAD_CSV_FILE_CONTENTS_SUCCESS = 'UPLOAD_CSV_FILE_CONTENTS_SUCCESS'
export const UPLOAD_CSV_FILE_CONTENTS_FAIL = 'UPLOAD_CSV_FILE_CONTENTS_FAIL'

export const UPLOAD_CSV_FILE_PATIENTS_BEGIN = 'UPLOAD_CSV_FILE_PATIENTS_BEGIN'
export const UPLOAD_CSV_FILE_PATIENTS_SUCCESS = 'UPLOAD_CSV_FILE_PATIENTS_SUCCESS'
export const UPLOAD_CSV_FILE_PATIENTS_FAIL = 'UPLOAD_CSV_FILE_PATIENTS_FAIL'

export const SET_CSV_FILE_PATIENTS = 'SET_CSV_FILE_PATIENTS'
export const REMOVE_CSV_PATIENT = 'REMOVE_CSV_PATIENT'
export const RESET_CSV_DATA = 'RESET_CSV_DATA'