'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _map = require('babel-runtime/core-js/map');

var _map2 = _interopRequireDefault(_map);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _from = require('babel-runtime/core-js/array/from');

var _from2 = _interopRequireDefault(_from);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _redux = require('redux');

var _reactRouterRedux = require('react-router-redux');

var _reduxThunk = require('redux-thunk');

var _reduxThunk2 = _interopRequireDefault(_reduxThunk);

var _history = require('history');

var _forEach = require('lodash/forEach');

var _forEach2 = _interopRequireDefault(_forEach);

var _keys = require('lodash/keys');

var _keys2 = _interopRequireDefault(_keys);

var _authentication = require('../authentication');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MHealthMain = function () {
  function MHealthMain() {
    var _this = this;

    (0, _classCallCheck3.default)(this, MHealthMain);

    this.isAuthenticated = function () {
      return localStorage.getItem('session_token'); //&& isTokenStillValid()
    };

    this.initBrowserHistory = function (basename) {
      if (basename && basename !== '') {
        _this.history = (0, _history.createBrowserHistory)({ basename: basename });
      } else if (basename !== undefined) {
        _this.history = (0, _history.createBrowserHistory)();
      }
    };

    this.createModules = function (modules, moduleConfigs) {
      for (var moduleConfig in moduleConfigs) {
        var config = moduleConfigs[moduleConfig];
        _this.modules.set(config.name, new modules[config.module](_this, config));
      }
    };

    this.setPermissions = function (permissionsArray) {
      _this.permissions = permissionsArray;
      var modulesToRemove = [];

      _this.getModules().forEach(function (module) {
        module.requiredPermissions.forEach(function (permission) {
          if (_this.permissions.indexOf(permission) === -1) {
            modulesToRemove.push(module.className);
          }
        });
      });

      modulesToRemove.forEach(function (moduleName) {
        _this.modules.delete(moduleName);
      });

      return true;
    };

    this.initModules = function () {
      _this.getModules().forEach(function (module) {
        module.setStore(_this.store);
      });
    };

    this.activateDefaultModule = function (DEFAULT_MODULE) {
      _this.activeModule = _this.modules.get(DEFAULT_MODULE);
    };

    this.buildReducers = function (baseReducers, baseModels) {
      _this.getModules().forEach(function (module) {
        var moduleReducers = module.getReducers();

        moduleReducers.forEach(function (reducer) {
          _this.loadedReducers[reducer.key] = reducer.function;
        });
      });

      var dataReducers = {};

      (0, _forEach2.default)(baseModels, function (value, modelName) {
        dataReducers[modelName + 'Edit'] = function () {
          var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var action = arguments[1];

          switch (action.type) {
            case 'SET_EDIT_' + modelName.toUpperCase():
              return action.payload;
            default:
              return state;
          }
        };
      });

      var appReducer = (0, _redux.combineReducers)((0, _assign2.default)(_this.loadedReducers, baseReducers, dataReducers, _this.addOnReducers));

      _this.reducers = function (state, action) {
        if (action.type === "LOGOUT_SUCCESS") state = undefined;
        return appReducer(state, action);
      };
    };

    this.buildActions = function (baseActions) {
      _this.getModules().forEach(function (module) {
        var moduleActions = module.getActions();

        moduleActions.forEach(function (action) {
          _this.loadedActions[action.key] = action.function;
        });
      });

      _this.loadedActions = (0, _assign2.default)(_this.loadedActions, baseActions, _this.addOnActions);
    };

    this.buildOperations = function (baseOperations) {
      _this.getModules().forEach(function (module) {
        var moduleOperations = module.getOperations();

        moduleOperations.forEach(function (operation) {
          _this.loadedOperations[operation.key] = operation.function;
        });
      });

      _this.loadedOperations = (0, _assign2.default)(_this.loadedOperations, baseOperations, _this.addOnOperations);
    };

    this.buildDispatch = function () {
      var dispatchArr = [];
      _this.getActions().forEach(function (actionKey) {
        _this.dispatch[actionKey] = function () {
          var _loadedActions;

          dispatchArr.push(_this.store.dispatch((_loadedActions = _this.loadedActions)[actionKey].apply(_loadedActions, arguments)));
        };
      });
      _this.getOperations().forEach(function (operationKey) {
        _this.dispatch[operationKey] = function () {
          var _loadedOperations;

          dispatchArr.push(_this.store.dispatch((_loadedOperations = _this.loadedOperations)[operationKey].apply(_loadedOperations, arguments)));
        };
      });
      return dispatchArr;
    };

    this.setStore = function () {
      var middleware = (0, _reactRouterRedux.routerMiddleware)(_this.history);
      if (process.env.NODE_ENV === 'development') {
        _this.store = (0, _redux.createStore)(_this.reducers, (0, _redux.applyMiddleware)(middleware, _reduxThunk2.default)
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        );
      } else {
        _this.store = (0, _redux.createStore)(_this.reducers, (0, _redux.applyMiddleware)(middleware, _reduxThunk2.default));
      }
    };

    this.getModules = function () {
      var keys = (0, _from2.default)(_this.modules.keys());
      var arr = [];

      keys.forEach(function (moduleName) {
        arr.push(_this.modules.get(moduleName));
      });

      return arr;
    };

    this.getActions = function () {
      return (0, _keys2.default)(_this.loadedActions);
    };

    this.getOperations = function () {
      return (0, _keys2.default)(_this.loadedOperations);
    };

    this.setActiveModule = function (moduleClassName, location) {
      _this.activeModule = _this.modules.get(moduleClassName);
      _this.dispatch.navigateTo(_this.activeModule.route, location);
    };

    this.setState = function (newState) {
      _this.state = (0, _extends3.default)({}, _this.state, newState);
    };

    this.getStoreState = function () {
      return _this.store.getState();
    };

    window.MHealthMain = this;
    this.history = {};
    this.modules = new _map2.default();
    this.loadedReducers = {};
    this.loadedActions = {};
    this.loadedOperations = {};
    this.dispatch = {};
    this.activeModule = undefined;
    this.permissions = [];
    this.state = {};
    this.store = {};
  }

  (0, _createClass3.default)(MHealthMain, [{
    key: 'parseAddons',
    value: function parseAddons(addOns) {
      var _this2 = this;

      this.addOnRequests = {};
      this.addOnActions = {};
      this.addOnOperations = {};
      this.addOnReducers = {};

      (0, _forEach2.default)(addOns, function (a) {
        _this2.addOnRequests = (0, _assign2.default)(_this2.addOnRequests, a.requests);
        _this2.addOnActions = (0, _assign2.default)(_this2.addOnActions, a.actions);
        _this2.addOnOperations = (0, _assign2.default)(_this2.addOnOperations, a.operations);
        _this2.addOnReducers = (0, _assign2.default)(_this2.addOnReducers, a.reducers);
      });
    }
  }, {
    key: 'init',
    value: function init(api, actions, operations, reducers, modules, models, moduleConfigs, basename) {
      this.initBrowserHistory(basename);
      this.createModules(modules, moduleConfigs);
      this.buildReducers(reducers, models);
      this.buildActions(actions);
      this.buildOperations(operations);
      this.buildDispatch();
      this.setStore();
      this.initModules();
      this.activeModule = this.getModules()[0];
    }

    // logger(this.api.addLogEntry) old unused logger
    // crashReporter(this.api.addLogEntry)


    // This thing could be expanded to server side rendering if ever needed?
    // No reason these could not be added to the Map during runtime at some point on demand
    // and the app would not care. We would have to account for updating the store
    // during runtime, which I have not done personally but is well documented.

  }]);
  return MHealthMain;
}();

exports.default = MHealthMain;