'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAttempts = exports.getPatientResponses = exports.getDashboardUser = exports.getDashboardUsers = exports.getStatusCounts = exports.getPatient = exports.getPatients = undefined;

var _getPatients = require('./patients/getPatients');

var _getPatients2 = _interopRequireDefault(_getPatients);

var _getPatient = require('./patients/getPatient');

var _getPatient2 = _interopRequireDefault(_getPatient);

var _getStatusCounts = require('./patients/getStatusCounts');

var _getStatusCounts2 = _interopRequireDefault(_getStatusCounts);

var _getDashboardUsers = require('./dashboardUsers/getDashboardUsers');

var _getDashboardUsers2 = _interopRequireDefault(_getDashboardUsers);

var _getDashboardUser = require('./dashboardUsers/getDashboardUser');

var _getDashboardUser2 = _interopRequireDefault(_getDashboardUser);

var _getPatientResponses = require('./patientResponses/getPatientResponses');

var _getPatientResponses2 = _interopRequireDefault(_getPatientResponses);

var _getAttempts = require('./patientResponses/getAttempts');

var _getAttempts2 = _interopRequireDefault(_getAttempts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.getPatients = _getPatients2.default;
exports.getPatient = _getPatient2.default;
exports.getStatusCounts = _getStatusCounts2.default;
exports.getDashboardUsers = _getDashboardUsers2.default;
exports.getDashboardUser = _getDashboardUser2.default;
exports.getPatientResponses = _getPatientResponses2.default;
exports.getAttempts = _getAttempts2.default;