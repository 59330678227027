import moment from 'moment'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import last from 'lodash/last'

export const constructPatientDetails = (attributes) => {
  const activeSurgeryTable = [{ 
    activeSurgeryType: defaultTo(get(attributes, 'surgeryType', undefined), undefined),
    activeSurgeryDate: get(attributes, 'surgeryDate', undefined) ? moment(get(attributes, 'surgeryDate', undefined)) : undefined,
  }]
  const previousSurgeryTable = defaultTo(get(attributes, 'previousSurgeries', []), []).map((ps) => ({
    previousSurgeryType: ps.surgeryType,
    previousSurgeryDate: ps.date ? moment(ps.date) : undefined
  }))
  const activeTreatmentTable = defaultTo(get(attributes, 'activeTreatments', []), []).map((at) => {
    const startDate = at.startDate ? moment(at.startDate) : undefined
    const endDate = at.endDate ? moment(at.endDate) : undefined
    return {
      activeTreatment: at.treatmentType,
      activeTreatmentDates: [startDate, endDate]
    }
  })
  const previousTreatmentTable = defaultTo(get(attributes, 'previousTreatments', []), []).map((pt) => {
    const startDate = pt.startDate ? moment(pt.startDate) : undefined
    const endDate = pt.endDate ? moment(pt.endDate) : undefined
    return {
      previousTreatment: pt.treatmentType,
      previousTreatmentDates: [startDate, endDate]
    }
  })
  const dateOfNextClinicalAppointment = get(attributes, 'dateTimeOfNextClinicalAppointment', undefined)
  const timeOfNextAppointment = dateOfNextClinicalAppointment ? moment.unix(dateOfNextClinicalAppointment) : undefined
  const dateOfNextAppointment = dateOfNextClinicalAppointment ? moment.unix(dateOfNextClinicalAppointment).startOf('day') : undefined
  return {
    activeSurgeryTable,
    previousSurgeryTable,
    activeTreatmentTable,
    previousTreatmentTable,
    emailAddress: get(attributes, 'email', undefined),
    timeOfNextAppointment: timeOfNextAppointment,
    dateOfNextAppointment,
    locationOfNextAppointment: attributes.locationOfNextClinicalAppointment,
    permissionToText: attributes.permissionToText ? 'yes' : 'no',
    preferredContactMethod: get(attributes, 'preferedMethodOfContacts', []),
    nextPROMDate: attributes.nextPROMDate,
    reflectionsOnMyJourney: attributes.reflectionsOnMyJourney,
    thingsIWantToAskMyDoctor: attributes.thingsIWantToAskMyDoctor,
    thingsImThankfulFor: attributes.thingsImThankfulFor,
    onboardingCompleted: attributes.onboardingCompleted
  }
}

const organizeData = (domain) => {
  if (!domain.data) return []
  else {
    return domain.data
      .sort((a, b) => moment.unix(a.date) - moment.unix(b.date))
      .map((dataSet, index) => ({
        value: dataSet.value,
        date: moment.unix(dataSet.date),
        questionnaireId: dataSet.questionnaireId,
        questionnaireResponseId: dataSet.questionnaireResponseId,
        x: index + 1
      }))
  }
}

export const formatSurveyResponseData = (categories) => {
  const formattedCategories = categories.map((category) => {
    const newDomains = category.domains.map((domain) => {
      const organizedDomainData = organizeData(domain)
      if (domain.name === "Physical well-being: chest (BREAST-Q)") {
        return { ...domain, data: organizedDomainData, graphType: "How I'm feeling physically" }
      }
      else if (domain.name === 'Satisfaction with breast (BREAST-Q)') {
        return { ...domain, data: organizedDomainData, graphType: "How my breasts look and feel" }
      }
      else if (domain.name === 'Psychosocial well-being (BREAST-Q)') {
        return { ...domain, data: organizedDomainData, graphType: "How I'm feeling emotionally" }
      }
      else if (domain.name === 'Sexual well-being (BREAST-Q)') {
        return { ...domain, data: organizedDomainData, graphType: "How I'm feeling about my sexuality" }
      }
      else return { ...domain, data: organizedDomainData }
    })
    return { ...category, domains: newDomains }
  })

  formattedCategories.map((category) => {
    const newDomains = category.domains.sort((a, b) => {
      const difference = moment.unix(last(a.data).date).diff(moment.unix(last(b.data).date), 'days')
      if (difference < 1) return 1
      if (difference > 1) return -1
      else return 0
    })
    return {
      ...category,
      domains: newDomains
    }
  })


  return formattedCategories.sort((a, b) => {
    const capA = a.category.toUpperCase()
    const capB = b.category.toUpperCase()
    if (capA === 'SEXUAL HEALTH' && capB === 'PSYCHOSOCIAL HEALTH') return -1
    if (capA < capB) return -1
    if (capA > capB) return 1
    else return 0
  })
}