'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getCohorts;

var _endpoints = require('../../endpoints');

function getCohorts() {
  return ['GET', true, _endpoints.COHORT_ROUTE, null, null];
}