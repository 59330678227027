'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _antd = require('antd');

var Notification = function Notification(props) {
  var message = props.message,
      _props$description = props.description,
      description = _props$description === undefined ? '' : _props$description,
      _props$placement = props.placement,
      placement = _props$placement === undefined ? 'bottomRight' : _props$placement,
      _props$duration = props.duration,
      duration = _props$duration === undefined ? 3 : _props$duration,
      _props$onClick = props.onClick,
      onClick = _props$onClick === undefined ? null : _props$onClick,
      icon = props.icon;


  _antd.notification.open({
    message: message,
    description: description,
    placement: placement,
    duration: duration,
    onClick: onClick,
    icon: icon
  });
};

exports.default = Notification;