'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCohorts = undefined;

var _getCohorts = require('./getCohorts');

var _getCohorts2 = _interopRequireDefault(_getCohorts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.getCohorts = _getCohorts2.default;