import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { v4 } from 'uuid'

import './PatientCareTeam.scss'

class PatientCareTeam extends PureComponent {
  render () {
    const { patientDoctors } = this.props
    return (
      <div className='patient-header-careteam'>
        {
          patientDoctors.map((doctor) => (
            <div className='patient-header-careteam__item' key={v4()}>
              <p className='patient-header-careteam__item--specialty'>{get(doctor, ['attributes', [0], 'specialty'], '')}:</p>
              <p className='patient-header-careteam__item--name'>{`${doctor.name} ${doctor.lastName}`}</p>
              <a href={`mailto:${doctor.email}`} className='patient-header-careteam__item--email'>{doctor.email}</a>
            </div>
          ))
        }
      </div>
    )
  }
}

const mapStateToProps = ({ entityProfile }) => {
  const { patientDoctors }  = entityProfile
  return { patientDoctors }
}

export default connect(mapStateToProps)(PatientCareTeam)