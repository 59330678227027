export default async (props, guid) => {
  const { moduleConfig } = props

  const initConfigs = moduleConfig.initEntityData(props, guid)
  const requests = initConfigs.map((config, index) => {
    return config.request().catch((e) => {
      initConfigs[index].fallback()
      initConfigs[index].onError()
    })
  })

  await Promise.all(requests).then(responses => {
    responses.forEach((res, index) => {
      if (res && res.data) {
        initConfigs[index].onSuccess(res)
      }
      if (res && res.error) {
        if (!res.data) initConfigs[index].fallback()
        initConfigs[index].onError()
      }
    })
  })
}