import { push } from 'react-router-redux'

const navigateTo = (path, location, payload) => async (dispatch, props) => {
  dispatch(push({
    pathname: path,
    state: { from: location },
    payload
  }))
}

export {
  navigateTo
}