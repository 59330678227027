import React, { Component } from 'react'
import * as APPCONFIG from '../../configs/app'
import { isTokenStillValid } from '@adkgroup/appkit-utils'
import { 
  Login,
  ForgotPassword,
  ResetPassCreds,
  ResetPassEmail,
} from '@adkgroup/appkit-ui'
import './Auth.scss'

const mHealthUtils = require('@adkgroup/appkit-utils')

export default class AuthScreen extends Component {
  state = {
    errors: false,
    message: null,
    showPWModal: false,
    username2send: '',
  }

  componentDidMount () {
    this.props.mHealthMain.dispatch.setSelectedTrial(APPCONFIG.DEFAULT_TRIAL_SITE[0])
    this.props.mHealthMain.dispatch.setSelectedSite(APPCONFIG.DEFAULT_TRIAL_SITE[1])
  }

  handleForgotPassword = () => {
    const { mHealthMain, location } = this.props
    mHealthMain.dispatch.navigateTo(
      '/auth/forgot-password',
      location,
    )
  }

  handleBackToLogin = () => {
    const { mHealthMain, location } = this.props
    mHealthMain.dispatch.navigateTo(
      '/auth/login',
      location,
    )
  }

  renderLogin = () => {
    if (this.props.match.params.action === 'forgot-password') {
      return <ForgotPassword
        {...this.props}
        APPCONFIG={APPCONFIG}
        handleBackToLogin={this.handleBackToLogin}
      />
    } else if (this.props.match.params.action === 'login') {
      return <Login
        {...this.props}
        APPCONFIG={APPCONFIG} 
        handleForgotPassword={this.handleForgotPassword}
      />
    } else if (this.props.match.params.action === 'emailverify') {
      if (window.localStorage.getItem('session_token') && isTokenStillValid()) {
        const locationSearch = window.location.href.replace(window.location.origin, '').replace(window.location.pathname, '')
        const username = locationSearch.split('username=')[1].split('&pin=')[0]
        const pin = decodeURIComponent(locationSearch.split('pin=')[1])

        this.props.mHealthMain.dispatch.navigateTo(
          APPCONFIG.DEFAULT_ROUTE,
          this.props.location,
          { username, pin }
        )
      } else {
          return <Login
          {...this.props}
          APPCONFIG={APPCONFIG} 
          handleForgotPassword={this.handleForgotPassword}
          emailVerify={this.props.match.params.action === 'emailverify'}
        />
      }
    } else if (this.props.match.params.action === 'reset-password') {
      return <ResetPassCreds
        {...this.props}
        APPCONFIG={APPCONFIG}
        handleForgotPassword={this.handleForgotPassword}
      />
    } else if (this.props.match.params.action === 'new-password') {
      return <ResetPassCreds
        {...this.props}
        APPCONFIG={APPCONFIG}
        handleForgotPassword={this.handleForgotPassword}
        newAuth={true}
      />
    } else {
      // if (window.location.search.split('=')[1]) {
      //   return <ResetPassCreds {...this.props} APPCONFIG={APPCONFIG} />
      // } else {
        return <ResetPassEmail {...this.props} APPCONFIG={APPCONFIG} />
      // }
    }
  }

  renderSubscreen = ({ match, ...props }) => (
    <div className='login-screen'>
      {this.renderLogin()}
    </div>
  )


  render () {
    // put this back instead of renderSubscreen? if weird routing errors, try it
    const GuestOnlyRoute = mHealthUtils.GuestOnlyRoute(APPCONFIG.DEFAULT_ROUTE, this.props.mHealthMain.isAuthenticated)

    return (
      <div id='auth' className='fullScreen'>
        <GuestOnlyRoute
          {...this.props}
          path={'/auth/:action'}
          component={this.renderSubscreen}
        />
      </div>
    )
  }
}
