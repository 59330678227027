'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var getInitials = exports.getInitials = function getInitials(firstName, lastName) {
  if (firstName && lastName) {
    return '' + firstName.charAt(0) + lastName.charAt(0);
  } else {
    return 'A';
  }
};