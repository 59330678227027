import {
  CHANGE_APPNAV_OPENSTATE,
  SET_SELECTED_TABLE_ROWS,
  SET_MODAL_VISIBILITY,
  SET_APP_BUSY_STATE,
  SET_POPOVER_STATE,
  SET_TABLE_COLUMNS,
  SET_TABLE_FILTER,
  RESET_TABLE_FILTER,
  RESET_ALL_TABLE_FILTERS,
  SET_TABLE_PAGE_SIZE,
  SET_TABLE_PAGE_NUMBER,
  SET_TABLE_PAGE_INFO,
  SET_TABLE_SORT,
  SET_MODAL_CONTENT,
} from '../../actionTypes'

const INITIAL_APPNAV_STATE = {
  application: {
    id: 1,
    name: 'mHealth'
  },
  busy: false,
  appNav: {
    open: false
  },
  login: {
    resetPassScreen: {
      password: '',
      newPassword: '',
      confirmPassword: '',
      success: false,
      expired: false
    }
  },
  modal: {
    visible: false,
    onOk: null,
    title: null,
    content: null,
    okText: null,
    okButtonProps: null,
    cancelButtonProps: null,
    className: null,
    onCancel: null,
    width: null,
  },
  popover: {
    anchorEl: null,
    content: null,
    onClose: null,
    anchorOrigin: null,
    transformOrigin: null
  },
  tableRowsSelected: [],
  patientTable: {
    columns: ['mrn', 'status', 'surgeryType', 'surgeryDate', 'nextPROMDate', 'dateOfBirth'],
    sort: null,
    filters: [],
    pagination: {
      first: Math.floor(window.innerHeight / 90) <= 8 ? 8 : Math.floor(window.innerHeight / 80),
      after: 1,
    },
    totalCount: null,
    pageInfo: null,
  },
  dashboardUserTable: {
    columns: [],
    sort: null,
    filters: [],
    pagination: {
      first: Math.floor(window.innerHeight / 90) <= 8 ? 8 : Math.floor(window.innerHeight / 80),
      after: 1,
    },
    totalCount: null,
    pageInfo: null,
  },
  patientResponseTable: {
    columns: [],
    sort: null,
    filters: [],
    pagination: {
      first: Math.floor(window.innerHeight / 90) <= 8 ? 8 : Math.floor(window.innerHeight / 80),
      after: 1,
    },
    totalCount: null,
    pageInfo: null,
  }
}

const UI = (state = INITIAL_APPNAV_STATE, action) => {
  switch (action.type) {
    case CHANGE_APPNAV_OPENSTATE:
      return {
        ...state,
        appNav: {
          ...state.appNav,
          open: action.appNavOpenState
        }
      }
    case SET_MODAL_CONTENT:
      return {
        ...state,
        modal: {
          ...state.modal,
          title: action.title,
          content: action.content,
          onOk: action.onOk,
          okText: action.okText,
          okButtonProps: action.okButtonProps,
          cancelButtonProps: action.cancelButtonProps,
          className: action.className,
          onCancel: action.onCancel,
          width: action.width,
          visible: action.visible,
        }
      }
    case SET_MODAL_VISIBILITY:
      if (!action.visible) {
        return {
          ...state,
          modal: INITIAL_APPNAV_STATE.modal
        }
      } else {
        return {
          ...state,
          modal: {
            ...state.modal,
            visible: action.visible,
          } 
        }
      }
    case SET_APP_BUSY_STATE:
      return {
        ...state,
        busy: action.busy
      }
    case SET_SELECTED_TABLE_ROWS:
      return {
        ...state,
        tableRowsSelected: action.tableRowsSelected
      }
    case SET_POPOVER_STATE:
      return {
        ...state,
        popover: {
          anchorEl: action.anchorEl || null,
          content: action.content || null,
          onClose: action.onClose || null,
          anchorOrigin: action.anchorOrigin || null,
          transformOrigin: action.transformOrigin || null
        }
      }
    case SET_TABLE_COLUMNS:
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          columns: action.columns,
        }
      }
    case SET_TABLE_FILTER:
      if (action.reset) {
        return {
          ...state,
          [`${action.entityType}Table`]: {
            ...state[`${action.entityType}Table`],
            filters: [action.filters],
          }
        }
      } else {
        return {
          ...state,
          [`${action.entityType}Table`]: {
            ...state[`${action.entityType}Table`],
            filters: state[`${action.entityType}Table`].filters
              .filter(f => Object.keys(f)[0] !== Object.keys(action.filters)[0])
              .concat(action.filters),
          }
        }
      }
    case RESET_TABLE_FILTER:
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          filters: state[`${action.entityType}Table`].filters.filter(f => Object.keys(f)[0] !== action.removeIndex && !Object.keys(f)[0].toLowerCase().includes(action.removeIndex)),
        }
      }
    case RESET_ALL_TABLE_FILTERS: 
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          filters: [],
        }
      }
    case SET_TABLE_PAGE_SIZE:
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          pagination: {
            first: action.pageSize,
            after: null,
          },
        }
      }
    case SET_TABLE_PAGE_NUMBER:
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          pagination: {
            ...state[`${action.entityType}Table`].pagination,
            after: action.pageNumber,
          },
        }
      }
    case SET_TABLE_PAGE_INFO: 
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          pageInfo: action.pageInfo,
          totalCount: action.totalCount,
        }
      }
    case SET_TABLE_SORT:
      return {
        ...state,
        [`${action.entityType}Table`]: {
          ...state[`${action.entityType}Table`],
          sort: action.sort
        }
      }
    default:
      return state
  }
}

export {
  UI
}
