import React, { PureComponent } from 'react'
import { Icon } from 'antd'

import CircleType from 'circletype'
import './PatientHeaderImage.scss'

class PatientHeaderImage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      topLeft: 0,
      topRight: 0,
      bottomLeft: 0,
      bottomRight: 0,
      rotate: false
    }
  }

  componentDidMount () {
    new CircleType(document.getElementById('top-left-text'))
      .radius(70)
    new CircleType(document.getElementById('top-right-text'))
      .radius(70)
    new CircleType(document.getElementById('bottom-left-text'))
      .radius(70)
      .dir(-1)
    new CircleType(document.getElementById('bottom-right-text'))
      .radius(70)
      .dir(-1)
    this.setState({ rotate: true }) // CircleType will break if rotate is added by css on mount.
  }

  renderImage = () => {
    const { profilePhotoUrl } = this.props
    if (!profilePhotoUrl) {
      return (
        <div className='patient__header__image__container__icon'>
          <Icon type='user' className='patient__header__image__container__icon__user' />
        </div>
      )
    }
    return (
      <img className='patient__header__image__container__image' src={profilePhotoUrl} />
    )
  }

  render () {
    return (
      <>      
      <div className='patient__header__image__container'>
        <div className='patient__header__image__container__top'>
          <div className='patient__header__image__container__top__slice__container'>
            <div 
              onClick={() => document.getElementById("Psychosocial Health").scrollIntoView()}
              className='
                patient__header__image__container__top__slice__container__slice 
                patient__header__image__container__top__slice__container__slice__left
              '
            >
              <p 
                className='patient__header__image__container__top__slice__container__slice__left__text' 
                id='top-left-text'
                style={this.state.rotate ? { transform: `translate(-38px, 21px) rotate(-45deg)` } : null}
              >
                Psychosocial
              </p>
            </div>
          </div>
          <div
            className='patient__header__image__container__top__slice__container'
          >
            <div 
              className='
              patient__header__image__container__top__slice__container__slice 
              patient__header__image__container__top__slice__container__slice__right
            '
            >
              <p 
                onClick={() => document.getElementById("Body Image").scrollIntoView()}
                id='top-right-text'
                className='patient__header__image__container__top__slice__container__slice__right__text' 
                style={this.state.rotate ? { transform: `translate(39px, 22px) rotate(45deg)` } : null}
              >
                Body Image
              </p>
            </div>
          </div>
        </div>
        <div className='patient__header__image__container__bottom'>
          <div className='patient__header__image__container__bottom__slice__container'>
            <div 
              onClick={() => document.getElementById("Sexual Health").scrollIntoView()}
              className='
                patient__header__image__container__bottom__slice__container__slice 
                patient__header__image__container__bottom__slice__container__slice__left
              '
            >
              <p 
                id='bottom-left-text'
                className='patient__header__image__container__bottom__slice__container__slice__left__text' 
                style={this.state.rotate ? { transform: `translate(-41px, 105px) rotate(45deg)` } : null}
              >
                Sexual
              </p>
            </div>
          </div>
          <div className='patient__header__image__container__bottom__slice__container'>
            <div 
              onClick={() => document.getElementById("Physical Health and Symptoms").scrollIntoView()}
              className='
                patient__header__image__container__bottom__slice__container__slice 
                patient__header__image__container__bottom__slice__container__slice__right
              '
            >
              <p 
                id='bottom-right-text'
                className='patient__header__image__container__bottom__slice__container__slice__right__text' 
                style={this.state.rotate ? { transform: `translate(39px, 104px) rotate(-45deg)` } : null}
              >
                Physical
              </p>
            </div>
          </div>
        </div>
        {this.renderImage()}
        <div className='patient__header__image__container__overlay' ></div>
        <div className='patient__header__image__container__line--vertical' ></div>
        <div className='patient__header__image__container__line' ></div>
      </div>
      </>
    )
  }
}

export default PatientHeaderImage