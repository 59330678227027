import React from 'react'
import { AntTable } from '@adkgroup/appkit-ui'

class LoginHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  render() {
    const { data, entity, configs, entityLoginHistory } = this.props
    return (
      <div className="login-history-wrapper">
        <div className="login-history-upper-container">
          <div className="login-history-title">{configs.title}</div>
          { entityLoginHistory.pagination.totalCount &&
            <div className="login-history-count-container">
              <div className="login-history-count">{entityLoginHistory.pagination.totalCount}</div>
            </div> }
        </div>
        <AntTable
          data={data}
          configs={configs.tableConfigs}
          className="login-history-table"
          pagination={{
            pageSize: entityLoginHistory.pagination.pageSize,
            total: entityLoginHistory.pagination.totalCount,
            hideOnSinglePage: true, 
            size: "small",
            onChange: page => configs.goToPage(entity, page)
          }}
          size={"small"}
        />
      </div>
    );
  }
}

export default LoginHistory; 