'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getApplication;

var _endpoints = require('../../endpoints');

function getApplication() {
  return ['GET', true, _endpoints.APPLICATION_ROUTE, null];
}